import { useContext, useState } from "react";
import TabPosition from "../shared/TabPosition";
import { TranslationService } from "../../services/TranslationService";
import { useHistory } from "react-router-dom";
import ActionService, { ActionType } from "../../services/ActionService";
import { handleErrorWithToast } from "../../utils/RequestUtils";
import { ActionListResponse } from "./entities/ActionListResponse";
import Table, { TableHeader } from "../shared/Table";
import CompanyService from "../../services/CompanyService";
import Switch from "react-switch";
import TableContext from "../task/TableContext";
import ActionTableProvider, { ActionTableContextValues } from "./providers/ActionTableProvider";
import { ToastService } from "../shared/bootstrap/Toast";
import { CollapsableTr, DeleteButtonModal, EditFolderButton, EditFolderPanel } from "../segment/List";
import { LinkWithHistory } from "../shared/BreadcrumbHistory";
import { NavigationHistoryContext } from "../shared/NavigationHistory";
import FloatingPanelService from "../shared/FloatingPanel";
import { ActionDetail } from "./ActionDetail";
import TooltipComponent from "../shared/TooltipComponent";
import { FixToTop } from "../shared/FixToTop";
import SearchInput from "../shared/components/SearchInput";
import { addIf } from "../../utils/Utils";
import MenuButton from "../shared/components/MenuButton";

const ActionList: React.FC = () => {
    return (
        <TabPosition module="action">
            <ActionTableProvider>
                <ActionContainer />
            </ActionTableProvider>
        </TabPosition >
    );
};

const ActionContainer = () => {
    const { translate, currentLanguage } = TranslationService;
    const { applySearch, reload } = useContext(TableContext);
    const history = useHistory();
    const headers: TableHeader[] = [
        new TableHeader("action", spaceSeparator + translate.Action, false, false),
        new TableHeader("description", translate.Description, false, false),
        new TableHeader("segment", translate.Segment, false, false),
        new TableHeader("when", translate.HeaderWhen, false, false, "w-300px"),
        new TableHeader("switch", TogglePauseHeaderSwitch, false, false, "p-2 switch-header"),
    ];
    const editFolder = () => FloatingPanelService.showPanel({
        children: <EditFolderPanel name={undefined} folderId={undefined} reload={reload} endpoint={(name, id) => ActionService.setFolder(name, id ?? 0)} />,
        title: translate.NewFolder,
        width: 450,
        height: 280,
        position: "center",
    });

    const newActions = [
        ...addIf(CompanyService.canDo("editsegment"), { text: translate.NewAction, action: () => history.push(`/${currentLanguage}/action/editaction`) }),
        ...addIf(CompanyService.canDo("editsegment"), { text: translate.NewFolder, action: editFolder }),
    ];

    return (
        <section className="segments">
            <div className="container-fluid padding">
                <div className="card action-table mh-100 p-0">
                    <FixToTop>
                        <div className="genericHeader">
                            <div className="w-100 ps-0">
                                <div className="col-auto ps-0">
                                    <div className="actionButtonsHeader">
                                        {CompanyService.canDo("editsegment") && <MenuButton text={TranslationService.translate.New} showTitle actions={newActions} icon="fa-light fa-plus" hideChevron />}
                                    </div>
                                </div>
                            </div>
                            <SearchInput onSearch={applySearch} autosearch={false} />
                        </div>
                    </FixToTop>
                    <Table headers={headers} item={ActionItem} stickyHeader={true} />
                </div>
            </div>
        </section>
    );
};

const TogglePauseHeaderSwitch = () => {
    const { paused, setPaused } = useContext(TableContext) as ActionTableContextValues;
    const history = useHistory();
    const { setCurrentPath } = useContext(NavigationHistoryContext);
    setCurrentPath({
        name: TranslationService.translate.Automatic,
        link: () => {
            history.push(`/${TranslationService.currentLanguage}/action`);
        },
    });
    async function togglePause(notPaused: boolean) {
        setPaused(!notPaused);
        const result = await handleErrorWithToast(ActionService.togglePause(!notPaused), () => { setPaused(!notPaused); });
        if (!result) {
            return;
        }
        setPaused(result.disabled);
    }
    return <Switch checked={!paused} onChange={togglePause} height={24} width={50} />;
};

const ActionItem = ({ data }: { data: ActionListResponse.List }) => {
    const [showRows, setShowRows] = useState(!data.name);
    const [disabled, setDisabled] = useState(data.items.map(x => x.Disabled ?? true));

    const { reload, paused } = useContext(TableContext) as ActionTableContextValues;
    const history = useHistory();
    const deleteItem = (endpoint: (id: number) => Promise<Error | unknown>, id: number) => async () => {
        const result = await endpoint(id);
        if (result instanceof Error) {
            ToastService.showToast(TranslationService.translate.ErrorProcessingRequest, undefined, "danger");
        } else {
            ToastService.showToast(TranslationService.translate.FolderDeletedSuccessfully, undefined, "success");
            reload();
        }
    };
    const actionTypeIcon = (actionType: ActionType) => {
        switch (actionType) {
            case ActionType.MailSend:
                return <><i className="fal fa-envelope me-2" style={{ color: "#0076B6", borderRadius: 100, backgroundColor: "#EAF8FA", padding: 5 }} />{TranslationService.translate.TypeMail}</>;
            default:
            case ActionType.ActionTask:
                return <><i className="fa-regular fa-square-check me-2" style={{ color: "#3A9D18", borderRadius: 100, backgroundColor: "#EEF7EC", padding: "5px 6px" }} />{TranslationService.translate.TypeTask}</>;
            case ActionType.ActionSMSSend:
                return <><i className="fa-regular fa-message-dots me-2" style={{ color: "#998106", borderRadius: 100, backgroundColor: "#FCF9D9", padding: 5 }} />{TranslationService.translate.TypeSMS}</>;
            case ActionType.ActionSendList:
                return <><i className="fal fa-list-ul me-2" style={{ color: "#1F2B45", borderRadius: 100, backgroundColor: "#F7F7F9", padding: 5 }} />{TranslationService.translate.TypeList}</>;
            case ActionType.ActionSendIOList:
                return <><i className="fal fa-list-ul me-2" style={{ color: "#1F2B45", borderRadius: 100, backgroundColor: "#F7F7F9", padding: 5 }} />{TranslationService.translate.TypeIOList}</>;
            case ActionType.ActionSendLetter:
                return <><i className="fa-regular fa-file me-2" style={{ color: "#FF7A00", borderRadius: 100, backgroundColor: "#FFEDDC", padding: "5px 7px" }} />{TranslationService.translate.TypeLetter}</>;
            // case ActionType.Multiple:
            //     return <><i className="fal fa-mail-bulk me-2" style={{ color: "#FF7A00", borderRadius: 100, backgroundColor: "#FFEDDC", padding: "3px 5px" }} />{TranslationService.translate.TypeMultiple}</>;
            case ActionType.ActionSendWhatsApp:
                return <><i className="fa-brands fa-whatsapp me-2" style={{ color: "#1bd741", borderRadius: 100, backgroundColor: "#d9f9d9", padding: "5px 6px" }} />{TranslationService.translate.TypeWhatsApp}</>;
        }
    };
    const Period = ({ item }: { item: ActionListResponse.Item }) => {
        const { translate } = TranslationService;


        function showHour() {
            if (item.Type === ActionType.ActionTask || !item.Hour) {
                return (<></>);
            }
            const timezone = CompanyService.getTimezone();
            const split = item.Hour.split(":").map(x => parseInt(x));
            let hours = Math.floor(split[0] - timezone / 60);
            if (hours < 0) {
                hours += 24;
            }
            const minutes = split[1] - (timezone % 60);
            return ` ${TranslationService.translate.At} ${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
        }

        function showDayFrequency() {
            if (!item.Mail_Frequency) {
                return (<></>);
            }
            const day = item.Mail_Frequency;
            return ` ${translate.OnDays} ${day}`;
        }

        const showWeekly = () => {
            const days = [translate.Sunday, translate.Monday, translate.Tuesday, translate.Wednesday, translate.Thursday, translate.Friday, translate.Saturday];
            const translatedDays = Array.from({ length: 7 }).map((x, i) => item["Day" + i as keyof typeof item] && days[i].toLocaleLowerCase()).filter(x => x);
            const lastDay = translatedDays.splice(translatedDays.length - 1, 1)[0];
            const joinedFirstDays = translatedDays.length === 0 ? "" :
                translatedDays.join(", ") + " y ";
            return `${translate.On} ${joinedFirstDays}${lastDay}`;
        };
        const Periods = ActionListResponse.Periods;
        if (!item.When) { // Whens.Custom, null or undefined
            if (!item.Mail_Period) { // Period.Once, null or undefined
                return <div>{translate.OnceAfterEnteredIntoSegment}{showHour()}</div>;
            } else if (item.Mail_Period === Periods.After) {
                return <div>{translate.AfterEnteringSegment.replace("{days}", (item.Mail_Frequency || 0).toString())}{showHour()}</div>;
            } else if (item.Mail_Period === Periods.Every) {
                return <div>{translate.EveryXDaysAfterEnteringSegment.replace("{days}", (item.Mail_Frequency || 0).toString())}{showHour()}</div>;
            } else if (item.Mail_Period === Periods.Weekly) {
                return <>{showWeekly()}{showHour()}</>;
            } else if (item.Mail_Period === Periods.Monthly) {
                return <>{TranslationService.translate.Monthly} {showDayFrequency()} {showHour()}</>;
            } else {
                return <div></div>;
            }
        } else if (item.When === ActionListResponse.Whens.NewInvoice) {
            return <div>{translate.WhenNewInvoice}</div>;
            // } else if (when === Whens.NextDue) {
            //     // FIXME: Add criteria
            //     return <div><code>NextDue - missing first segment criteria</code></div>
            // } else if (when === Whens.Due) {
            //     // FIXME: Add criteria
            //     return <div><code>Due - missing first segment criteria</code></div>
        } else {
            return <div></div>;
        }
    };
    const Segment = ({ segments }: { segments: ActionListResponse.Segment[] }) => {
        if (!Array.isArray(segments) || segments.length === 0) {
            return (<></>);
        } else if (segments.length === 1) {
            return (<LinkWithHistory to={`segment/details?segmentid=${segments[0].Id}`}>
                {segments[0].Value}
            </LinkWithHistory>);
        } else {
            return (<>{TranslationService.translate.Multiple}</>);
        }
    };

    const setActionDisabled = (index: number, disabled: boolean) => {
        setDisabled(x => {
            x[index] = disabled;
            return [...x];
        });
    };

    const handleSwitch = (index: number) => async (enabled: boolean, event: MouseEvent | React.SyntheticEvent<MouseEvent | KeyboardEvent, Event>) => {
        event.stopPropagation();
        event.preventDefault();
        setActionDisabled(index, !enabled);
        const id = data.items[index].ActionID;
        handleErrorWithToast(ActionService.switchAction(id, !enabled), () => setActionDisabled(index, enabled));
    };

    return (
        <>
            {data.name && <tr onClick={() => setShowRows(x => !x)} className={"pointer"}>
                <td valign="middle" colSpan={4}>
                    <span className="arrowDown mb-2"><i className={"fa-solid fa-fw " + (!showRows ? "fa-chevron-right" : "fa-chevron-down")}></i></span>
                    <span className="iconFolder"><i className={"fa-light fa-fw mx-2 fa-folder" + (showRows ? "-open" : "")}></i></span>
                    <span className="folderName">{data.name ?? TranslationService.translate.WithoutFolder}</span>
                </td>
                <td className="actions" >
                    <span onClick={(e) => { e.stopPropagation(); return false; }}>
                        <EditFolderButton className="" folder={{ Id: data.id, name: data.name }} endpoint={ActionService.setFolder}><i className="fa-regular fa-pen px-2"></i></EditFolderButton>
                        <DeleteButtonModal onDelete={deleteItem(ActionService.deleteFolder, data.id)} message={TranslationService.translate.SureDeleteFolder}><i className="fa-regular fa-trash-can px-2"></i></DeleteButtonModal>
                    </span>
                </td>
            </tr>}
            {data.items.length === 0 &&
                <CollapsableTr show={showRows} className={"bg-warning p-2 bg-opacity-25"}>
                    <td colSpan={5}> {TranslationService.translate.EmptyFolder}</td>
                </CollapsableTr>}
            {data.items.length > 0 && data.items.map((x, i) =>
                <CollapsableTr key={x.ActionID} show={showRows || !data.name} className='pointer show-child-on-hover'
                    onClick={() => history.push(`/${TranslationService.currentLanguage}/action/editaction?id=${x.ActionID}`)}>
                    <td>
                        {data.name && spaceSeparator}
                        {actionTypeIcon(x.Type)}
                    </td>
                    <td className='text-grey'>
                        <TooltipComponent title={x.Name}>
                            {x.Name}
                        </TooltipComponent>
                        {x.Type === ActionType.ActionSendLetter && !x.Letter_HasFile &&
                            <TooltipComponent title={TranslationService.translate.LetterHasFile}>
                                <i className="ms-2 text-danger fas fa-exclamation-square"></i>
                            </TooltipComponent>
                        }
                    </td>
                    <td>
                        <Segment segments={x.segments} />
                    </td>
                    <td>
                        <Period item={x} />
                    </td>
                    <td onClick={(e) => e.stopPropagation()} valign="middle">
                        <div className='d-flex align-items-center gap-3'>
                            <Switch checked={!disabled[i]} onChange={handleSwitch(i)} onColor={paused ? "#888" : "#080"} height={24} width={50} uncheckedIcon={false} />
                            {(x.Type !== ActionType.ActionTask && !(x.Type === ActionType.MailSend && x.When)) ?
                                <i className="fa-solid fa-magnifying-glass show-when-hovering-parent"
                                    onClick={() => FloatingPanelService.showPanel({
                                        children: <ActionDetail id={x.ActionID} type={x.Type} />,
                                        title: x.Name,
                                        width: 890,
                                        height: 745,
                                    })} /> : <span></span>}
                        </div>
                    </td>
                </CollapsableTr>)}
        </>
    );
};
const spaceSeparator = "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0";

export default ActionList;
