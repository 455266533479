import { useContext, useEffect, useRef, useState } from "react";
import { TranslationService } from "../../../services/TranslationService";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CompanyService from "../../../services/CompanyService";
import FloatingPanelService from "../../shared/FloatingPanel";
import ToastContext, { ToastService } from "../../shared/bootstrap/Toast";
import ActivityService, { EmailInvoiceListType, SetEmailRequest } from "../../../services/ActivityService";
import { RequiredManager, ValidationMessage } from "../../shared/RequieredManager";
import { ClientDetailContextValues } from "../ClientDetailContext";
import FilterService from "../../../services/FilterService";
import { ActivityGetResponse } from "../activity/entities/AcitivityGetResponse";
import { FloatingPanelFooter } from "../../shared/components/FloatingPanelFooter";
import { TabMenuProps } from "../../shared/components/TabMenu";
import { EmailTabMessage } from "./EmailTabMessage";
import { EmailTabPreview } from "./EmailTabPreview";
import { getError } from "../../../utils/RequestUtils";
import { isNullOrWhitespace, OptionalMap } from "../../../utils/Utils";
import ModalService from "../../shared/bootstrap/Modal";
import { parseDate, restTimezone } from "../../../utils/ParseUtils";
import { DateTimePickerEditor } from "../../shared/components/Editors";
import SwitchButton from "../../shared/components/SwitchButton";
import TooltipComponent from "../../shared/TooltipComponent";
import { ActivityListResponse } from "../activity/entities/AcitivityListResponse";

export class EmailEdit {
    to = "";
    subject = "";
    toCC?: string;
    toBCC?: string;
    templateId?: string;
    groupId?: string;
    linkToClient = false;
    emailFrameId?: number;
    sendCopy = false;
    activityTypeId?: number;
    tags?: string;
    programEmail = false;
    programEmailDateTime?: string;
    MessageID?: number;
    bodyHtml = "";
    replacebody: "0" | "1" = "1";
    replytoid: number | null = null;

    includeInvoiceList = false;
    includeInvoiceFiles = false;
    invoiceListType: EmailInvoiceListType = EmailInvoiceListType.All;
    invoiceListMode = "1";
    invoiceGroupBy?: string;
    invoiceReportExportId?: number;
    invoiceLanguage?: string;
    invoiceFilters: string[] = [];

    constructor(subject?: string, to?: string, groupId?: string, emailBase?: ActivityGetResponse.Item, prevMessageId?: string) {
        this.to = emailBase?.To ?? to ?? "";
        this.subject = emailBase?.Subject ?? subject ?? "";
        this.groupId = emailBase?.GroupID ?? groupId;

        this.toCC = emailBase?.CC;
        this.toBCC = emailBase?.BCC;
        this.linkToClient = emailBase?.IncludeLink ?? false;
        this.sendCopy = emailBase?.copyMe ?? false;
        this.activityTypeId = emailBase?.ActivityTypeID;
        this.tags = emailBase?.Tag_Message.map(x => x.TagID).join(",");
        this.programEmailDateTime = emailBase?.Draft ? undefined : emailBase?.ScheduledDate;
        this.programEmail = !!this.programEmailDateTime;

        //this.includeInvoiceList = !!this.invoiceListType;
        this.includeInvoiceFiles = emailBase?.IncludeAttachment ?? false;
        this.invoiceGroupBy = emailBase?.Mail_GroupBy?.toString();
        this.invoiceReportExportId = emailBase?.ReportExportID;
        this.invoiceLanguage = emailBase?.Language;
        this.programEmailDateTime = emailBase?.ScheduledDate;
        this.programEmail = !!this.programEmailDateTime;
        this.invoiceFilters = FilterService.ParseExtraFiltersResponseString(emailBase?.IOFilter);
        this.emailFrameId = emailBase?.MailFrameID ? parseInt(emailBase?.MailFrameID) : undefined;
        this.MessageID = emailBase?.MessageID;
        this.replytoid = prevMessageId ? parseInt(prevMessageId) : null;
    }
}

interface EmailComposeParams {
    personId: string,
    onSubmit?: () => void,
    clientDetailContext?: ClientDetailContextValues,
    defaultSubject?: string
    defaultTo?: string
    defaultGroupId?: string
    defaultGroupLabel?: string
    prevMessageId?: string
    emailBase?: ActivityGetResponse.Item
    reply?: string
    files?: ActivityListResponse.File[]
}

export type EmailTabProps = {
    model: Partial<EmailComposeModel>,
    setModel: React.Dispatch<React.SetStateAction<Partial<EmailComposeModel>>>,
    getFeature: <TKey extends keyof EmailComposeModel["item"], >(featureKey: TKey, offValue?: EmailComposeModel["item"][TKey]) =>
        {
            toggleFeature: (isOn?: boolean | undefined) => void;
            setFeatureValue: <TValueKey extends keyof EmailComposeModel["item"], >(key: TValueKey) => (value: EmailComposeModel["item"][TValueKey]) => void;
            isOn: boolean;
        },
    requiredManager: RequiredManager,
};

export type EmailComposeModel = SetEmailRequest & {
    temp: {
        showPrevEmailBtn: boolean | "loading",
        includeInvoiceList?: boolean,
        invoiceListType?: EmailInvoiceListType,
    }
};

export const EmailCompose = (props: EmailComposeParams) => {
    const { onSubmit, clientDetailContext } = props;
    const { translate } = TranslationService;
    const { showToast } = useContext(ToastContext);

    const [isPreview, setIsPreview] = useState(false);
    const [model, setModel] = useState(getDefaultModel(props));

    function getDefaultModel(props: EmailComposeParams) {
        const { personId, defaultSubject, defaultTo, defaultGroupId, prevMessageId, emailBase, reply, files } = props;

        const model = { item: {}, temp: {}, } as Partial<EmailComposeModel>;
        const item = model.item!;
        item.to = emailBase?.To ?? defaultTo ?? "";
        item.subject = emailBase?.Subject ?? defaultSubject ?? "";
        item.groupid = emailBase?.GroupID ?? defaultGroupId;
        item.groupName = emailBase?.Group;

        item.cc = emailBase?.CC;
        item.bcc = emailBase?.BCC;
        item.ccto = emailBase?.CC;
        item.bccto = emailBase?.BCC;
        item.copyme = emailBase?.copyMe ?? false;
        item.activitytypeid = emailBase?.ActivityTypeID;
        item.Tag_Message = emailBase?.Tag_Message;
        if (new Date(emailBase?.ScheduledDate).getFullYear() < 2099) {
            item.scheduleddate = emailBase?.ScheduledDate;
        }
        item.personid = parseInt(personId);

        item.html = true;
        item.attach = emailBase?.Attach ?? false;
        //Content set later in useEffect if undefined
        item.content = emailBase?.Content as string;

        //item.includeInvoiceList = !!item.invoiceListType;
        //Invoice
        item.quickfilter = emailBase?.QuickFilter ?? ((emailBase?.IOFilter !== undefined) ? 3 : null);
        item.includeinvoices = Boolean(emailBase?.IncludeInvoices ?? emailBase?.QuickFilter ?? emailBase?.IOFilter ?? clientDetailContext?.invoiceIds?.length);
        item.listmode = emailBase?.Attach ? "0" : "1";
        item.IncludeAttachment = emailBase?.IncludeAttachment ?? false;
        item.includeattachment = emailBase?.IncludeAttachment ?? false;
        item.mail_groupby = emailBase?.Mail_GroupBy ? parseInt(emailBase.Mail_GroupBy) : OptionalMap(CompanyService.getCompanyAuth()?.GroupMailBy, x => parseInt(x));
        item.reportexport = emailBase?.ReportExportID;
        item.language = emailBase?.Language;
        item.mailframeid = emailBase?.MailFrameID ? parseInt(emailBase?.MailFrameID) : undefined;
        item.MessageID = emailBase?.MessageID;
        item.replytoid = prevMessageId ? parseInt(prevMessageId) : null;
        item.replacebody = !prevMessageId ? "0" : "1";
        item.activitytypeid = emailBase?.ActivityTypeID ?? CompanyService.getActivityTypes().find(x => x.Mail && x.MailDefault)?.ActivityTypeID;
        item.filter = emailBase?.IOFilter;
        item.includelink = emailBase?.IncludeLink;


        item.fromname = emailBase?.FromName ?? CompanyService.getUserFullName();
        item.from = emailBase?.From ?? CompanyService.getUserEmail();
        const defaultMail = CompanyService.getCompanyAuth()?.DefaultMail;
        if (defaultMail && !emailBase?.From && !emailBase?.FromName) {
            const regexPattern = /(.*?) *<(.*)>/g;
            const regex = regexPattern.exec(defaultMail);
            const [, name, email] = regex ?? [];
            if (name && email) {
                item.fromname = name;
                item.from = email;
            } else {
                item.from = defaultMail;
            }
        }

        item.files = files?.map(el => ({ id: el.id, name: el.fileName })) ?? [];
        item.draft = emailBase?.Draft ?? false;
        const orderByArray = emailBase?.Mail_OrderBy?.split(";");
        const orderByDescArray = emailBase?.Mail_OrderByOrderDesc?.split(";");
        item.orderby0 = OptionalMap(orderByArray?.[0], x => x.length > 0 ? parseInt(x) : undefined);
        item.orderby1 = OptionalMap(orderByArray?.[1], x => x.length > 0 ? parseInt(x) : undefined);
        item.orderby2 = OptionalMap(orderByArray?.[2], x => x.length > 0 ? parseInt(x) : undefined);
        item.orderbyorderdesc0 = (OptionalMap(orderByDescArray?.[0], x => x === "0" ? "off" : "on") ?? "off") as "on" | "off";
        item.orderbyorderdesc1 = (OptionalMap(orderByDescArray?.[1], x => x === "0" ? "off" : "on") ?? "off") as "on" | "off";
        item.orderbyorderdesc2 = (OptionalMap(orderByDescArray?.[2], x => x === "0" ? "off" : "on") ?? "off") as "on" | "off";

        item.reply = reply;
        // Will be set later
        // const temp = model.temp!;
        // temp.showPrevEmailBtn = Boolean(prevMessageId);
        return model;
    }
    const [isSending, setIsSending] = useState<string>("");
    const requiredManager = useRef<RequiredManager>(new RequiredManager()).current;

    const features = useRef({} as Record<string, unknown>).current;
    const featuresCache: Record<string, ReturnType<EmailTabProps["getFeature"]>> = {};
    const getFeature: EmailTabProps["getFeature"] = <TKey extends keyof EmailComposeModel["item"],>(featureKey: TKey, offValue?: EmailComposeModel["item"][TKey]) => {
        if (featuresCache[featureKey] === undefined) {
            const setFeatures = () => {
                const newValue = features[featureKey + "isOn"] ? features[featureKey + "prevValue"] : offValue;
                setModel(model => {
                    const newModel = { ...model };
                    if (!newModel.item) {
                        newModel.item = {} as Partial<EmailComposeModel>["item"];
                    }
                    if (newModel.item) {
                        if (features[featureKey + "isOn"]) {
                            Object.assign(newModel.item, features[featureKey + "otherValues"]);
                        }
                        newModel.item[featureKey] = newValue as never;
                    }
                    return newModel;
                });
            };
            const toggleFeature = (isOn?: boolean | undefined) => {
                const value = model.item && model.item[featureKey];
                features[featureKey + "isOn"] = isOn !== undefined ? isOn : features[featureKey + "isOn"] === undefined ? (value === undefined || value === "off") : !features[featureKey + "isOn"];
                setFeatures();
            };
            const setFeatureValue = <TValueKey extends keyof EmailComposeModel["item"],>(key: TValueKey) =>
                (value: EmailComposeModel["item"][TValueKey]) => {
                    if (key === featureKey as string) {
                        features[featureKey + "prevValue"] = value;
                    } else {
                        const newValue = (features[featureKey + "otherValues"] ?? {}) as Record<string, unknown>;
                        newValue[key] = value;
                        features[featureKey + "otherValues"] = newValue;
                    }
                    setFeatures();
                };
            features[featureKey + "prevValue"] = model?.item?.[featureKey];
            features[featureKey + "isOn"] = features[featureKey + "isOn"] || (model?.item?.[featureKey] && model?.item?.[featureKey] !== offValue);

            featuresCache[featureKey] = {
                toggleFeature,
                setFeatureValue,
                isOn: Boolean(features[featureKey + "isOn"]),
            };
        }
        return featuresCache[featureKey];
    };

    const setEmail = <TKey extends keyof EmailComposeModel["item"],>(key: TKey) =>
        (value: EmailComposeModel["item"][TKey]) =>
            setModel(model => {
                const newModel = { ...model };
                if (!newModel.item) {
                    newModel.item = {} as Partial<EmailComposeModel>["item"];
                }
                if (newModel.item) {
                    newModel.item[key] = value;
                }
                return newModel;
            });

    useEffect(() => {
        if (clientDetailContext?.invoiceIds?.length || clientDetailContext?.invoiceAll) {
            setModel(model => ({
                ...model, item: {
                    ...model.item,
                    quickfilter: EmailInvoiceListType.Selected,
                    includeInvoiceList: true,
                    ids: clientDetailContext?.invoiceIds.join(","),
                }
            } as EmailComposeModel));
        }
    }, [clientDetailContext?.invoiceAll, clientDetailContext?.invoiceIds]);

    const submit = async (type: "program" | "draft" | "send" = "send") => {
        if (isSending !== "") {
            return;
        }
        if (!requiredManager.validate()) {
            ToastService.showToast(translate.MissingRequiredFields, undefined, "warning");
            return;
        }
        setIsSending(type);
        model.item!.draft = type === "draft";

        const result = await ActivityService.setEmailNew(model as EmailComposeModel);
        if (result instanceof Error) {
            const messageError = (getError(result).message === "Fail-Hour") ? translate.CantScheduleSendingToPastDate : translate.ErrorProcessingRequest;
            showToast(messageError, undefined, "danger");
            setIsSending("");
        }
        else {
            showToast(type === "draft" ? translate.SavedChanges : translate.Sent, undefined, "success");
            onSubmit && onSubmit();
            setModel(getDefaultModel(props));
            setIsSending("");
            FloatingPanelService.hidePanel();
        }
    };

    const tabProps: TabMenuProps<EmailTabProps>["props"] = {
        model,
        setModel,
        getFeature,
        requiredManager,
    };

    const showProgramModal = () => {
        if (!requiredManager.validate()) {
            return;
        }

        const validDateManager = new RequiredManager();
        const Message = () => {
            const scheduleDateRef = useRef(model.item?.scheduleddate && OptionalMap(parseDate(model.item.scheduleddate), x => restTimezone(x).toJSON()));
            useEffect(() => {
                const button: HTMLButtonElement = document.querySelector(".btn.btn-primary.program-email-ready-button")!;
                button.disabled = !((parseDate(model.item?.scheduleddate)?.getTime() ?? 0) > Date.now());
            }, []);

            const onScheduleChange = validDateManager.makeRequiredWithId((x: string) => {
                scheduleDateRef.current = x;
                setEmail("scheduleddate")(x && OptionalMap(parseDate(x), y => restTimezone(y).toJSON()));
                const button: HTMLButtonElement = document.querySelector(".btn.btn-primary.program-email-ready-button")!;
                button.disabled = !((parseDate(x)?.getTime() ?? 0) > Date.now());
            }, "scheduleddate");

            return (
                <div className="row">
                    <label className="form-label">
                        {TranslationService.translate.DateAndTimeOfDelivery}:
                    </label>
                    <div className="col-6" style={{ height: 40 }}>
                        <DateTimePickerEditor onChange={onScheduleChange} minDate={new Date()}
                            defaultValue={model.item?.scheduleddate && OptionalMap(parseDate(model.item.scheduleddate), x => restTimezone(x).toJSON())} />
                    </div>
                    <ValidationMessage
                        onChange={onScheduleChange}
                        message={translate.CalendarValidatorTip} defaultValue={scheduleDateRef.current}
                        validationMethod={(value: string | undefined) => ((parseDate(value)?.getTime() ?? 0) > Date.now())} />
                </div>);
        };
        ModalService.showDefaultModal({
            acceptButtonLabel: TranslationService.translate.Program,
            onAcceptClick: () => submit("program"),
            acceptButtonClassName: "btn btn-primary program-email-ready-button",
            title: TranslationService.translate.Program,
            message: Message,
        });
    };

    return (
        <>
            <div className="floatingBody p-0">
                {isPreview ? <EmailTabPreview {...tabProps} /> : <EmailTabMessage {...tabProps} />}
            </div>
            <FloatingPanelFooter>
                <>
                    <div className="me-auto d-flex align-items-center">
                        <SwitchButton label={TranslationService.translate.SendMeACopy} onChange={setEmail("copyme")} defaultValue={Boolean(model?.item?.copyme)} />
                    </div>
                    <TooltipComponent title={TranslationService.translate.Preview}>
                        <button className='btn btn-link text-secondary' onClick={() => setIsPreview(x => !x)}>
                            <i className={"fa-regular fa-fw " + (isPreview ? "fa-eye-slash" : "fa-eye")} />
                        </button>
                    </TooltipComponent>
                    <div className="btn-group">
                        <button type="button" className="btn btn-primary" onClick={() => submit()}>{TranslationService.translate.Send} {!isNullOrWhitespace(isSending) && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}</button>
                        <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split" style={{ borderLeftColor: "#1f2b45" }} data-bs-toggle="dropdown" aria-expanded="false">
                            <span className="visually-hidden">Toggle Dropdown</span>
                        </button>
                        <ul className="dropdown-menu">
                            <li><button className="dropdown-item" onClick={showProgramModal}>{TranslationService.translate.Program}</button></li>
                            <li><button className="dropdown-item" onClick={() => submit("draft")}>{TranslationService.translate.SaveDraft}</button></li>
                        </ul>
                    </div>
                </>
            </FloatingPanelFooter>
        </>
    );
};

