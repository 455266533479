import { CSSProperties, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import ActivityService, { EmailInvoiceListType } from "../../../../services/ActivityService";
import CompanyService, { DataType } from "../../../../services/CompanyService";
import { FilterCollection } from "../../../../services/FilterService";
import InvoiceService from "../../../../services/InvoiceService";
import { formatDateDigits, formatDateShort } from "../../../../utils/FormatUtils";
import ModalService from "../../../shared/bootstrap/Modal";
import ToastContext, { ToastService } from "../../../shared/bootstrap/Toast";
import AdvancedFilters, { AdvancedFiltersButton, AdvancedFiltersContext } from "../../../shared/components/AdvancedFilters";
import Dropdown from "../../../shared/components/Dropdown";
import { AutocompleteGroup, DatePickerEditor, FileApi, MultiselectEditor, TagsDropdownEditor, TextAreaBigEditor } from "../../../shared/components/Editors";
import { SortDirection } from "../../../shared/entities/Sort";
import FloatingPanelService from "../../../shared/FloatingPanel";
import { TranslationService } from "../../../../services/TranslationService";
import { RequiredManager, ValidationMessage, ValidationMessageProps } from "../../../shared/RequieredManager";
import Table, { TableHeader } from "../../../shared/Table";
import TableContext, { TableContextValues } from "../../../task/TableContext";
import ClientDetailContext, { ClientDetailContextValues, ClientDetailProvider } from "../../ClientDetailContext";
import InvoiceTableProvider from "../../invoice/InvoiceTableProvider";
import { ActivityGetResponse } from "../entities/AcitivityGetResponse";
import { ActivityListResponse } from "../entities/AcitivityListResponse";
import { ActivitySetComment } from "../entities/ActivitySetComment";
import { FloatingPanelFooter } from "../../../shared/components/FloatingPanelFooter";
import TagService from "../../../../services/TagService";
import { addIf, Cast, hasValuesLength, OptionalMap } from "../../../../utils/Utils";
import FileService from "../../../../services/FileService";
import AdvancedFilterService from "../../../../services/AdvancedFilterService";
import InvoiceTableItemReadOnly from "../../invoice/InvoiceTableItemReadOnly";
import { MultiFileUpload } from "../../../shared/components/MultiFileUpload";
import { addTimezone, MapIfHasHours, restTimezone } from "../../../../utils/ParseUtils";
import { Mention, MentionsInput } from "react-mentions";
import TooltipComponent from "../../../shared/TooltipComponent";

export const NewComment = ({
    reload,
    clientid,
    data: original = undefined,
    handleClose,
    clientDetailContext
}: {
    reload: () => void,
    clientid: string,
    data?: ActivityListResponse.Item,
    handleClose?: () => void,
    clientDetailContext?: ClientDetailContextValues,
}) => {
    const { translate } = TranslationService;
    const { showToast } = useContext(ToastContext);
    const [invoiceIds, setInvoiceIds] = useState<number[]>(clientDetailContext?.invoiceIds || []);

    const [isSaving, setIsSaving] = useState(false);
    const requiredManager = useRef(new RequiredManager()).current;
    const [filters, setFilters] = useState<string[]>([]);
    const [fixedIos, setFixedIos] = useState<ActivityGetResponse["ios"]>();
    const [loadingFixedIos, setLoadingFixedIos] = useState(true);

    const createCommentModel = (original?: ActivityListResponse.Item): ActivitySetComment => {
        if (original === undefined) {
            return {
                item: {
                    PersonID: parseInt(clientid),
                    Content: "",
                    files: [],
                    ActivityTypeID: CompanyService.getActivityTypes().find(x => x.Comment && x.CommentDefault)?.ActivityTypeID,
                },
                filter: "",
                ids: "",
                quickfilter: undefined,
            };
        }
        const item = { ...original, PersonID: original.PersonId };
        for (const key of Object.keys(item).filter(x => !["ID", "Content", "PersonID", "Sent", "GroupID", "TaskID", "ActivityTypeID", "NotifyTo", "Tag_Message", "files", "MessageID", "tagList "].includes(x))) {
            delete item[key as keyof typeof item];
        }
        return Cast<ActivitySetComment>({
            item: { ...item, Sent: original.date, ActivityTypeID: original.ActivityTypeID, Tag_Message: original.Tags?.map(x => ({ TagID: x.TagID })) },
        });
    };
    const [comment, setComment] = useState<ActivitySetComment>(createCommentModel(original));
    const [files, setFiles] = useState<{ id: string; name: string; }[]>(original?.files.map(x => ({ id: x.id, name: x.fileName })) ?? []);

    const requestData = useCallback(
        async () => {
            if (original === undefined) {
                setLoadingFixedIos(false);
                return;
            }
            const result = await ActivityService.get(original?.ID);
            if (result instanceof Error) {
                setLoadingFixedIos(false);
                return;
            }
            setComment(comment => ({ ...comment, item: { ...comment.item, MessageID: result.item.MessageID } }));
            setLoadingFixedIos(false);
            if (result.ios !== undefined && result.ios.length > 0) {
                setFixedIos(result.ios);
            }
        },
        [original],
    );

    useEffect(() => {
        requestData();
    }, [requestData]);

    const fields: {
        title: string;
        onChange: (value: string) => void;
        type: DataType;
        items: { value: string; text: string; }[],
        defaultValue?: (current: ActivitySetComment) => string | undefined;
        className: string;
        secondaryVariant: boolean;
        placeholder?: string;
        extraStyle?: React.CSSProperties
    }[] = useMemo(() => [
        {
            title: translate.Date,
            onChange: (value: string) => setComment(comment => ({ ...comment, item: { ...comment.item, Sent: value } })),
            type: CompanyService.getSetting("canselectactivitydate") ? DataType.Date : DataType.Readonly,
            items: [],
            defaultValue: () => (CompanyService.getSetting("canselectactivitydate") ?
                (original?.date || formatDateShort(OptionalMap(original?.date, x => new Date(x)) ?? new Date())) :
                formatDateShort(OptionalMap(original?.date, x => new Date(x)) ?? new Date())
            ),
            className: "col-1",
            secondaryVariant: false,
        },
        {
            title: "",
            onChange: requiredManager.makeRequiredWithId((value: string) => setComment(comment => ({ ...comment, item: { ...comment.item, Content: value } })), "content"),
            type: DataType.CommentsLink,
            items: [],
            defaultValue: (current) => current?.item.Content,
            className: "",
            secondaryVariant: false,
            placeholder: translate.WriteComment,
        },
    ], [original, requiredManager, translate.Date, translate.WriteComment]);

    const otherFields: {
        title: string;
        onChange: (value: string) => void;
        type: DataType;
        items: { value: string; text: string; }[],
        defaultValue?: (current: ActivitySetComment) => string | undefined;
        className: string;
        secondaryVariant: boolean;
        placeholder?: string;
        extraStyle?: React.CSSProperties
    }[] = useMemo(() => [
        ...addIf(hasValuesLength(CompanyService.getActivityTypes().filter(x => x.Comment)), {
            title: translate.ActivityType2,
            onChange: requiredManager.makeRequiredIf(
                CompanyService.getSetting("mandatorycommenttype"),
                (value: string) => setComment(comment => ({ ...comment, item: { ...comment.item, ActivityTypeID: value ? parseInt(value) : undefined } })),
                "activitytype"),
            type: DataType.List,
            items: [{ value: "", text: translate.None }, ...CompanyService.getActivityTypes().filter(x => x.Comment).map(x => ({ value: x.ActivityTypeID.toString(), text: x.ActivityTypeName }))],
            defaultValue: (current: any) => current.item?.ActivityTypeID?.toString(),
            className: "",
            secondaryVariant: false
        }),
        ...addIf(CompanyService.getGroupName()?.length > 0, {
            title: CompanyService.getGroupName(),
            onChange: (value: string) => setComment(comment => ({ ...comment, item: { ...comment.item, GroupID: parseInt(value) } })),
            type: DataType.Group,
            items: [{ value: "0", text: clientid }],
            defaultValue: () => original?.groupId,
            className: "",
            secondaryVariant: false
        }),
    ], [clientid, original, requiredManager, translate.ActivityType2, translate.None]);

    const advancedFiltersContext = useContext(AdvancedFiltersContext);
    useEffect(() => {
        advancedFiltersContext.setShow(comment.quickfilter === 3);
    }, [advancedFiltersContext, comment.quickfilter]);

    const saveComment = async () => {
        if (isSaving) {
            return;
        }
        if (!requiredManager.validate()) {
            showToast(translate.MissingRequiredFields);
            return;
        }
        if (CompanyService.getSetting("mandatoryeffectiveactivityfile") && !comment.item.files?.length) {
            ToastService.showToast(TranslationService.translate.YouHaveToAttachAtLeastOneFileInAnEffectiveActivity, undefined, "warning");
            return;
        }
        setIsSaving(true);
        {
            const result = await TagService.createMissingTags(comment.item.Tag_Message?.map(x => x.TagID).join(", "));
            if (result instanceof Error) {
                showToast(translate.ErrorProcessingRequest, undefined, "danger");
                setIsSaving(false);
                return;
            }
            const val = result?.split(",").filter(x => x && x.length > 0).map(x => ({ TagID: parseInt(x) }));
            setComment(comment => ({ ...comment, item: { ...comment.item, Tag_Message: val } }));
            comment.item.Tag_Message = val;
        }

        if (comment.quickfilter === 3) {
            comment.filter = AdvancedFilterService.filterStringsToQueryString(filters);
        }

        if (Boolean(invoiceIds.length)) {
            comment.ids = invoiceIds.join(",");
            comment.quickfilter = 6;
        }
        comment.item.Content = ActivityService.replaceUsersTo(comment.item.Content, (user: string) => "@" + user);
        const result = await ActivityService.setComment(comment);
        if (result instanceof Error) {
            showToast(translate.ErrorProcessingRequest, undefined, "danger");
            setIsSaving(false);
            return;
        }
        await requestData();
        handleClose && await handleClose();
        setIsSaving(false);

        FloatingPanelService.hidePanel();
        reload();
    };

    const onFileChange = (files: FileApi[]) => {
        setComment(comment => ({ ...comment, item: { ...comment.item, files: files.map(x => ({ fileName: x.name, response: null, id: x.id })) } }));
        setFiles([...files]);
    };

    const onFilterChange = (value: string) => setComment(comment => ({ ...comment, filter: value }));
    const tableContext = new TableContextValues();
    tableContext.applyFilters = onFilterChange;

    const tableHeaders: TableHeader[] = [
        ...InvoiceService.getTableHeaders(),
    ];

    const tableValues = new TableContextValues();
    tableValues.error = false;
    tableValues.response = { list: fixedIos };
    tableValues.loading = false;
    tableValues.sort = { sortColumn: "", sortDirection: SortDirection.Ascending };
    tableValues.setSortColumn = () => Boolean(4);

    const showInvoiceTable = fixedIos !== undefined && CompanyService.getSetting("messageiolink");

    const changeSelection = () => {
        ModalService.showDefaultModal({
            acceptButtonLabel: "Ok",
            message: translate.ChangeSelectionMessage,
            onAcceptClick: () => {
                setFixedIos(undefined);
                setComment(x => ({
                    ...x,
                    quickfilter: -1 //Cast<number>(0)
                }));
                setLoadingFixedIos(false);
            },
            title: translate.ChangeSelection
        });
    };

    return (
        <>
            <div className="floatingBody p-4">
                {fields.slice(0, 1).map(x =>
                    <CommentFieldEdit key={x.title?.replaceAll(" ", "")} {...x} defaultValue={x.defaultValue && x.defaultValue(comment)} />
                )}
                <div className="row align-items-center mb-2 g-0">
                    <div className="col">
                        <TextWithMentions onChange={requiredManager.makeRequiredWithId((value: string) => setComment(comment => ({ ...comment, item: { ...comment.item, Content: value } })), "content")} defaultValue={comment.item.Content} onSelected={(userId) => setComment((comment) => ({ ...comment, item: { ...comment.item, NotifyTo: [...(new Set([...(comment.item.NotifyTo ?? "").split(","), userId])).values()].filterFalsey().join(",") } }))} />
                    </div>
                    <div style={{ paddingLeft: 14 }}><ValidationMessage onChange={requiredManager.getValidation("content")} defaultValue={comment.item.Content} validationMethod={(value: string | undefined) => value !== undefined && value.length > 0} /></div>
                </div>
                <div className="row">
                    <hr className="mt-2" />
                </div>
                <div className="row">
                    <ActionButtons
                        original={original}
                        onFilesChange={onFileChange}
                        files={files}
                        setComment={setComment}
                        comment={comment}
                        requiredManager={requiredManager}
                        showInvoiceTable={showInvoiceTable}
                        downloadFile={(id: any, name: any) => FileService.downloadFileMessage(id, original?.ID.toString() ?? "", name)}
                        linkedData={
                            <>
                                {showInvoiceTable &&
                                    <div className="col-12">
                                        <div className="row mb-2">
                                            <div className="col">
                                                <h6 className="mt-2">{translate.LinkedInvoices}</h6>
                                            </div>
                                            <div className="col-auto">
                                                <button className="btn btn-intiza" onClick={changeSelection}>{translate.ChangeSelection}</button>
                                            </div>
                                        </div>
                                        <TableContext.Provider value={tableValues}>
                                            <Table headers={tableHeaders} item={InvoiceTableItemReadOnly(undefined)} />
                                        </TableContext.Provider>
                                    </div>}

                                {loadingFixedIos ?
                                    <div className="col-11 align-items-center">
                                        <i className="fas fa-spinner-third fa-spin third ms-2"></i>
                                    </div>
                                    :
                                    (fixedIos === undefined && CompanyService.getSetting("messageiolink")) &&
                                    <CommentFieldDefaultEdit
                                        key={translate.LinkCommentWith.replaceAll(" ", "")}
                                        marginBottom=""
                                        label={false}
                                        {...{
                                            title: undefined,
                                            onChange: requiredManager.makeRequiredIf(CompanyService.getSetting(Cast<"mandatoryiolistincomment">("mandatoryiolistincomment")), (value: string) => setComment(comment => ({ ...comment, quickfilter: value === "" ? null : parseInt(value) })), "iocomment"),
                                            type: DataType.List,
                                            items: [ //due = 0, pending = 1, all = 2, filtered = 3, (Hay que incluir filter) claimable = 4, dueclaimable = 5, selected = 6 (Hay que incluir ids)
                                                ...addIf(Boolean(invoiceIds?.length), {
                                                    value: EmailInvoiceListType.SelectedList.toString(),
                                                    text: TranslationService.translate.SelectedInvoices.replace("{invoiceCount}", invoiceIds?.length.toString() ?? "0")
                                                }),
                                                { text: translate.NoInvoices, value: "-1" },
                                                { text: translate.PendingInvoices, value: "1" },
                                                { text: translate.AllPendingClaimable, value: "4" },
                                                { text: translate.DuesClaimable, value: "5" },
                                                { text: translate.DueInvoices, value: "0" },
                                                { text: translate.OtherFilters, value: "3" },
                                                { text: translate.SelectFromAList, value: "6" },
                                            ],
                                            defaultValue: comment.quickfilter?.toString(),
                                            className: "col-12"
                                        }} />
                                }
                            </>}
                        linkedDataInvoice={
                            <div className="col-12">
                                {comment.quickfilter === 6 &&
                                    <ClientDetailProvider>
                                        <InvoiceList personId={parseInt(clientid)} setIds={setInvoiceIds} />
                                    </ClientDetailProvider>
                                }
                            </div>}
                        otherFilter={comment.quickfilter === 3 ? <AdvancedFilters page={FilterCollection.Invoice} onFilterApply={setFilters} /> : null}
                    />
                </div>
                {otherFields.map(x =>
                    <CommentFieldEdit key={x.title?.replaceAll(" ", "")} {...x} defaultValue={x.defaultValue && x.defaultValue(comment)} />
                )}
            </div>
            <FloatingPanelFooter>
                <button className="btn btn-primary" onClick={saveComment}>
                    {translate.Save}
                    {isSaving && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                </button>
            </FloatingPanelFooter>
        </>
    );
};

const ActionButtons = ({
    onFilesChange,
    files = [],
    downloadFile: downloadFileAux,
    linkedData,
    requiredManager,
    setComment,
    comment,
    linkedDataInvoice,
    otherFilter,
    showInvoiceTable
}: any) => {
    const { translate } = TranslationService;
    const tagsValues = useMemo(() => comment.item?.Tag_Message?.map((x: any) => x.TagID.toString()) || [], [comment.item?.Tag_Message]);

    const fields = useMemo(() => [
        {
            title: translate.NotifyTo,
            onChange: (value: string) => setComment((comment: any) => ({ ...comment, item: { ...comment.item, NotifyTo: value } })),
            type: DataType.Multiselect,
            items: CompanyService.getUsers().map(x => ({ value: x.Id, text: x.Value })),
            className: "col",
            secondaryVariant: false
        },
        ...(CompanyService.getTags()?.length ? [{
            title: translate.Tags,
            onChange: requiredManager.makeRequiredIf(CompanyService.getSetting("mandatorytag"), (value: string) => {
                setComment((comment: any) => ({ ...comment, item: { ...comment.item, Tag_Message: value.split(", ").filter(x => x && x.length > 0).map(x => ({ TagID: parseInt(x) })) } }));
            }, "comment"),
            type: DataType.Tags,
            items: CompanyService.getTags().map(x => ({ value: x.Id, text: x.Value })),
            className: "col",
            secondaryVariant: false
        }] : [])
    ], [requiredManager, setComment, translate.NotifyTo, translate.Tags]);

    return (
        <>
            <MultiFileUpload onFilesChange={onFilesChange} canEdit downloadFile={downloadFileAux} files={files} />
            <div className="col-12">
                {fields[0] &&
                    <div className="d-flex align-items-center mb-1">
                        <div style={{ width: 45, textAlign: 'center' }}>
                            <TooltipComponent title={fields[0].title}>
                                <i className="fa fa-share-alt activity-icon-color my-2" onClick={() => { }} />
                            </TooltipComponent>
                        </div>
                        <CommentFieldDefaultEdit key={fields[0].title?.replaceAll(" ", "")} {...fields[0]} title={""} defaultValue={comment.item.NotifyTo} label={false} />
                    </div>
                }

                {fields[1] &&
                    <div className="d-flex align-items-center mb-1">
                        <div style={{ width: 45, textAlign: 'center' }}>
                            <TooltipComponent title={TranslationService.translate.Tags}>
                                <i className="fa fa-tags activity-icon-color my-2" onClick={() => { }} />
                            </TooltipComponent>
                        </div>
                        <CommentFieldDefaultEdit key={fields[1]?.title?.replaceAll(" ", "")} {...fields[1]} title={""} defaultValue={tagsValues.join(", ")} label={false} />
                    </div>
                }

                <div className={"d-flex" + (showInvoiceTable ? " align-items-start" : " align-items-center") + " mb-1"}>
                    <div style={{ width: 45, textAlign: 'center' }}>
                        <TooltipComponent title={TranslationService.translate.LinkCommentWith}>
                            <i className="fa fa-notes activity-icon-color my-2" onClick={() => { }} />
                        </TooltipComponent>
                    </div>
                    <div className="col">
                        {linkedData}
                    </div>
                </div>
            </div>
            <div className="col-12">
                {linkedDataInvoice}
                {comment.quickfilter === 3 ?
                    <div className="row mb-2">
                        <div className="col-auto pe-0">
                            <AdvancedFiltersButton />
                        </div>
                        <div className="col ps-0">
                            {otherFilter}
                        </div>
                    </div> : null}
            </div>
        </>
    );
};

export const FilesAttached = ({ files, downloadFile, removeFile }: any) => {
    return files.length > 0 && files.map((x: any) => <FileItem key={x.id} {...{ removeFile, downloadFile, x }} />);
};

const FileItem = ({ removeFile, downloadFile, x }: any) => {
    const [hoveredFileId, setHoveredFileId] = useState<string | null>(null);

    return (
        <div onMouseEnter={() => setHoveredFileId(x.id)} onMouseLeave={() => setHoveredFileId(null)}>
            <LabelActionText
                bgColor={hoveredFileId}
                text={x?.name || x?.fileName}
                action={(e: Event) => downloadFile(x.id, x.name)}
                actionRemove={removeFile ? (e: Event) => removeFile(x.id) : undefined}
            />
        </div>
    );
};

export const LabelActionText = ({ text, action, bgColor = true, actionRemove }: any) => {
    return (
        <div
            className="d-flex cursor-pointer p-0 m-0 "
            style={{ backgroundColor: "rgba(0, 118, 182, 0.08)", borderRadius: 2 }}>
            <div
                onClick={action}
                style={{ paddingLeft: 6, paddingRight: 3 }}>
                <small className="text-secondary text-secondary-label me-2">{text}</small>
            </div>
            {actionRemove &&
                <div
                    onClick={actionRemove}
                    className={bgColor ? "bg-del-file" : ""} style={{ paddingLeft: 4, paddingRight: 4 }}>
                    <svg height="14" width="14" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
                        <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" fill={bgColor ? "#de350B" : "#0076b6"}></path>
                    </svg>
                </div>}
        </div>
    );
};

const CommentFieldDefaultEdit = ({ type, title, onChange, className, items, defaultValue = undefined, secondaryVariant = false, placeholder = "", extraStyle = {}, label = true }: { onChange: (value: string) => void, type: DataType, title: string | undefined, className: string, items: { value: string, text: string }[], defaultValue?: string | undefined, secondaryVariant?: boolean, placeholder?: string, extraStyle?: React.CSSProperties, marginBottom?: string, label?: boolean }) => {
    let editor = undefined;
    let titleEditor = undefined;
    let validationEditor = undefined;
    const validationMethod: ValidationMessageProps<string>["validationMethod"] = undefined;

    switch (type) {
        case DataType.List:
            if (items.length === 0)
                throw new Error("items missing");
            editor = <Dropdown onChange={onChange} items={items} defaultValue={defaultValue} secondaryVariant={secondaryVariant} />;
            break;
        case DataType.Multiselect:
            {
                const selectItems = items.map(x => ({ value: x.value.toString(), label: x.text }));
                const def = defaultValue?.split(",").map(x => selectItems.find(y => y.value === x)!).filter(x => x !== undefined);
                // No viene el id del notificado, por ende no matchea el default en la edicion
                editor = <MultiselectEditor items={selectItems} callback={x => onChange(x ? x.join(",") : "")} value={def} />;
            }
            break;
        case DataType.Tags:
            editor = <TagsDropdownEditor onChange={(value) => {
                onChange(value);
            }} defaultValue={defaultValue} />;
            break;
        case DataType.Readonly:
            editor = <>{defaultValue}</>;
            break;
        default:
            throw new Error("Missing editor: " + DataType[type]);
    }

    switch (title) {
        case TranslationService.translate.Comment:
            titleEditor = <label className="form-label" style={{ paddingLeft: 14 }}>{title}</label>;
            break;
        default:
            titleEditor = <label className="form-label">{title}</label>;
            break;
    }

    switch (type) {
        default:
            validationEditor = <ValidationMessage onChange={onChange} defaultValue={defaultValue} validationMethod={validationMethod} />;
            break;
    }

    return (
        <div className={className}>
            <div className={"d-flex align-items-center"}>
                {label && titleEditor}
                <div className={"col"}>
                    {editor}
                </div>
                {validationEditor}
            </div>
        </div>
    );
};

const CommentFieldEdit = ({ type, title, onChange, className, items, defaultValue = undefined, secondaryVariant = false, placeholder = "", extraStyle = {}, marginBottom = "mb-3", label = true }: { onChange: (value: string) => void, type: DataType, title: string | undefined, className: string, items: { value: string, text: string }[], defaultValue?: string | undefined, secondaryVariant?: boolean, placeholder?: string, extraStyle?: React.CSSProperties, marginBottom?: string, label?: boolean }) => {
    let editor = undefined;
    let titleEditor = undefined;
    let validationEditor = undefined;
    let validationMethod: ValidationMessageProps<string>["validationMethod"] = undefined;

    switch (type) {
        case DataType.Group:
            editor = items.length === 1 ? <AutocompleteGroup onChange={(value) => onChange(value!.value)} clientId={items[0].text} /> : <input type="text" readOnly className="form-control-plaintext"></input>;
            break;
        case DataType.List:
            if (items.length === 0)
                throw new Error("items missing");
            editor = <Dropdown onChange={onChange} items={items} defaultValue={defaultValue} secondaryVariant={secondaryVariant} />;
            break;
        case DataType.CommentsLink:
            editor = <TextAreaBigEditor onChange={onChange} defaultValue={defaultValue} extraStyle={extraStyle} placeholder={placeholder} />;
            validationMethod = (value: string | undefined) => value !== undefined && value.length > 0;
            break;
        case DataType.Readonly:
            editor = <>{defaultValue}</>;
            break;
        case DataType.Date:
            editor = <DatePickerEditor showIcon onChange={(x) => onChange(formatDateDigits(MapIfHasHours(new Date(x), addTimezone), "en"))} defaultValue={defaultValue ? MapIfHasHours(new Date(defaultValue), restTimezone)!.toString() : defaultValue} />;
            break;
        default:
            editor = <p>-</p>;
            console.log("Missing editor: " + DataType[type]);
            break;
    }

    switch (type) {
        case DataType.Readonly:
            titleEditor = <label className="col-2 py-0 my-0">{title}</label>;
            break;
        default:
            titleEditor = <label className="col-2 py-0 my-0">{title}</label>;
            break;
    }

    switch (type) {
        case DataType.CommentsLink:
            validationEditor = <div style={{ paddingLeft: 14 }}><ValidationMessage onChange={onChange} defaultValue={defaultValue} validationMethod={validationMethod} /></div>;
            break;

        default:
            validationEditor = <ValidationMessage onChange={onChange} defaultValue={defaultValue} validationMethod={validationMethod} />;
            break;
    }

    return (
        <div className={`row align-items-center ${(type) === DataType.Readonly ? "mb-3" : "mb-2"} g-0`}>
            {(label && titleEditor && title && title?.length > 0) && titleEditor}
            <div className="col">
                {editor}
            </div>
            {validationEditor}
        </div>
    );
};

export const InvoiceList = ({ personId, style, setIds }: { personId: number, style?: CSSProperties, setIds: (ids: number[]) => void }) => {
    const { invoiceIds, setInvoiceIds } = useContext(ClientDetailContext);

    const toggleOneCheckbox = (invoiceId: number, checked: boolean) => {
        let newIds: number[] = [];
        if (checked) {
            newIds = [...invoiceIds, invoiceId];
        }
        else {
            newIds = invoiceIds.filter(x => x !== invoiceId);
        }
        setInvoiceIds(newIds);
        setIds(newIds);
    };

    const tableHeaders: TableHeader[] = [
        new TableHeader("Checkbox", () => <></>, false, false),
        ...InvoiceService.getTableHeaders()
    ];

    return (
        <InvoiceTableProvider clientId={personId}>
            <div className="row mb-2">
                <div className="col-auto pe-0">
                    <AdvancedFiltersButton />
                </div>
                <div className="col ps-0">
                    <AdvancedFilters page={FilterCollection.Invoice} />
                </div>
            </div>
            <div className="mb-2">
                <Table headers={tableHeaders} item={InvoiceTableItemReadOnly(toggleOneCheckbox)} />
            </div>
        </InvoiceTableProvider>
    );
};

const TextWithMentions = ({ onChange, onSelected, defaultValue }: { onChange: (value: string) => void, onSelected: (value: string) => void, defaultValue: string }) => {
    const [value, setValue] = useState(defaultValue.replaceAll('<br />', ''));
    const control: CSSProperties = {
        backgroundColor: "#fff",
        fontSize: 14,
        fontWeight: "normal",
        minHeight: 63,
    };
    const highlighter: CSSProperties = {
        padding: 9,
        border: "1px solid transparent",
    };
    const input: CSSProperties = {
        padding: 9,
        border: "1px solid silver",
    };
    const suggestionsList: CSSProperties = {
        backgroundColor: "white",
        border: "1px solid rgba(0,0,0,0.15)",
        fontSize: 14,
    };
    const suggestionItem: CSSProperties = {
        padding: "5px 15px",
        borderBottom: "1px solid rgba(0,0,0,0.15)",
    };
    const suggestionItemFocused: CSSProperties = {
        backgroundColor: "#cee4e5",
    };
    const mention: CSSProperties = {
        backgroundColor: "#0076b633",
        borderRadius: 5,
    };
    return (<MentionsInput value={value} onChange={x => { setValue(x.target.value); onChange(x.target.value); }} style={{
        control,
        highlighter,
        input,
        suggestions: {
            list: suggestionsList,
            item: {
                ...suggestionItem,
                "&focused": suggestionItemFocused,
            }
        },
    }
    }>
        <Mention
            trigger="@"
            data={(search) => CompanyService.getUsers().map(x => ({
                id: x.Id,
                display: "  " + x.Value + "  ",
            })).filter(x => x.display.toLowerCase().includes(search.toLowerCase())).slice(0, 5)}
            style={mention}
            onAdd={x => onSelected(x.toString())}
        />
    </MentionsInput>);
};

// function findIndexReverse(text: string, index = -1) {
//     if (index < 0) {
//         index = text.length + index; 
//     }
//     for (; index >= text.length; index--) {
//         const char = text[index];
//         if ()

//     }
// }

export default NewComment;