import { useState, useContext, ChangeEvent, useMemo, createContext, PropsWithChildren, useEffect } from "react";
import { TranslationService } from "../../../services/TranslationService";
import TableContext from "../../task/TableContext";
import RangedDatepicker from "./DateRangePicker";
import { formatDateDigits } from "../../../utils/FormatUtils";
import { AutocompleteClient, AutocompleteGroup, MultiselectEditor, TagsDropdownEditor } from "./Editors";
import FilterService, { FilterCollection, FilterDefinition, FilterOption } from "../../../services/FilterService";
import CompanyService, { DataType } from "../../../services/CompanyService";
import AdvancedFilterService, { Filter, FilterActivityTypeWithDate, FilterLine } from "../../../services/AdvancedFilterService";
import Collapsable from "../bootstrap/Collapsable";
import Dropdown, { DropdownProps } from "./Dropdown";
import ActivityService from "../../../services/ActivityService";
import { Moment } from "moment";
import moment from "moment";
import { useLocation } from "react-router-dom";
import TooltipComponent from "../TooltipComponent";

type AdvancedFiltersParams = {
    page: FilterCollection,
    onFilterApply?: (filters: string[]) => void,
    defaultValue?: string,
    onAppliedFiltersCount?: (filterCount: number) => void,
    showSaveFilter?: boolean;
    filterOptions?: FilterOption[];
    allowClosing?: boolean;
    removeApplyButtton?: boolean;
    autoApply?: boolean;
}

const AdvancedFilters = ({ page, onFilterApply = undefined, defaultValue = undefined, showSaveFilter = true, allowClosing = true, filterOptions, removeApplyButtton = false, autoApply = false }: AdvancedFiltersParams) => {
    const filtersOptions = filterOptions ?? FilterService.GetFiltersForPage(page);
    const { show, setShow, filtersCount, setFiltersCount } = useContext(AdvancedFiltersContext);
    const [filters, setFilters] = useState<FilterLine[]>(() => getDefaultFilters(defaultValue, filtersOptions));
    const [, setSelectedSavedFilter] = useState<SavedFilter>();
    // const location = useLocation();
    // const [savedFilters, setSavedFilters] = useState(StorageService.getPreferences().filters?.[location.pathname] ?? []);
    const { applyFilters } = useContext(TableContext);
    useEffect(() => {
        setFiltersCount(filters.filter(x => !x.isEmpty()).length);
        // Safety: We only need it the first time
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const _applyFilters = (filters: FilterLine[]) => {
        const filter = { extraFilters: filters.map(x => x.encodedFilter).filter(x => x !== undefined && x.length > 0) };
        setFiltersCount(filter.extraFilters.length);
        if (onFilterApply) {
            onFilterApply(filter.extraFilters);
        }
        else {
            applyFilters(filter);
        }
    };

    const deleteFilter = (filter: FilterLine | string) => {
        const id = typeof filter === "string" ? filter : filter.id;
        const newFilters = [...filters.filter(x => x.id !== id)];
        if (filters.filter(x => !x.isEmpty()).length !== newFilters.filter(x => !x.isEmpty()).length) {
            setSelectedSavedFilter(undefined);
        }
        setFilters(newFilters);
        if (newFilters.length === 0) {
            setFilters([new FilterLine()]);
            if (allowClosing) {
                setShow(false);
            }
        }
        if (newFilters.length !== filtersCount || autoApply) {
            _applyFilters(newFilters);
        }
    };

    const addFilter = () => {
        setFilters([...filters, new FilterLine()]);
    };

    const updateFilter = (item: FilterLine, value: Filter) => {
        setSelectedSavedFilter(undefined);
        Object.assign(item, value);
        setFilters([...filters]);
        if (autoApply) {
            applyFilter([...filters]);
        }
    };

    const cleanFilters = () => {
        setSelectedSavedFilter(undefined);
        setFilters([new FilterLine()]);
        if (filtersCount !== 0) {
            applyFilter([]);
        }
    };

    // const applySavedFilter = (filter?: SavedFilter) => {
    //     setSelectedSavedFilter(filter);
    //     if (!filter) {
    //         return;
    //     }
    //     const filters = AdvancedFilterService.parseFilters(AdvancedFilterService.filterStringsToQueryString(filter.filters), filtersOptions);
    //     setFilters(filters);
    // };

    // const saveFilter = () => {
    //     const data = {
    //         name: "",
    //     };
    //     const saveFilter = () => {
    //         const filtersStorage = StorageService.getPreferences().filters ?? {};
    //         const filtersForPage = filtersStorage[location.pathname].filter(x => x.name === data.name) ?? [];
    //         const filter =
    //         {
    //             name: data.name,
    //             filters: filters
    //                 .map(x => AdvancedFilterService.filterToString(x))
    //                 .filter(x => x !== undefined && x.length > 0),
    //         };
    //         filtersForPage.push(filter);
    //         filtersStorage[location.pathname] = filtersForPage;
    //         StorageService.setPreference("filters", filtersStorage);
    //         // setSavedFilters(filtersForPage);
    //         setSelectedSavedFilter(filter);
    //     };
    //     ModalService.showDefaultModal({
    //         title: TranslationService.translate.SaveFilter,
    //         message: () =>
    //             <div className="row">
    //                 <Field title={TranslationService.translate.Name} colClass="col-12 px-0">
    //                     <input className="form-control w-100" onChange={(e) => data.name = e.target.value} />
    //                 </Field>
    //             </div>,
    //         onAcceptClick: saveFilter,
    //         acceptButtonLabel: TranslationService.translate.Save,
    //     });
    // };

    const applyFilter = (orig?: FilterLine[]) => {
        const newFilters = [...(orig ?? filters).filter(x => Number(x.kind) !== -1)];
        newFilters.forEach(x => x.encodedFilter = AdvancedFilterService.filterToString(x));
        _applyFilters(newFilters);
    };

    const nonEmptyFilters = filters.filter(x => !x.isEmpty());

    return (
        <div>
            <Collapsable show={!show}>
                {nonEmptyFilters.length > 0 &&
                    <div className="pb-3" style={{ minHeight: show ? 120 : 0 }}>
                        <div className="chipsRowFilter m-0">
                            {nonEmptyFilters.map(x =>
                                <span className="chipItem" key={x.id}>
                                    {AdvancedFilterService.FriendlyName(x)} <span className="pointer ps-1" onClick={() => deleteFilter(x)}> × </span>
                                </span>)}
                        </div>
                    </div>}
            </Collapsable>
            <Collapsable show={show}>
                <div className="pb-3">
                    <div className="filters-container" style={{ minHeight: show ? 120 : 0 }}>
                        {filters.map((item, index) =>
                            <FilterComponent key={item.id} qtyFilters={filters.length} deleteFilter={() => deleteFilter(item)} updateFilter={value => updateFilter(item, value)} options={filtersOptions} defaultValue={item.isEmpty() ? undefined : item} />)}
                        <div className="d-flex justify-content-between">
                            <div className="addRow align-content-center">
                                <button className="btn btn-link p-0" onClick={addFilter}>
                                    <i className="fa-light fa-plus me-2"></i>{TranslationService.translate.Add}
                                </button>
                            </div>
                            {filters.length > 0 && !removeApplyButtton &&
                                <div className="addRow d-flex gap-12">
                                    {/* {false && <>{savedFilters.length > 0 && <Dropdown items={savedFilters.map(x => ({ text: x.name, value: x }))} onChange={applySavedFilter} optionLabel={TranslationService.translate.Select} value={selectedSavedFilter} />}
                            <button className="btn btn-link text-granite-gray" onClick={saveFilter}>
                                {TranslationService.translate.Save}
                            </button> </>} */}
                                    <button className="btn btn-outline-primary" onClick={cleanFilters}>
                                        {TranslationService.translate.Clear}
                                    </button>
                                    <button className="btn btn-primary" onClick={() => applyFilter()}>
                                        {TranslationService.translate.ApplyFilters}
                                    </button>
                                </div>}
                        </div>
                    </div>
                </div>
            </Collapsable>
        </div>
    );
};

export type SavedFilter = {
    name: string,
    filters: string[],
};

function getDefaultFilters(defaultValue: string | undefined, filtersOptions: FilterOption[]) {
    const filters = AdvancedFilterService.parseFilters(defaultValue, filtersOptions);
    if (filters.length === 0) {
        filters.push(new FilterLine());
    }
    return filters;
}

const FilterComponent = (props: { options: FilterOption[], deleteFilter: () => void, updateFilter: (filter: Filter) => void, qtyFilters: number, defaultValue?: Filter }) => {
    const [filter, setFilter] = useState(props.defaultValue);
    const { translate } = TranslationService;

    const onFilterChange = (property: string) => (value: string) => {
        const newFilter = filter!;
        Object.assign(newFilter, { [property]: value });
        setFilter({ ...newFilter });
        updateFilterValue({ ...newFilter });
    };

    const onChangeValueInput = (event: ChangeEvent<HTMLInputElement>) => onChangeValue(event.target.value);
    const onChangeValue = onFilterChange("value");

    const handleFilter = (definition: undefined | FilterDefinition) => {
        if (definition === undefined) {
            setFilter(undefined);
            return;
        }
        const filter: Filter = { kind: definition.Type, definition: definition };

        setDefaultValues(filter);
        updateFilterValue(filter);
        setFilter({ ...filter, operator: 0 });
        function setDefaultValues(filter: Filter) {
            switch (filter.kind) {
                case DataType.List:
                    //filter.value = filter.definition.AdditionalDefinitionItems[0].Value;
                    filter.value = undefined;
                    break;
                case DataType.YesNo:
                    filter.value = "0";
                    break;
                case DataType.Date:
                    filter.value = formatDateDigits(new Date(), "ja").replaceAll("/", "");
                    break;
                case DataType.Priority:
                    filter.value = "1";
                    break;
                default:
                    break;
            }
        }
    };

    const updateFilterValue = (filter: Filter) => {
        props.updateFilter(filter);
    };

    const formatDefinitionKey = (definition: FilterDefinition) => {
        return definition.Entity + "%" + definition.Field;
    };

    const mainDropdownItems: DropdownProps<FilterDefinition>["items"] = props.options
        .flatMap(x => [({ text: x.title, isTitle: true }), ...x.definitions.map(y => ({ text: y.Name, value: y, key: formatDefinitionKey(y) }))]);

    const values = filter?.value?.split(",");
    const mappedItems = filter?.definition.AdditionalDefinitionItems.map((x) => ({ label: x.Label, value: x.Value }));
    const selectedValues = mappedItems?.filter(x => values?.includes(x.value));
    const activityTypeFilterItems = useMemo(() => ActivityService.ActivityTypeFilterItems
        .map(x => ({ Label: TranslationService.getTranslation(x.Label), Value: x.Value })), []);

    const typeFilters = useMemo(() => ActivityService.TypeFilterItems
        .map(x => ({ Label: TranslationService.getTranslation(x.Label), Value: x.Value })), []);

    const setFilterCombo = (filter: Filter) => {
        setFilter(filter);
        updateFilterValue(filter);
    };

    return (
        <div className="filter-row">
            <div className="row ms-0 me-1">
                <div className="col-md-4">
                    <Dropdown value={filter?.definition} onChange={handleFilter} optionLabel={translate.Select} items={mainDropdownItems} showSearchFilter={true} />
                </div>
                {!filter &&
                    <>
                        <div className="col-md-4">
                            <Dropdown onChange={() => undefined} items={[]} />
                        </div>
                        <div className="col-md-4">
                            <input className="form-control" />
                        </div>
                    </>
                }
                {filter && DataType.List === filter.kind && <>
                    <SelectOperations operators={AdvancedFilterService.listOperators} value={filter.operator} filter={filter} setFilter={setFilterCombo} />
                    {filter.operator !== 7 &&
                        <MultipleSelectOperations selectedValues={selectedValues} keyValues={mappedItems!} filter={filter} setFilter={setFilterCombo}></MultipleSelectOperations>
                    }
                </>}
                {filter && DataType.ListNoOperator === filter.kind && <>
                    <SelectValue value={filter.value} keyValues={filter.definition.AdditionalDefinitionItems} onChangeValue={onChangeValue} />
                </>}
                {filter && DataType.Date === filter.kind && <DateTextboxValue defaultValue={filter.value} onChangeValue={onChangeValue} />}
                {filter && [DataType.Currency, DataType.Number, DataType.DaysOld].includes(filter.kind) && <>
                    <SelectOperations operators={AdvancedFilterService.numericOperator} value={(filter!).operator} filter={filter} setFilter={setFilterCombo} />
                    <div className="col-md-3"><input onChange={onChangeValueInput} className="form-control" defaultValue={filter.value} type="number" /></div>
                    {filter.kind === DataType.DaysOld && <div className="col-auto"><input type="text" readOnly className="form-control-plaintext" value={translate.DaysOld} /></div>}
                </>}
                {filter && DataType.DayCompare === filter.kind && <>
                    <SelectOperations operators={AdvancedFilterService.numericOperator} value={(filter!).operator} filter={filter} setFilter={setFilterCombo} />
                    <div className="col-md-2"><input onChange={onChangeValueInput} className="form-control" defaultValue={filter.value} type="number" /></div>
                    <div className="col-md-2"><input type="text" readOnly className="form-control-plaintext" value={translate.Days} /></div>
                </>}
                {filter && DataType.Tags === filter.kind &&
                    <div className="col-md-4">
                        <TagsDropdownEditor onChange={onChangeValue} defaultValue={filter.value} forceMultiple={true} />
                    </div>
                }
                {filter && DataType.ActivityType === filter.kind &&
                    <div className="col-md-4">
                        <MultiselectEditor callback={x => onChangeValue(x?.join(",") ?? "")} value={filter.value?.split(",").map(x => filter.definition.AdditionalDefinitionItems.find(y => y.Value === x)!).filter(x => x).map(x => ({ value: x.Value, label: x.Label }))} items={filter.definition.AdditionalDefinitionItems.map(x => ({ value: x.Value, label: x.Label }))} />
                    </div>
                }
                {filter && DataType.User === filter.kind &&
                    <SelectValue value={filter.value} keyValues={CompanyService.getUsers().map(x => ({ Label: x.Value, Value: x.Id }))} onChangeValue={onChangeValue} />
                }
                {filter && [DataType.Client, DataType.ClientGroup].includes(filter.kind) &&
                    <div className="col-md-4"><AutocompleteClient onChange={onChangeValue} defaultValue={filter.value} /></div>
                }
                {filter && [DataType.ClientGroup].includes(filter.kind) &&
                    <div className="col row align-items-center">
                        <div className="col-auto">{CompanyService.getGroupName()}:</div><div className="col-md-4"><AutocompleteGroup onChange={x => onFilterChange("operator")(x?.value ?? "")} clientId={filter.value ?? ""} /></div></div>
                }
                {filter && DataType.Priority === filter.kind &&
                    <SelectValue value={filter.value} keyValues={Array.from({ length: CompanyService.getTotalPriorities() }).map((x, i) => i + 1).map(x => ({ Label: x.toString(), Value: x.toString() }))} onChangeValue={onChangeValue} />
                }
                {filter && [DataType.Text, DataType.Phone, DataType.Link, DataType.TextWithOperator].includes(filter.kind) && <>
                    <SelectOperations operators={AdvancedFilterService.textWithOperatorOperators} value={filter.operator} filter={filter} setFilter={setFilterCombo} />
                    {filter.operator !== 7 && <div className="col-md-4"><input onChange={onChangeValueInput} defaultValue={filter.value} className="form-control" /></div>}
                </>}
                {filter && DataType.ExpirationDate === filter.kind && <>
                    <SelectValue value={filter.value} keyValues={AdvancedFilterService.expirationDateOptionsUntranslated.map(x => ({ Label: TranslationService.getTranslation(x.Label), Value: x.Value })).concat(CompanyService.GetAgeingValues().map(x => ({ Label: x.name, Value: x.filterVal })))} onChangeValue={onChangeValue} /></>
                }
                {filter && DataType.YesNo === filter.kind && <>
                    <SelectValue value={filter.value} keyValues={[{ Value: "0", Label: translate.No }, { Value: "1", Label: translate.Yes }]} onChangeValue={onChangeValue} /></>
                }
                {filter && filter.kind === DataType.Percentage && <>
                    <SelectOperations operators={AdvancedFilterService.percentageOperators} value={filter.operator} filter={filter} setFilter={setFilterCombo} />
                    <div className="col"><input onChange={onChangeValueInput} className="form-control" type={"number"} defaultValue={filter.value} /></div>
                    <div className="col-auto"><input type="text" readOnly className="form-control-plaintext mt-1" value={"% " + translate.OfUsedCredit} /></div>
                </>}
                {filter && DataType.Status === filter.kind &&
                    <SelectValue value={filter.value} keyValues={filter.definition.AdditionalDefinitionItems} onChangeValue={onChangeValue} />
                }
                {filter && DataType.Type === filter.kind &&
                    <SelectValue value={filter.value} keyValues={typeFilters} onChangeValue={onChangeValue} />
                }
                {filter && DataType.ActivityTypeWithDate === filter.kind && <>
                    <DateTextboxValue defaultValue={filter.value ?? null} onChangeValue={onChangeValue} small />
                    <div className="col-md-2">
                        <select className="form-control h-40px" value={(filter as FilterActivityTypeWithDate).activityTypeFilter} onChange={(e) => onFilterChange("activityTypeFilter")(e.target.value)}>
                            <option>{translate.All}</option>
                            {activityTypeFilterItems.map((item: { Label: string, Value: string }) => (
                                <option key={item.Value} value={item.Value}>{item.Label}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-2">
                        <select className="form-control h-40px" value={(filter as FilterActivityTypeWithDate).userId} onChange={(e) => onFilterChange("userId")(e.target.value)}>
                            <option>{translate.All}</option>
                            {CompanyService.getUsers().map((item) => (
                                <option key={item.Id} value={item.Id}>{item.Value}</option>
                            ))}
                        </select>
                    </div>
                </>}
            </div>
            <div className="delete">
                <div className="delete">
                    <button className="btn button-outline-primary" onClick={props.deleteFilter}><i className="fa-light fa-trash-can text-danger fa-lg"></i></button>
                </div>
            </div>
        </div>
    );
};

const DateTextboxValue = ({ onChangeValue, defaultValue, small = false }: { defaultValue?: string | null, onChangeValue: (value: string) => void, small?: boolean }) => {
    const location = useLocation();
    const [rangedDefaultDate, setRangedDefaultDate] = useState(defaultValue);
    const [daysCount, setDaysCount] = useState<Moment>();
    const [daysInput, setDaysInput] = useState("");
    const operators = [
        { text: TranslationService.translate.Equal, value: (day?: Moment) => [day, day] },
        { text: TranslationService.translate.Greater, value: (day?: Moment) => [day?.clone(), day?.add(50, "years")] },
        { text: TranslationService.translate.LessThan, value: (day?: Moment) => [day?.clone().add(-50, "years"), day] },
    ];
    const [daysOperator, setDaysOperator] = useState(() => operators[0].value);

    const updateRangedDatepicker = (days?: Moment, op?: typeof daysOperator) => {
        if (daysInput === "" && days === undefined) {
            return;
        }
        const opValue = op ?? daysOperator;
        const daysValue = days ?? daysCount;
        const [start, end] = opValue(daysValue);
        setRangedDefaultDate(!start ? null : (start.format("YYYYMMDD") + "-" + (end ?? start).format("YYYYMMDD")));
        onChange(start?.toDate(), end?.toDate());
    };
    const formatDate = (date: Date) => formatDateDigits(date, "ja").replaceAll("/", "");
    const onChange = (start?: Date, end?: Date) => {
        if (start === undefined) {
            onChangeValue("");
            return;
        }
        if (end === undefined) {
            onChangeValue("");
            return;
        }
        const value = (start.setHours(0, 0, 0, 0) === end.setHours(0, 0, 0, 0)) ? formatDate(start) : (formatDate(start) + "-" + formatDate(end));
        setRangedDefaultDate(value);
        onChangeValue(value);
        setDaysInput("");
    };

    const showDays = ["report/reportactivity", "report/ioactivity", "report/activitytypesummary", "ImportTemplateManual"].some(x => location.pathname.includes(x));
    const width = 2 << Number(!small || !showDays); 
    
    return (<>
        <div className={"col col-md-" + width}>
            <RangedDatepicker key={rangedDefaultDate} onChange={onChange} defaultValue={rangedDefaultDate} />
        </div>
        {showDays &&
            <div className="col pe-0 col-md-2">
                <div className="row align-items-center">
                    <div className="col-auto px-1 ps-2">|</div>
                    <div className="col ps-0">
                        <Dropdown items={operators} onChange={(op) => {
                            setDaysOperator(() => op);
                            updateRangedDatepicker(undefined, op);
                        }} />
                    </div>
                    <div className="col">
                        <input className="form-control d-block-inline" type="number" onChange={(e) => {
                            const limitDays = 365 * 25;
                            const days = Math.max(Math.min(e.target.valueAsNumber, limitDays), -limitDays);
                            const value = isNaN(days) ? undefined : moment().add(days, "day");
                            setDaysCount(value);
                            updateRangedDatepicker(value, undefined);
                            setDaysInput(e.target.value);
                        }} value={daysInput} />
                    </div>
                    <div className="col-auto p-0 ps-1">{TranslationService.translate.Days}</div>
                </div>
            </div>}
    </>);
};

export type AdvancedFiltersValues = {
    show: boolean,
    setShow: (val: boolean) => void,
    filtersCount: number,
    setFiltersCount: (val: number) => void,
};
export const AdvancedFiltersContext = createContext({} as AdvancedFiltersValues);
export const AdvancedFiltersProvider = ({ children }: PropsWithChildren<Record<string, unknown>>) => {
    const [show, setShow] = useState(false);
    const [filtersCount, setFiltersCount] = useState(0);
    return <AdvancedFiltersContext.Provider value={{ show, setShow, filtersCount, setFiltersCount }}>
        {children}
    </AdvancedFiltersContext.Provider>;
};

export const AdvancedFiltersButton = () => {
    const { filtersCount, setShow, show } = useContext(AdvancedFiltersContext);
    return (<>
        <span style={{ width: "auto", display: "flex", alignItems: "baseline", cursor: "pointer", color: "var(--intiza-darkGrey2)" }} onClick={() => setShow(!show)}>
            <TooltipComponent title={TranslationService.translate.Filters}>
                <button type="button" className={"btn button-outline-primary"}>
                    <i className="fal fa-bars-filter" />
                </button>
            </TooltipComponent>
            {filtersCount > 0 && <span className="ps-2">{TranslationService.translate.ActiveFilters}: {filtersCount}</span>}
        </span>
    </>);
};


const SelectOperations = ({ value, operators, filter, setFilter }: { value?: number, operators: readonly string[], filter: Filter, setFilter: (filter: Filter) => void }) => {
    const onChange = (value: string) => {
        const newFilter = filter!;
        newFilter.operator = operators.indexOf(value);
        if (value === "Empty") {
            newFilter.value = "";
        }
        setFilter(newFilter);
    };
    return (
        <div className="col-md-3">
            <Dropdown value={operators[value ?? 0]} onChange={onChange} items={operators.filter(x => x).map(x => ({ text: TranslationService.getTranslation(x), value: x }))} />
        </div>
    );
};

const MultipleSelectOperations = ({ selectedValues, keyValues, setFilter, filter }: { selectedValues?: { label: string, value: string }[], keyValues: { label: string, value: string }[], filter: Filter, setFilter: (filter: Filter) => void }) => {
    const { translate } = TranslationService;
    const [values, setValues] = useState(selectedValues);
    const onChange = (values: string[] | undefined) => {
        const newFilter = filter!;
        if (!values) {
            setFilter(newFilter);
            return;
        }

        setValues(values.map(x => keyValues.find(y => y.value === x)!));
        newFilter.value = values.join(",");
        setFilter(newFilter);
    };

    return (
        <div className="col-md-4">
            <MultiselectEditor placeholder={translate.Select} callback={onChange} value={values} items={keyValues} />
        </div>
    );
};

const SelectValue = ({ value, keyValues, onChangeValue }: { value?: string, keyValues: { Label: string, Value: string }[], onChangeValue: (val: string) => void }) => {
    return (
        <div className="col-md-4">
            <Dropdown value={value} onChange={onChangeValue} items={[{ text: TranslationService.translate.Select, value: "Empty" }, ...keyValues.map(x => ({ text: x.Label, value: x.Value }))]} />
        </div>
    );
};

export default AdvancedFilters;