import { ReactElement, useCallback, useContext, useState } from "react";
import * as yup from "yup";

import ErrorContext from "../shared/ErrorContext";
import { TranslationService } from "../../services/TranslationService";
import SubmitButton from "./components/SubmitButton";
import Form from "./components/Form";
import AuthenticationService, {
  ValidLogonResponse,
} from "../../services/AuthenticationService";
import { useHistory, useLocation } from "react-router-dom";
import AuthenticationContext from "../shared/AuthenticationContext";

const defaultValues = {
  code: "",
};

function CompleteSignin(): ReactElement {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(defaultValues);

  const { translate, currentLanguage } = TranslationService;
  const { setCurrentError } = useContext(ErrorContext);
  const { setCurrentAuthentication } = useContext(AuthenticationContext);

  const formDataSchema = yup.object({
    code: yup
      .string()
      .required(translate.TokenMissingCode),
  });

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const email = params.get("email") || "";
  const twofadata = params.get("token") || "";
  const remember = params.get("remember") || false;

  const handleCompleteSignIn = () => {
    setLoading(true);
    setCurrentError(undefined);
    formDataSchema
      .validate(formData)
      .then(async () => {
        const userData = await AuthenticationService.login2AFValidation(
          email,
          formData.code,
          twofadata
        );

        if (userData instanceof Error) {
          if (userData.message === "LoginIncorrect") {
            setCurrentError(translate.CodeIncorrect);
          } else {
            setCurrentError(translate.Network);
          }
        } else if (userData.response === "LoginIncorrect") {
          setCurrentError(translate.CodeIncorrect);
        } else if (userData.response === "OK" || userData.response === "Ok") {
          companyLogin(userData);
        }
      })
      .catch((err) => setCurrentError(err.errors[0]))
      .finally(() => setLoading(false));
  };

  const companyLogin = useCallback(
    async (userData: ValidLogonResponse) => {
      const companyData = await AuthenticationService.companyLogin(
        userData,
        remember === "ok"
      );

      if (companyData instanceof Error) {
        setCurrentError(translate.Network);
      } else {
        setCurrentAuthentication(companyData);
        history.push(`/${currentLanguage}/company`);
        window.location.reload();
      }
    },
    [
      currentLanguage,
      remember,
      history,
      setCurrentAuthentication,
      setCurrentError,
      translate.Network,
    ]
  );

  const handleInputChange = ({
    target: { name, value, checked, type },
  }: React.ChangeEvent<HTMLInputElement>) =>
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

  return (
    <Form back={false}>
      <h1>{translate.EnterLoginCodeToCompleteProcess}</h1>
      <p style={{ marginBottom: 10 }}>
        {translate.Instructions2FA.replace("{email}", email)}
      </p>
      <div className="input-group">
        <input
          type="text"
          className="code"
          placeholder={translate.Code}
          name="code"
          onChange={handleInputChange}
        />
      </div>
      <div className="login-btn" id="recover">
        <SubmitButton
          onClick={handleCompleteSignIn}
          text={translate.Send.toUpperCase()}
          loading={loading}
        />
      </div>
    </Form>
  );
}

export default CompleteSignin;
