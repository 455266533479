import { GroupListRequest } from "../pages/group/entities/GroupListRequest";
import { GroupListResponse } from "../pages/group/entities/GroupListResponse";
import { GroupSearchResponse } from "../pages/group/entities/GroupSearchResponse";
import RequestService from "./RequestService";
import { CacheEntity, StorageService } from "./StorageService";

class GroupService {
    public static search(personId: string, text: string): Promise<GroupSearchResponse | Error> {
        const request = {
            personid: personId,
            text
        };
        return RequestService.post("/group/search", request);
    }

    public static getGroupSelectOptions = async (personId: string | undefined, inputValue: string) => {
        let list: Array<{ value: string, label: string }> = [];
        const minInputLength = 2;
        if (!personId || inputValue.length < minInputLength) { return list; }
        const result = await GroupService.search(personId, inputValue);
        if (!(result instanceof Error)) {
            list = result.list.map(x => ({ value: x.data.toString(), label: x.value }));
        }
        return list;
    }

    public static list(request: Partial<GroupListRequest>): Promise<GroupListResponse | Error> {
        return RequestService.post("/group/list", { ...request });
    }

    private static listPromise: Record</*personid*/number, Promise<GroupListResponse.Item[]> | undefined> = {};
    public static async getGroupName(groupid: number, personid: number): Promise<string> {
        const cacheName = StorageService.getCache<string>(CacheEntity.GroupName, groupid.toString());
        if (cacheName) {
            return cacheName;
        }
        let tries = 3;
        const createPromise = async () => {
            const res = await GroupService.list({ personid });
            if (res instanceof Error) {
                GroupService.listPromise[personid] = undefined;
                return [];
            }
            res.list.forEach(x => {
                StorageService.setCache<string>(CacheEntity.GroupName, groupid.toString(), x.Name);
            });
            return res.list;
        };
        let promise = GroupService.listPromise[personid] ?? createPromise();
        while (promise !== undefined && tries--) {
            const result = await promise;
            const listPromiseName = result.find(x => x.GroupID === groupid)?.Name;
            if (listPromiseName) {
                return listPromiseName;
            }
            if (promise === GroupService.listPromise[personid]) {
                GroupService.listPromise[personid] = createPromise();
            }
            promise = GroupService.listPromise[personid]!;
        }
        return "";
    }
}

export default GroupService;