import { v4 } from "uuid";
import { clientCache } from "../pages/shared/components/Editors";
import { formatIntizaDate, formatDateDigits, formatInteger, formatNumber } from "../utils/FormatUtils";
import { parseDate, parseIntOrDefault } from "../utils/ParseUtils";
import { OptionalMap, isNullOrWhitespace } from "../utils/Utils";
import ActivityService from "./ActivityService";
import CompanyService, { DataType } from "./CompanyService";
import { FilterDefinition, FilterOption } from "./FilterService";
import { TranslationService } from "./TranslationService";
import moment from "moment";
import { CacheEntity, StorageService } from "./StorageService";
import { Group } from "../pages/task/entities/TaskGetResponse";

class AdvancedFilterService {
    public static filterToString(filter: Filter) {
        const requestParams: FilterQueryParam = {};
        switch (filter.kind) {
            case DataType.Text:
            case DataType.Phone:
            case DataType.Link:
            case DataType.TextWithOperator:
                requestParams.text = filter.value;
                requestParams.operatorContains = (filter.operator ?? 0)?.toString();
                break;
            case DataType.List:
                requestParams.selList = filter.value ?? filter.definition?.AdditionalDefinitionItems?.[0]?.Value ?? filter.value;
                requestParams.operatorList = (filter.operator ?? 0).toString();
                if (filter.definition.Field === "transactiontype") {
                    requestParams.transactiontype = filter.value ?? filter.definition?.AdditionalDefinitionItems?.[0]?.Value ?? filter.value;
                    requestParams.operatortransactiontypelist = (filter.operator ?? 0)?.toString();
                }
                break;
            case DataType.ListNoOperator:
                requestParams.selCashflow = filter.value ?? filter.definition?.AdditionalDefinitionItems?.[0]?.Value ?? filter.value;
                break;
            case DataType.Date:
                requestParams.selDate = filter.value ?? formatIntizaDate(new Date());
                break;
            case DataType.Number:
            case DataType.Currency:
            case DataType.DaysOld:
                requestParams.amount = filter.value;
                requestParams.selOperator = (filter.operator ?? 0)?.toString();
                break;
            case DataType.DayCompare:
                requestParams.date = filter.value;
                requestParams.selOperatorDate = (filter.operator ?? 0)?.toString();
                //&filter0=text%3d%26date%3d6%26amount%3d%26selFields%3d-1023%26selYesNo%3d0%26selStatus%3d0%26operatorContains%3d3%26selOperatorDate%3d2%26selOperator%3d0%26operatorList%3d0%26selList%3dActualizando%2blista...&filter1=text%3d%26date%3d%26amount%3d%26selFields%3d%26selYesNo%3d0%26selStatus%3d0%26operatorContains%3d3%26selOperatorDate%3d0%26selOperator%3d0%26operatorList%3d0%26selList%3dActualizando%2blista...
                //TODO: COMPLETAR SEL OPERATOR
                break;
            case DataType.Percentage:
                requestParams.percent = filter.value;
                requestParams.selOperatorPercent = (filter.operator ?? 0)?.toString();
                break;
            case DataType.Tags:
                requestParams.tagidsFilter = filter.value?.replaceAll(" ", "");
                break;
            case DataType.ActivityType:
                requestParams.selActivityType = filter.value;
                requestParams.activitytypeFilter = filter.value;
                requestParams.operatorContains = "3";
                break;
            case DataType.Client:
                requestParams.personidFilter = filter.value;
                break;
            case DataType.Priority:
                requestParams.selRank = filter.value ?? "1";
                break;
            case DataType.ExpirationDate:
                requestParams.selfilter = filter.value ?? "all";
                break;
            case DataType.ClientGroup:
                requestParams.personidFilter = filter.value;
                requestParams.groupFilterid = filter.operator ? filter.operator.toString() : undefined;
                break;
            case DataType.YesNo:
                requestParams.selYesNo = filter.value;
                break;
            case DataType.User:
                requestParams.useridFilter = filter.value;
                break;
            case DataType.Type:
                requestParams.typeFilter = filter.value;
                break;
            case DataType.Status:
                requestParams.selStatus = filter.value ?? "0";
                break;
            case DataType.ActivityTypeWithDate:
                {
                    const activityFilter = filter as FilterActivityTypeWithDate;
                    requestParams.selDateActivityType = filter.value;
                    requestParams.selActivityType = activityFilter?.activityTypeFilter ?? "";
                    requestParams.selActivityUser = activityFilter?.userId ?? "";
                }
                break;
            default:
                throw Error("Not implemented filter: " + JSON.stringify(filter));
        }
        requestParams.selFields = filter.definition.Field;
        const params = new URLSearchParams();
        Object.keys(requestParams).forEach(key => params.append(key, Reflect.get(requestParams, key)));
        return params.toString();
    }

    public static parseFilters(text: string | undefined, options: FilterOption[]): FilterLine[] {
        if (!text) {
            return [];
        }
        const textSplit = text.split("&").map(x => x.split("=")[1]);
        return textSplit.map(textFirst => {
            return FilterLine.From(textFirst, options);
        }).filter(x => !x.isEmpty());
    }

    public static FriendlyName(filter: Filter | undefined): string | undefined {
        if (!filter) {
            return undefined;
        }

        let friendlyName = filter.definition.Name + " - ";
        filter.value = filter?.value ?? "";
        try {
            const decodedValue = decodeURIComponent(filter.value);
            filter.value = decodedValue;
        } catch { }

        switch (filter?.kind) {
            case DataType.Text:
            case DataType.Phone:
            case DataType.Link:
            case DataType.TextWithOperator:
                friendlyName += TranslationService.getTranslation(AdvancedFilterService.textWithOperatorOperators[(filter.operator ?? 0)]);
                friendlyName = appendFriendly(friendlyName, filter.value);
                break;
            case DataType.List:
                {
                    friendlyName += TranslationService.getTranslation(AdvancedFilterService.listOperators[(filter.operator ?? 0)]);
                    const values = filter.value?.split(",");
                    const items = filter.definition.AdditionalDefinitionItems.filter(x => values?.includes(x.Value));
                    const value = items.map(x => x.Label).join(", ");
                    //const value = filter.definition.AdditionalDefinitionItems.find(x => x.Value === filter.value ?? filter.definition.AdditionalDefinitionItems[0].Value)?.Label;
                    friendlyName = appendFriendly(friendlyName, value);
                }
                break;
            case DataType.ListNoOperator:
                //friendlyName += filter.definition.AdditionalDefinitionItems.filter(x => x.Value === filter.value)[0].Label;
                friendlyName += filter.definition.AdditionalDefinitionItems.find(x => x.Value === filter.value ?? filter.definition.AdditionalDefinitionItems[0].Value)?.Label;
                break;
            case DataType.Date:
                friendlyName += formatDateFilter(filter);
                break;
            case DataType.PeriodoGestiones:
                friendlyName += formatDateFilter(filter);
                break;
            case DataType.Number:
            case DataType.Currency:
            case DataType.DaysOld:
                {
                    friendlyName += TranslationService.getTranslation(AdvancedFilterService.numericOperator[(filter.operator ?? 0)]) + " - ";
                    if (filter.kind === DataType.Currency) {
                        friendlyName += CompanyService.GetDefaultCurrencySymbol();
                    }
                    const numStr = (filter.value ?? "0").replaceAll(/\D/g, "");
                    const notNumStr = (filter.value ?? "").replaceAll(/\d/g, "").trim();
                    const num = parseFloat(numStr);
                    const formatter = num % 1 === 0 ? formatInteger : formatNumber;
                    if (notNumStr.length > 0) {
                        friendlyName += notNumStr + " ";
                    }
                    friendlyName += formatter(num);
                }
                break;
            case DataType.DayCompare:
                friendlyName += TranslationService.getTranslation(AdvancedFilterService.numericOperator[(filter.operator ?? 0)]) + " - ";
                friendlyName += parseFloat(filter.value ?? "0");
                break;
            case DataType.Percentage:
                friendlyName += TranslationService.getTranslation(AdvancedFilterService.percentageOperators[(filter.operator ?? 0)]) + " - ";
                friendlyName += filter.value + "%";
                break;
            case DataType.Tags:
                friendlyName += filter.value?.replaceAll(" ", "").split(",").map(x => CompanyService.getTags().find(y => y.Id === x)?.Value ?? undefined).filter(x => x !== undefined).join(", ");
                break;
            case DataType.ActivityType:
                friendlyName += filter.value.split(",").map(x => CompanyService.getActivityTypes().find(y => y.ActivityTypeID === parseInt(x))?.ActivityTypeName).filter(x => x !== undefined).join(", ");
                if (filter.value === "0") {
                    friendlyName += TranslationService.translate.None;
                }
                break;
            case DataType.ClientGroup:
                {
                    const clientName: string | undefined = StorageService.getCache(CacheEntity.Client, filter.value);
                    friendlyName += clientName;
                    if (filter.operator) {
                        const group: Group | undefined = StorageService.getCache(CacheEntity.GroupName, filter.operator);
                        if (group) {
                            friendlyName += group.Name;
                        }
                    }
                }
                break;
            case DataType.Client:
                friendlyName += clientCache.get(filter.value!);
                break;
            case DataType.Priority:
                friendlyName += filter.value ?? "1";
                break;
            case DataType.ExpirationDate:
                {
                    filter.value = filter.value === "" ? "all" : filter.value;
                    const item = AdvancedFilterService.expirationDateOptionsUntranslated.find(x => x.Value === filter.value);
                    const agingValues = CompanyService.GetAgeingValues();
                    friendlyName += item ? TranslationService.getTranslation(item.Label) : (agingValues[parseIntOrDefault(filter.value, 412)]?.name ?? agingValues.find(x => x.filterVal === filter.value)!.name);
                }
                break;
            case DataType.YesNo:
                friendlyName += filter.value === "0" ? TranslationService.translate.No : TranslationService.translate.Yes;
                break;
            case DataType.User:
                {
                    const valueEval = isNullOrWhitespace(filter.value)
                    const userSelecet = valueEval ? TranslationService.translate.NotSelected : CompanyService.getUsers().find(x => x.Id === filter.value)?.Value;
                    friendlyName += userSelecet
                }
                break;
            case DataType.Type:
                friendlyName = OptionalMap(ActivityService.TypeFilterItems.find(x => x.Value === filter.value)?.Label, x => friendlyName + TranslationService.getTranslation(x!)) ?? "";
                break;
            case DataType.Status:
                friendlyName += filter.definition.AdditionalDefinitionItems.find(x => x.Value === (filter.value ?? "0"))?.Label;
                break;
            case DataType.ActivityTypeWithDate:
                {
                    const activityFilter = filter as FilterActivityTypeWithDate;
                    friendlyName += formatDateFilter(filter);
                    const activityName = TranslationService.getTranslation(ActivityService.ActivityTypeFilterItems.find(x => x.Value === activityFilter.activityTypeFilter)?.Label ?? "");
                    friendlyName += isNullOrWhitespace(activityName) ? "" : (" - " + activityName);
                    const userName = CompanyService.getUsers().find(x => x.Id === activityFilter.userId)?.Value ?? "";
                    friendlyName += isNullOrWhitespace(userName) ? "" : (" - " + userName);
                }
                break;
        }
        return friendlyName;
    }
    public static filterStringsToQueryString(filters: string[] | undefined) {
        return filters?.map((value, index) => "filter" + index + "=" + encodeURIComponent(value)).join("&") ?? "";
    }
    public static filtersToRequestString(filters: Filter[]) {
        return encodeURIComponent(
            AdvancedFilterService.filterStringsToQueryString(
                filters.map(x => AdvancedFilterService.filterToString(x))
            ) ?? ""
        );
    }
    public static listOperators: readonly string[] = ["EqualTo", "IsNot", "", "", "", "", "", "Empty"];
    public static numericOperator: readonly string[] = ["EqualTo", "LessThan", "MoreThan", "", "", "IsNot"];
    public static percentageOperators: readonly string[] = ["EqualTo", "LessThan", "MoreThan"];
    public static expirationDateOptionsUntranslated: { Label: string; Value: string; }[] = [{ Value: "all", Label: "AllClients" }, { Value: "pending", Label: "PendingCustomersOnly" },];
    public static textWithOperatorOperators: readonly string[] = ["Equal", "", "", "Contains", "DoesntContain", "", "", "Empty"];
}

export default AdvancedFilterService;

export interface Filter {
    kind: DataType;
    definition: FilterDefinition;
    value?: string;
    valueid?: string;
    operator?: number;
}

export interface FilterActivityTypeWithDate extends Filter {
    activityTypeFilter: string;
    userId: string;
    operatorFilter?: string;
}

type FilterQueryParam = {
    operatorContains?: string;
    text?: string;
    selDate?: string;
    additional?: string;
    amount?: string;
    date?: string
    personFilter?: string;
    personidFilter?: string;
    selFields?: string;
    status?: string;
    selRank?: string;
    selActivityType?: string;
    operatorList?: string;
    selList?: string;
    selCashflow?: string;
    useridFilter?: string;
    selOperator?: string;
    selOperatorDate?: string;
    tagidsFilter?: string;
    selfilter?: string;
    selYesNo?: string;
    percent?: string;
    selOperatorPercent?: string;
    selDateActivityType?: string;
    selActivityUser?: string;
    activitytypeFilter?: string;
    selStatus?: string;
    typeFilter?: string;
    groupFilterid?: string;
    transactiontype?: string;
    operatortransactiontypelist?: string;
}

export class FilterLine implements Filter {
    kind: DataType;
    id: string;
    definition: FilterDefinition;
    encodedFilter: string
    value?: string;
    operator?: number;

    constructor() {
        this.id = v4();
        this.kind = -1 as DataType;
        this.definition = {} as FilterDefinition;
        this.encodedFilter = "";
    }

    isEmpty(): boolean {
        return this.kind < 0 && this.encodedFilter === "";
    }

    static From(encodedFilter: string, options: FilterOption[]) {
        const parsedFilter: string = window.decodeURIComponent(encodedFilter);
        const item: FilterQueryParam = parsedFilter.split("&").reduce((prev, curr) => {
            const [key, value] = curr.split("=");
            prev[key] = value;
            return prev;
        }, {} as { [key: string]: string });

        const definition = options.reduce<FilterDefinition | undefined>((prev, cur) => {
            if (prev !== undefined) {
                return prev;
            }

            return cur.definitions.find(y => y.Field === item.selFields);
        }, undefined);

        try {
            if (item.text) {
                const decodedValue = decodeURIComponent(item.text);
                item.text = decodedValue;
            }
        } catch { }
        const filter = new FilterLine();
        if (definition === undefined) {
            return filter;
        }
        filter.kind = definition.Type;
        filter.definition = definition;

        switch (definition.Type) {
            case DataType.Text:
            case DataType.Phone:
            case DataType.Link:
            case DataType.TextWithOperator:
                filter.value = item.text;
                filter.operator = parseIntOrDefault(item.operatorContains, 0);
                break;
            case DataType.List:
                filter.value = item.selList ?? filter.definition.AdditionalDefinitionItems[0].Value;
                filter.operator = parseIntOrDefault(item.operatorList, 0);
                break;
            case DataType.ListNoOperator:
                filter.value = item.selCashflow ?? filter.definition.AdditionalDefinitionItems[0].Value;
                break;
            case DataType.Date:
                filter.value = item.selDate ?? item.date;
                filter.operator = parseIntOrDefault(item.selOperator, parseIntOrDefault(item.selOperatorDate, 0));
                break;
            case DataType.PeriodoGestiones:
            case DataType.ActivityTypeWithDate:
                {
                    const activityFilter = filter as unknown as FilterActivityTypeWithDate;
                    filter.value = item.selDateActivityType;
                    activityFilter.activityTypeFilter = item.selActivityType ?? "0";
                    activityFilter.userId = item.selActivityUser ?? "0";
                }
                break;
            case DataType.Number:
            case DataType.Currency:
            case DataType.DaysOld:
                filter.value = item.amount;
                filter.operator = parseIntOrDefault(item.selOperator, 0);
                break;
            case DataType.DayCompare:
                filter.value = item.date;
                filter.operator = parseIntOrDefault(item.selOperatorDate, 0);
                break;
            case DataType.Percentage:
                filter.value = item.percent;
                filter.operator = parseIntOrDefault(item.selOperatorPercent, 0);
                break;
            case DataType.Tags:
                filter.value = item.tagidsFilter?.replaceAll(",", ", ");
                break;
            case DataType.ActivityType:
                filter.value = item.activitytypeFilter?.length ? item.activitytypeFilter : item.selActivityType;
                break;
            case DataType.Client:
                filter.value = item.personidFilter;
                break;
            case DataType.Priority:
                filter.value = item.selRank ?? "1";
                break;
            case DataType.ClientGroup:
                filter.value = item.personidFilter;
                filter.operator = OptionalMap(item.groupFilterid, x => parseInt(x));
                break;
            case DataType.ExpirationDate:
                filter.value = item.selfilter;
                break;
            case DataType.YesNo:
                filter.value = item.selYesNo;
                break;
            case DataType.User:
                filter.value = item.useridFilter ?? item.selList;
                break;
            case DataType.Type:
                filter.value = item.typeFilter ?? item.selList;
                break;
            case DataType.Status:
                filter.value = item.selStatus ?? "0";
                break;
            default:
                throw Error("Not implemented filter: " + JSON.stringify(filter));
        }

        filter.encodedFilter = parsedFilter;
        filter.definition = definition;
        filter.kind = filter.definition.Type;
        return filter;
    }
}

function formatDateFilter(filter: Filter) {
    const datesCustom = filter.value && convertirFormatoFecha(filter.value);
    const dates = datesCustom?.split("-").map(x => formatDateDigits(parseDate(x) ?? new Date()));
    return dates?.join(` ${TranslationService.translate.DateTo} `) ?? formatDateDigits(new Date());
}

function appendFriendly(firendly: string, value: string | undefined) {
    if (isNullOrWhitespace(value)) {
        return firendly;
    }
    return firendly + " - " + (value);
}

function convertirFormatoFecha(cadenaFecha: string) {
    const partes = cadenaFecha.split("-").map(parte => parte.trim());
    const formatoEntrada = "DD/MM/YYYY";

    const fechaInicioMoment = moment(partes[0], formatoEntrada, true);
    const fechaFinMoment = moment(partes[1], formatoEntrada, true);

    if (fechaInicioMoment.isValid() && fechaFinMoment.isValid()) {
        const formatoSalida = "YYYYMMDD";
        const fechaInicioFormateada = fechaInicioMoment.format(formatoSalida);
        const fechaFinFormateada = fechaFinMoment.format(formatoSalida);
        return `${fechaInicioFormateada}-${fechaFinFormateada}`;
    } else {
        return cadenaFecha;
    }
}


// function formatRangeDates(cadenaFecha: string) {
//     const nodes = cadenaFecha.split('-');

//     // fechas en formato "dd/mm/yyyy"
//     const startDate = nodes[0].trim();
//     const endDate = nodes[1].trim();

//     const formatedStartDate = formatDateYMD(startDate)
//     const formatedEndDate = formatDateYMD(endDate)
//     return `${formatedStartDate}-${formatedEndDate}`;
// }

// // // Formatea "yyyymmdd"
// function formatDateYMD(date: string) {
//     const dateObj = new Date(`${date.split('/').reverse().join('-')}T00:00:00`);
//     return moment(dateObj.toISOString()).format('YYYYMMDD');
// }

// const formatValidDate = (cadenaFecha: string) => {
//     const fechaMoment = moment(cadenaFecha, 'YYYYMMDD', true);
//     return fechaMoment.isValid() && fechaMoment.format('YYYYMMDD') === cadenaFecha;
// };