import React, { useCallback, useLayoutEffect, useRef } from "react";

export const FixToTop = ({ children, backgroundClass = "sticky-shadow backPublicSmooth", isFixed = false, id = '' }: React.PropsWithChildren<{ backgroundClass?: string, isFixed?: boolean, id?: string }>) => {
    const divRef = useRef<HTMLDivElement | null>(null);
    const rootRef = useRef(document.querySelector(":root") as HTMLElement);
    const otherDivs = useRef<HTMLDivElement[]>([]);

    const setHeadersTop = (height: number) => rootRef.current.style.setProperty("--fixed-to-top-height", height + "px");
    const animation = useCallback(() => {
        const isBeingPushed = (el: HTMLElement) => {
            const startedScrolling = (el.offsetTop) > 0;
            const reachedTheEnd = (el.parentElement as HTMLDivElement).clientHeight === (el.offsetTop + el.clientHeight);
            return startedScrolling && !reachedTheEnd;
        };
        if (divRef.current === null) {
            return;
        }
        divRef.current.dataset["fixed"] = isFixed ? "fixed" : undefined;
        const fixedDivs = otherDivs.current;
        const parent = divRef.current.parentElement!;
        const parentOffset = parent.offsetTop;
        const parentHeightPlusOffsetTop = (parent.offsetTop + parent.clientHeight);
        let ourTop = -8;
        const ourIndex = fixedDivs.indexOf(divRef.current);
        //Checking if there are other FixToTop
        if (fixedDivs.length !== 1) {
            ourTop += fixedDivs.slice(0, ourIndex)
                .filter(x => x.dataset["fixed"] === "fixed")
                .reduce((prev, curr) => prev + curr.clientHeight, 0);
        }
        divRef.current.style.top = ourTop + "px";
        divRef.current.style.zIndex = (fixedDivs.length - ourIndex + 5).toString();

        //Checking if we have to move the table header
        const shouldMoveTableHeader = fixedDivs.length === 1 || (isBeingPushed(divRef.current) && ourIndex === 1);
        if (shouldMoveTableHeader) {
            setHeadersTop(divRef.current.clientHeight + ourTop);
        }
        const debugInfo = () => {
            const div: HTMLDivElement = document.body.querySelector(".row .aboutCompanyItem")!;
            if (!div) {
                return;
            }
            if (div.dataset["removed"] !== "removed") {
                div.dataset["removed"] = "removed";
                div.querySelectorAll(":not(div)").forEach(x => x.remove());
                div.innerHTML = fixedDivs.map(x => "<div class='col-12'></div>").join("\n");
                return;
            }
            const divs = [...div.querySelectorAll(".col-12")] as HTMLDivElement[];
            const infoDiv = divs[ourIndex];
            const data: Record<string, string | number | boolean> = {
                parentOffset,
                parentHeight: parent.clientHeight,
                parentHeightPlusOffsetTop,
                clientHeight: divRef.current!.clientHeight,
                offsetTop: divRef.current!.offsetTop,
                _____: "",
                // clientTop: divRef.current!.clientTop,
                // scrollTop: divRef.current!.scrollTop,
                ourTop,
                headersTop: divRef.current!.clientHeight + ourTop,
                reachedTheEnd: parent.clientHeight === (divRef.current!.offsetTop + divRef.current!.clientHeight),
                isBeingPushed: !!divRef.current && isBeingPushed(divRef.current),
                shouldMoveTableHeader,
                actualHeadersTop: getComputedStyle(rootRef.current).getPropertyValue("--fixed-to-top-height"),
            };
            infoDiv.style.borderBottom = "solid 5px #4444ff50";
            infoDiv.innerHTML = Object.keys(data).map(key => `<div><b>${key}: </b>${data[key]}</div>`).join("\n");
        };
        DynamicsCompressorNode.bind(debugInfo);
        requestAnimationFrame(animation);
    }, [isFixed]);
    useLayoutEffect(() => {
        otherDivs.current = [...window.document.querySelectorAll(".sticky-to-top")] as HTMLDivElement[];
        requestAnimationFrame(animation);
        return () => {
            divRef.current = null;
            setHeadersTop(0);
        };
    }, [animation]);
    return <div className={"sticky-to-top " + backgroundClass} ref={divRef} id={id} >
        {children}
    </div>;
};
