export interface ImportPreviewResponse {
    validdates: number
    alerterror: boolean
    alertio: boolean
    errorcount: number
    errors: ImportPreviewResponse.Error[]
    errorrecords: ImportPreviewResponse.Error[]
    alertblank: boolean
    newcount: number
    newios: ImportPreviewResponse.Newio[]
    newrecords: ImportPreviewResponse.NewClient[],
    newRecords?: ImportPreviewResponse.NewPayment[],
    additionalitemcount: number
    additionalitems: any[]
    updatecount: number
    updates: ImportPreviewResponse.Update[]
    updaterecords: ImportPreviewResponse.UpdateRecord[]
    partialcount: number
    partials: ImportPreviewResponse.Partial[]
    recoveredcount: number
    recoveredios: ImportPreviewResponse.Newio[]
    zeroCount: number
    zeroios: any[]
    samecount: number
    sameios: any[]
    repeatedcount: number
    repeatedios: any[]
    repateatedcancel: boolean
    fullcount: number
    fullios: ImportPreviewResponse.Fullio[]
    personcount: number
    persons: string[]
    alertperson: boolean
    groupcount: number
    groups: any[]
    errorcurrencycount: number
    errorcurrencies: any[]
    existing: number
    items?: ImportPreviewResponse.Item[]
}
export namespace ImportPreviewResponse {
    export interface Error {
        cell: number
        cols: string
        values: string[]
        Repeats: number
        type: number
        types: number[]
        columns: string[]
    }

    export interface Newio {
        additionalList: AdditionalList[]
        payments: any[]
        qbBalance: number
        IOID: number
        io: any
        ios: any[]
        PersonKey: string
        PersonID: number
        emittedDate: string
        amount: number
        IOStatusID: any
        PartialPaid: boolean
        FullPaid: boolean
        transactionPaid: number
        dueDate?: string
        group: any
        groupId: number
        referenceNumber: string
        description: any
        Index: number
        fullPaid: boolean
        amountUpdated: boolean
        values: string[]
        CurrencyID: number
        CurrencySymbol: string
        CurrencyRate: number
        ExternalIntegrator: any
        ExternalID: any
        ExternalPersonID: any
        key: string
        updated: boolean
        updatedio: boolean
        updatedadditional: boolean
        updatedamount: boolean
        newRecords?: ImportPreviewResponse.Newio[],
    }

    export interface AdditionalList {
        Id: number
        value: string
        AdditionalDefinition: any
        status: number
    }

    export interface Update {
        additionalList: AdditionalList2[]
        payments: any[]
        qbBalance: number
        IOID: number
        io: Io
        ios: Io2[]
        PersonKey: string
        PersonID: number
        emittedDate: string
        amount: number
        IOStatusID: any
        PartialPaid: boolean
        FullPaid: boolean
        transactionPaid: number
        dueDate?: string
        group: any
        groupId: number
        referenceNumber: string
        description: any
        Index: number
        fullPaid: boolean
        amountUpdated: boolean
        values: string[]
        CurrencyID: number
        CurrencySymbol: string
        CurrencyRate: number
        ExternalIntegrator: any
        ExternalID: any
        ExternalPersonID: any
        key: string
        updated: boolean
        updatedio: boolean
        updatedadditional: boolean
        updatedamount: boolean
    }

    export interface AdditionalList2 {
        Id: number
        value: string
        AdditionalDefinition: any
        status: number
    }
    export interface NewClient {
        additionalList: AdditionalList[]
        hasPersonData: boolean
        Repeats: number
        person: any
        persons: any
        Name: string
        Contact: any
        Email: any
        Phone: string
        Address: string
        Comments: any
        CreditLimit: any
        CreditLimitSet: boolean
        values: string[]
        Index: number
        ExternalIntegrator: any
        ExternalID: any
        ExternalCompanyID: any
        updated: boolean
    }

    export interface Io {
        IOID?: number
        ioId: number
        amount: number
        paid: number
        personId: number
        personName: string
        description: string
        dueDate?: string
        emittedDate: string
        referenceNumber: string
        currencyRate: number
        groupId: any
        currencyId: number
        IOStatusID?: number
        ListField1?: number
        ListField2?: number
        ListField100: any
        TextField100: any
        TextField2?: string
        TextField10?: string
        TextField15?: string
        TextField18?: string
        TextField23?: string
        TextField25?: string
        TextField5?: string
        TextField7?: string
        TextField8?: string
        TextField9?: string
        TextField14?: string
        TextField12?: string
        TextField4?: string
        TextField6?: string
        TextField17?: string
    }

    export interface Io2 {
        ioId: number
        amount: number
        paid: number
        personId: number
        personName: string
        description: string
        dueDate?: string
        emittedDate: string
        referenceNumber: string
        currencyRate: number
        groupId: any
        currencyId: number
        IOStatusID?: number
        ListField1?: number
        ListField2?: number
        ListField100: any
        TextField100: any
        TextField2?: string
        TextField10?: string
        TextField15?: string
        TextField18?: string
        TextField23?: string
        TextField25?: string
        TextField5?: string
        TextField7?: string
        TextField8?: string
        TextField9?: string
        TextField14?: string
        TextField12?: string
        TextField4?: string
        TextField6?: string
        TextField17?: string
    }

    export interface Partial {
        additionalList: AdditionalList3[]
        payments: any[]
        qbBalance: number
        IOID: number
        io: Io3
        ios: Io4[]
        PersonKey: string
        PersonID: number
        emittedDate: string
        amount: number
        IOStatusID: any
        PartialPaid: boolean
        FullPaid: boolean
        transactionPaid: number
        dueDate?: string
        group: any
        groupId: number
        referenceNumber: string
        description: any
        Index: number
        fullPaid: boolean
        amountUpdated: boolean
        values: string[]
        CurrencyID: number
        CurrencySymbol: string
        CurrencyRate: number
        ExternalIntegrator: any
        ExternalID: any
        ExternalPersonID: any
        key: string
        updated: boolean
        updatedio: boolean
        updatedadditional: boolean
        updatedamount: boolean
    }

    export interface AdditionalList3 {
        Id: number
        value: string
        AdditionalDefinition: any
        status: number
    }

    export interface Io3 {
        ioId: number
        amount: number
        paid: number
        personId: number
        personName: string
        description: string
        dueDate: string
        emittedDate: string
        referenceNumber: string
        currencyRate: number
        groupId: any
        currencyId: number
        IOStatusID?: number
        ListField100: any
        TextField100: any
        TextField5?: string
        TextField6?: string
        TextField7?: string
        TextField8?: string
        TextField9?: string
        TextField10?: string
        TextField17?: string
        TextField23?: string
        TextField2?: string
        TextField12?: string
        TextField15?: string
        TextField18?: string
        ListField1?: number
        ListField2?: number
        TextField14?: string
    }

    export interface Io4 {
        ioId: number
        amount: number
        paid: number
        personId: number
        personName: string
        description: string
        dueDate: string
        emittedDate: string
        referenceNumber: string
        currencyRate: number
        groupId: any
        currencyId: number
        IOStatusID?: number
        ListField100: any
        TextField100: any
        TextField5?: string
        TextField6?: string
        TextField7?: string
        TextField8?: string
        TextField9?: string
        TextField10?: string
        TextField17?: string
        TextField23?: string
        TextField2?: string
        TextField12?: string
        TextField15?: string
        TextField18?: string
        ListField1?: number
        ListField2?: number
        TextField14?: string
    }

    export interface Fullio {
        additionalList: any[]
        payments: any[]
        qbBalance: number
        IOID: number
        io: Io5
        ios: Io6[]
        PersonKey: string
        PersonID: number
        emittedDate: string
        amount: number
        IOStatusID: any
        PartialPaid: boolean
        FullPaid: boolean
        transactionPaid: number
        dueDate?: string
        group: any
        groupId: number
        referenceNumber: string
        description: string
        Index: number
        fullPaid: boolean
        amountUpdated: boolean
        values: any[]
        CurrencyID: any
        CurrencySymbol: any
        CurrencyRate: number
        ExternalIntegrator: any
        ExternalID: any
        ExternalPersonID: any
        key: string
        updated: boolean
        updatedio: boolean
        updatedadditional: boolean
        updatedamount: boolean
    }

    export interface Io5 {
        ioId: number
        amount: number
        paid: number
        personId: number
        personName: string
        description: string
        dueDate?: string
        emittedDate: string
        referenceNumber: string
        currencyRate: number
        groupId?: number
        currencyId: number
        IOStatusID?: number
        ListField100: any
        TextField2?: string
        TextField10?: string
        TextField12?: string
        TextField15?: string
        TextField18?: string
        TextField23?: string
        TextField25?: string
        TextField26?: string
        TextField100: any
        ListField1?: number
        TextField5?: string
        TextField6?: string
        TextField7?: string
        TextField8?: string
        TextField9?: string
        TextField17?: string
        ListField2?: number
        TextField14?: string
        TextField4?: string
    }

    export interface Io6 {
        ioId: number
        amount: number
        paid: number
        personId: number
        personName: string
        description: string
        dueDate?: string
        emittedDate: string
        referenceNumber: string
        currencyRate: number
        groupId?: number
        currencyId: number
        IOStatusID?: number
        ListField100: any
        TextField2?: string
        TextField10?: string
        TextField12?: string
        TextField15?: string
        TextField18?: string
        TextField23?: string
        TextField25?: string
        TextField26?: string
        TextField100: any
        ListField1?: number
        TextField5?: string
        TextField6?: string
        TextField7?: string
        TextField8?: string
        TextField9?: string
        TextField17?: string
        ListField2?: number
        TextField14?: string
        TextField4?: string
    }

    export interface UpdateRecord {
        additionalList: AdditionalList[]
        hasPersonData: boolean
        Repeats: number
        person: Person
        persons: Person2[]
        Name: string
        Contact: any
        Email: any
        Phone: any
        Address: string
        Comments: any
        CreditLimit: any
        CreditLimitSet: boolean
        values: string[]
        Index: number
        ExternalIntegrator: any
        ExternalID: any
        ExternalCompanyID: any
        updated: boolean
    }

    export interface Person {
        CRMSaleAssigned: any
        customerSite: string
        followUpMail: string
        clearPhone: string
        PersonId: number
        Name: string
        Email: string
        Contact: string
        Phone: string
        Address: string
        Created: string
        CreatorUserID: number
        Comments: string
        Token: string
        ImportID: any
        ExternalID: any
        ExternalIntegrator: any
        CreditLimit: number
        CalculatedAmount?: number
        CalculatedOverdueAmount?: number
        CalculatedCount?: number
        CalculatedDate?: string
        LastLogID: any
        LastModified: string
        LastModifiedManual?: string
        PersonId_ref: any
        ApiRequestLogID: any
        Person_Segment: any[]
        PersonAdditional: PersonAdditional
        GroupedPerson: any[]
        ActionSkippeds: any[]
    }

    export interface PersonAdditional {
        PersonId: number
        TextField1: string
        ListField1: number
        TextField2: string
        ListField2: any
        TextField3: string
        ListField3: number
        TextField4: string
        ListField4?: number
        TextField5: string
        ListField5?: number
        TextField6: string
        ListField6: any
        TextField7: string
        ListField7: any
        TextField8: string
        ListField8: any
        TextField9: string
        ListField9: any
        TextField10: string
        ListField10: any
        TextField11: string
        ListField11: any
        TextField12: string
        ListField12: any
        TextField13: string
        ListField13: any
        TextField14: string
        ListField14: any
        TextField15: any
        ListField15: any
        TextField16: string
        ListField16: any
        TextField17: any
        ListField17: any
        TextField18: any
        ListField18: any
        TextField19: any
        ListField19: any
        TextField20: any
        ListField20: any
        TextField21: any
        ListField21: any
        TextField22: any
        ListField22: any
        TextField23: any
        ListField23: any
        TextField24: any
        ListField24: any
        TextField25: any
        ListField25: any
        TextField26: any
        ListField26: any
        TextField27: any
        ListField27: any
        TextField28: any
        ListField28: any
        TextField29: any
        ListField29: any
        TextField30: any
        ListField30: any
        TextField31: any
        ListField31: any
        TextField32: any
        ListField32: any
        TextField33: any
        ListField33: any
        TextField34: any
        ListField34: any
        TextField35: any
        ListField35: any
        TextField36: any
        ListField36: any
        TextField37: any
        ListField37: any
        TextField38: any
        ListField38: any
        TextField39: any
        ListField39: any
        TextField40: any
        ListField40: any
        TextField41: any
        ListField41: any
        TextField42: any
        ListField42: any
        TextField43: any
        ListField43: any
        TextField44: any
        ListField44: any
        TextField45: any
        ListField45: any
        TextField46: any
        ListField46: any
        TextField47: any
        ListField47: any
        TextField48: any
        ListField48: any
        TextField49: any
        ListField49: any
        TextField50: any
        ListField50: any
        updated: any
        TextField51: any
        ListField51: any
        TextField52: any
        ListField52: any
        TextField53: any
        ListField53: any
        TextField54: any
        ListField54: any
        TextField55: any
        ListField55: any
        TextField56: any
        ListField56: any
        TextField57: any
        ListField57: any
        TextField58: any
        ListField58: any
        TextField59: any
        ListField59: any
        TextField60: any
        ListField60: any
        TextField61: any
        ListField61: any
        TextField62: any
        ListField62: any
        TextField63: any
        ListField63: any
        TextField64: any
        ListField64: any
        TextField65: any
        ListField65: any
        TextField66: any
        ListField66: any
        TextField67: any
        ListField67: any
        TextField68: any
        ListField68: any
        TextField69: any
        ListField69: any
        TextField70: any
        ListField70: any
        TextField71: any
        ListField71: any
        TextField72: any
        ListField72: any
        TextField73: any
        ListField73: any
        TextField74: any
        ListField74: any
        TextField75: any
        ListField75: any
        TextField76: any
        ListField76: any
        TextField77: any
        ListField77: any
        TextField78: any
        ListField78: any
        TextField79: any
        ListField79: any
        TextField80: any
        ListField80: any
        TextField81: any
        ListField81: any
        TextField82: any
        ListField82: any
        TextField83: any
        ListField83: any
        TextField84: any
        ListField84: any
        TextField85: any
        ListField85: any
        TextField86: any
        ListField86: any
        TextField87: any
        ListField87: any
        TextField88: any
        ListField88: any
        TextField89: any
        ListField89: any
        TextField90: any
        ListField90: any
        TextField91: any
        ListField91: any
        TextField92: any
        ListField92: any
        TextField93: any
        ListField93: any
        TextField94: any
        ListField94: any
        TextField95: any
        ListField95: any
        TextField96: any
        ListField96: any
        TextField97: any
        ListField97: any
        TextField98: any
        ListField98: any
        TextField99: any
        ListField99: any
        TextField100: any
        ListField100: any
        PersonId_ref: any
    }

    export interface Person2 {
        CRMSaleAssigned: any
        customerSite: string
        followUpMail: string
        clearPhone: string
        PersonId: number
        Name: string
        Email: string
        Contact: string
        Phone: string
        Address: string
        Created: string
        CreatorUserID: number
        Comments: string
        Token: string
        ImportID: any
        ExternalID: any
        ExternalIntegrator: any
        CreditLimit: number
        CalculatedAmount?: number
        CalculatedOverdueAmount?: number
        CalculatedCount?: number
        CalculatedDate?: string
        LastLogID: any
        LastModified: string
        LastModifiedManual?: string
        PersonId_ref: any
        ApiRequestLogID: any
        Person_Segment: any[]
        PersonAdditional: PersonAdditional2
        GroupedPerson: any[]
        ActionSkippeds: any[]
    }

    export interface PersonAdditional2 {
        PersonId: number
        TextField1: string
        ListField1: number
        TextField2: string
        ListField2: any
        TextField3: string
        ListField3: number
        TextField4: string
        ListField4?: number
        TextField5: string
        ListField5?: number
        TextField6: string
        ListField6: any
        TextField7: string
        ListField7: any
        TextField8: string
        ListField8: any
        TextField9: string
        ListField9: any
        TextField10: string
        ListField10: any
        TextField11: string
        ListField11: any
        TextField12: string
        ListField12: any
        TextField13: string
        ListField13: any
        TextField14: string
        ListField14: any
        TextField15: any
        ListField15: any
        TextField16: string
        ListField16: any
        TextField17: any
        ListField17: any
        TextField18: any
        ListField18: any
        TextField19: any
        ListField19: any
        TextField20: any
        ListField20: any
        TextField21: any
        ListField21: any
        TextField22: any
        ListField22: any
        TextField23: any
        ListField23: any
        TextField24: any
        ListField24: any
        TextField25: any
        ListField25: any
        TextField26: any
        ListField26: any
        TextField27: any
        ListField27: any
        TextField28: any
        ListField28: any
        TextField29: any
        ListField29: any
        TextField30: any
        ListField30: any
        TextField31: any
        ListField31: any
        TextField32: any
        ListField32: any
        TextField33: any
        ListField33: any
        TextField34: any
        ListField34: any
        TextField35: any
        ListField35: any
        TextField36: any
        ListField36: any
        TextField37: any
        ListField37: any
        TextField38: any
        ListField38: any
        TextField39: any
        ListField39: any
        TextField40: any
        ListField40: any
        TextField41: any
        ListField41: any
        TextField42: any
        ListField42: any
        TextField43: any
        ListField43: any
        TextField44: any
        ListField44: any
        TextField45: any
        ListField45: any
        TextField46: any
        ListField46: any
        TextField47: any
        ListField47: any
        TextField48: any
        ListField48: any
        TextField49: any
        ListField49: any
        TextField50: any
        ListField50: any
        updated: any
        TextField51: any
        ListField51: any
        TextField52: any
        ListField52: any
        TextField53: any
        ListField53: any
        TextField54: any
        ListField54: any
        TextField55: any
        ListField55: any
        TextField56: any
        ListField56: any
        TextField57: any
        ListField57: any
        TextField58: any
        ListField58: any
        TextField59: any
        ListField59: any
        TextField60: any
        ListField60: any
        TextField61: any
        ListField61: any
        TextField62: any
        ListField62: any
        TextField63: any
        ListField63: any
        TextField64: any
        ListField64: any
        TextField65: any
        ListField65: any
        TextField66: any
        ListField66: any
        TextField67: any
        ListField67: any
        TextField68: any
        ListField68: any
        TextField69: any
        ListField69: any
        TextField70: any
        ListField70: any
        TextField71: any
        ListField71: any
        TextField72: any
        ListField72: any
        TextField73: any
        ListField73: any
        TextField74: any
        ListField74: any
        TextField75: any
        ListField75: any
        TextField76: any
        ListField76: any
        TextField77: any
        ListField77: any
        TextField78: any
        ListField78: any
        TextField79: any
        ListField79: any
        TextField80: any
        ListField80: any
        TextField81: any
        ListField81: any
        TextField82: any
        ListField82: any
        TextField83: any
        ListField83: any
        TextField84: any
        ListField84: any
        TextField85: any
        ListField85: any
        TextField86: any
        ListField86: any
        TextField87: any
        ListField87: any
        TextField88: any
        ListField88: any
        TextField89: any
        ListField89: any
        TextField90: any
        ListField90: any
        TextField91: any
        ListField91: any
        TextField92: any
        ListField92: any
        TextField93: any
        ListField93: any
        TextField94: any
        ListField94: any
        TextField95: any
        ListField95: any
        TextField96: any
        ListField96: any
        TextField97: any
        ListField97: any
        TextField98: any
        ListField98: any
        TextField99: any
        ListField99: any
        TextField100: any
        ListField100: any
        PersonId_ref: any
    }

    export interface NewPayment {
        "additionalList": [],
        "io": ImportPreviewResponse.Io,
        "personName": string,
        "referenceNumber": string,
        "Notes": string,
        "Date": Date,
        "Amount": number,
        "ExternalIntegrator": any,
        "ExternalID": any,
        "Invoice_ID": any,
        "ioid": any,
        "values": any[],
        "Index": number,
        "PersonID": number,
        "transactionTypeId": any,
        "CreatorID": any,
        "OriginalAmount": any,
        "OriginalCurrencyID": any

    }
    export interface Item {
        person: Person3
        personName: string
        Date: string
        user: User
        content: string
        tagnames: any
        tags: any[]
        ActivityTypeID: any
        activityType: any
        values: string[]
        Index: number
        groupId: any
        group: any
    }

    export interface Person3 {
        CRMSaleAssigned: any
        customerSite: string
        followUpMail: string
        clearPhone: string
        PersonId: number
        Name: string
        Email?: string
        Contact: string
        Phone: string
        Address: string
        Created: string
        CreatorUserID: number
        Comments: string
        Token: string
        ImportID: any
        ExternalID: any
        ExternalIntegrator: any
        CreditLimit: number
        CalculatedAmount: number
        CalculatedOverdueAmount: number
        CalculatedCount: number
        CalculatedDate: string
        LastLogID?: number
        LastModified: string
        LastModifiedManual: any
        PersonId_ref: any
        ApiRequestLogID: any
        Person_Segment: any[]
        PersonAdditional: PersonAdditional3
        GroupedPerson: any[]
        ActionSkippeds: any[]
    }

    export interface PersonAdditional3 {
        PersonId: number
        TextField1: string
        ListField1?: number
        TextField2: string
        ListField2?: number
        TextField3: string
        ListField3: number
        TextField4: string
        ListField4?: number
        TextField5: string
        ListField5?: number
        TextField6: string
        ListField6: any
        TextField7: string
        ListField7: any
        TextField8: string
        ListField8: any
        TextField9: string
        ListField9: any
        TextField10: string
        ListField10: any
        TextField11: string
        ListField11: any
        TextField12: any
        ListField12: any
        TextField13: string
        ListField13: any
        TextField14: string
        ListField14: any
        TextField15: string
        ListField15: any
        TextField16: string
        ListField16: any
        TextField17: string
        ListField17: any
        TextField18: any
        ListField18: any
        TextField19: any
        ListField19: any
        TextField20: any
        ListField20: any
        TextField21: any
        ListField21: any
        TextField22: any
        ListField22: any
        TextField23: any
        ListField23: any
        TextField24: any
        ListField24: any
        TextField25: any
        ListField25: any
        TextField26: any
        ListField26: any
        TextField27: any
        ListField27: any
        TextField28: any
        ListField28: any
        TextField29: any
        ListField29: any
        TextField30: any
        ListField30: any
        TextField31: any
        ListField31: any
        TextField32: any
        ListField32: any
        TextField33: any
        ListField33: any
        TextField34: any
        ListField34: any
        TextField35: any
        ListField35: any
        TextField36: any
        ListField36: any
        TextField37: any
        ListField37: any
        TextField38: any
        ListField38: any
        TextField39: any
        ListField39: any
        TextField40: any
        ListField40: any
        TextField41: any
        ListField41: any
        TextField42: any
        ListField42: any
        TextField43: any
        ListField43: any
        TextField44: any
        ListField44: any
        TextField45: any
        ListField45: any
        TextField46: any
        ListField46: any
        TextField47: any
        ListField47: any
        TextField48: any
        ListField48: any
        TextField49: any
        ListField49: any
        TextField50: any
        ListField50: any
        updated: any
        TextField51: any
        ListField51: any
        TextField52: any
        ListField52: any
        TextField53: any
        ListField53: any
        TextField54: any
        ListField54: any
        TextField55: any
        ListField55: any
        TextField56: any
        ListField56: any
        TextField57: any
        ListField57: any
        TextField58: any
        ListField58: any
        TextField59: any
        ListField59: any
        TextField60: any
        ListField60: any
        TextField61: any
        ListField61: any
        TextField62: any
        ListField62: any
        TextField63: any
        ListField63: any
        TextField64: any
        ListField64: any
        TextField65: any
        ListField65: any
        TextField66: any
        ListField66: any
        TextField67: any
        ListField67: any
        TextField68: any
        ListField68: any
        TextField69: any
        ListField69: any
        TextField70: any
        ListField70: any
        TextField71: any
        ListField71: any
        TextField72: any
        ListField72: any
        TextField73: any
        ListField73: any
        TextField74: any
        ListField74: any
        TextField75: any
        ListField75: any
        TextField76: any
        ListField76: any
        TextField77: any
        ListField77: any
        TextField78: any
        ListField78: any
        TextField79: any
        ListField79: any
        TextField80: any
        ListField80: any
        TextField81: any
        ListField81: any
        TextField82: any
        ListField82: any
        TextField83: any
        ListField83: any
        TextField84: any
        ListField84: any
        TextField85: any
        ListField85: any
        TextField86: any
        ListField86: any
        TextField87: any
        ListField87: any
        TextField88: any
        ListField88: any
        TextField89: any
        ListField89: any
        TextField90: any
        ListField90: any
        TextField91: any
        ListField91: any
        TextField92: any
        ListField92: any
        TextField93: any
        ListField93: any
        TextField94: any
        ListField94: any
        TextField95: any
        ListField95: any
        TextField96: any
        ListField96: any
        TextField97: any
        ListField97: any
        TextField98: any
        ListField98: any
        TextField99: any
        ListField99: any
        TextField100: any
        ListField100: any
        PersonId_ref: any
    }

    export interface User {
        User_Company: any[]
        Companies: any[]
        Subscriptions: any[]
        CustomSubscription: any
        PlanData: any
        UserConfig: any
        Mailing: any
        User_Mailing: any[]
        Logs1: any[]
        Profiles: any[]
        User_Segment: any[]
        EmailAlias: any[]
        PasswordHistories: any[]
        UserID: number
        Firstname: string
        Lastname: string
        Email: string
        EmailChecked: boolean
        ValidationToken: string
        Expires: any
        Created: string
        LastLogin: string
        SourceCode: any
        Country: string
        LastSessionId: string
        Logins: number
        Minutes: number
        NoMail: boolean
        ReferrerUserId: any
        Group: any
        UserStatus: number
        Plan: any
        Cohort: any
        Culture: string
        LastMailingID: number
        LastMailingDate: string
        Identify: any
        WeeklyMail: boolean
        DailyMail: boolean
        TimeZone: number
        TrialExpire: string
        LastDailyMail: string
        Phone: any
        CompanyName: string
        Website: any
        Position: any
        Active: boolean
        IP: any
        InHouse: boolean
        Trial: any
        UserStatusDate: any
        StripeCustomerId: any
        Signature: string
        PlanDataID: number
        TaxNumber: string
        Address: string
        BackupRequest: any
        ViewConsolidate: boolean
        ConsolidatedScale: any
        WeekendMail: boolean
        PlanExpireDate: any
        UniqueSession: any
        Remarketing: any
        PasswordExpire: any
        VeryStrongPassword: any
        PasswordDuration: any
        UserDelegated: any
        ExternalID: any
        URLSource: any
        DefaultMail: any
        AllowEmailFromEdit: any
        AccountBlock: any
        Salt: string
        Hash: string
        Shard: any
        VersionAllowed: number
        CurrentVersion: any
        Fullname: string
        FullnameInv: string
        CanLogon: boolean
        Status: number
    }

}

export const enum ValidDates {
    OK,
    MultipleValid,
    Invalid
}