import { useCallback, useEffect, useState } from "react";
import { GroupListResponse } from "../../group/entities/GroupListResponse";
import TableContext, { TableContextValues } from "../../task/TableContext";
import { GroupListRequest } from "../../group/entities/GroupListRequest";
import GroupService from "../../../services/GroupService";
import { CacheEntity, StorageService } from "../../../services/StorageService";
import { SortDirection } from "../../shared/entities/Sort";
import { applyFiltersHelper, calculatePageCount, setCurrentPageHelper, setPageSizeHelper } from "../../../utils/TableUtils";

export class InvoiceGroupTableContextValues extends TableContextValues<GroupListResponse> {
    constructor(public request: GroupListRequest,
        public setRequest: (request: GroupListRequest) => void) {
        super();
    }
}

const InvoiceGroupTableProvider = (props: React.PropsWithChildren<{ request?: GroupListRequest, personid: number }>) => {
    const [request, setRequest] = useState<GroupListRequest>(props.request ?? new GroupListRequest(props.personid));
    const [response, setResponse] = useState<GroupListResponse>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const requestData = async () => {
        setLoading(true);
        const result = await GroupService.list({ ...request, pageSize: request.pagesize });
        if (result instanceof Error) {
            setLoading(false);
            return;
        }
        result.list.map(x => StorageService.setCache(CacheEntity.GroupName, x.GroupID, x.Name));
        setResponse(result);
        setLoading(false);
        setError(false);
    }

    const requestDataCallback = useCallback(requestData, [request]);
    useEffect(() => {
        requestDataCallback();
    }, [requestDataCallback]);

    const sortDirection = request.descending as SortDirection;
    const sortColumn = request.sortcolumn;
    const setSortColumn = (key: string) => {
        const newSort = {
            sortcolumn: key,
            sortDirection: sortColumn === key ? Number(!sortDirection) : SortDirection.Descending
        };
        setRequest(request => ({ ...request, sortcolumn: newSort.sortcolumn, descending: newSort.sortDirection }));
    }
    const sort = { sortDirection, sortColumn };
    const pageCount = calculatePageCount(response);
    const value = new InvoiceGroupTableContextValues(request, setRequest);
    value.loading = loading;
    value.error = error;
    value.response = response;
    value.currentPage = request.page;
    value.setCurrentPage = setCurrentPageHelper(setRequest, pageCount);
    value.pageCount = pageCount;
    value.reload = requestData;
    value.applyFilters = applyFiltersHelper(setRequest);
    value.sort = sort;
    value.pageSize = request.pagesize;
    value.setSortColumn = setSortColumn;
    value.setPageSize = setPageSizeHelper(setRequest);

    return (
        <TableContext.Provider value={value}>
            {props.children}
        </TableContext.Provider>
    )
}

export default InvoiceGroupTableProvider;

