import { useContext, useEffect, useState } from "react";
import CompanyService, { DataType, Entities } from "../../../services/CompanyService";
import InvoiceService, { getInvoiceFieldDisplayValue } from "../../../services/InvoiceService";
import ToastContext from "../../shared/bootstrap/Toast";
import Dropdown from "../../shared/components/Dropdown";
import { EntityField, sortEntityFields } from "../../shared/entities/EntityField";
import { TranslationService } from "../../../services/TranslationService";
import { InvoiceEditStatus } from "./entities/InvoiceEditStatus";
import { InvoiceGetResponse } from "./entities/InvoiceGetResponse";
import FieldEdit from "./InvoiceMassiveChangesFieldEdit";
import { Cast } from "../../../utils/Utils";
import { RequiredManager } from "../../shared/RequieredManager";
import { formatDateDigits, formatIntizaDate } from "../../../utils/FormatUtils";
import { parseDate } from "../../../utils/ParseUtils";

export const InvoiceDetailStatus = ({ invoice, isEditing, afterSubmit, editingChanged, canEdit = true }: { invoice: InvoiceGetResponse.Item, isEditing: boolean, afterSubmit: () => void, editingChanged: (editing: boolean) => void, canEdit?: boolean }) => {
    const statusList = [...CompanyService.getIoStatus()];
    const currentStatus = statusList.find(x => x.IOStatusID === (invoice.IOStatusID ?? 0))!;
    const getFields = (statusId: number | undefined): EntityField[] => {
        if (!statusId) {
            return [];
        }
        return CompanyService.getAdditionalDefinitionsFiltered(Entities.Invoice)
            .filter(x => x.IOStatusDependencies.filter(y => y.IOStatusID === statusId).length > 0)
            .map(x => new EntityField(x)).sort(sortEntityFields(CompanyService.getIoSortedFields().split(",").map(x => x.toString())));
    };
    const [fields, setFields] = useState<EntityField[]>(getFields(currentStatus.IOStatusID ?? 0));

    const { showToast } = useContext(ToastContext);
    const { translate } = TranslationService;
    const [isSaving, setIsSaving] = useState(false);
    const [statusEdit, setStatusEdit] = useState(new InvoiceEditStatus(CompanyService.getUserid(), currentStatus.IOStatusID, invoice));
    const requiredManager = new RequiredManager();

    useEffect(() => {
        const defValues = fields
            .filter(x => x.type === DataType.Date && statusEdit.additionals.find(y => y.Id === x.id)?.Value !== parseDate(statusEdit.additionals.find(y => y.Id === x.id)?.Value)?.toJSON())
            .map(x => ({ Id: x.id, Value: (parseDate(statusEdit.additionals.find(y => y.Id === x.id)?.Value) ?? new Date()).toJSON() }));
        if (defValues.length > 0) {
            setStatusEdit(x => ({ ...x, additionals: [...x.additionals.filter(y => !defValues.map(z => z.Id).includes(y.Id)), ...defValues] }));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoice, statusEdit.statusId]);
    const statusFieldChange = (field: EntityField) => {
        const onChange = (value: string | undefined) => {
            if (value && field.type === DataType.Date) {
                value = formatIntizaDate(new Date(value));
            }
            const newAdditionals = [...statusEdit.additionals.filter(x => x.Id !== field.id), { Id: field.id, Value: value ?? (field.type === DataType.Date ? formatDateDigits(new Date()) : "") }];
            // const newAdditionals = [...statusEdit.additionals.filter(x => x.Id !== field.id), { Id: field.id, Value: value ?? "" }];
            setStatusEdit({ ...statusEdit, additionals: newAdditionals });
        };
        const required = CompanyService.getAdditionalDefinitionsFiltered(Entities.Invoice)
            .find(x => x.AdditionalDefinitionID === field.id)
            ?.IOStatusDependencies.find(y => y.IOStatusID === statusEdit.statusId)
            ?.Mandatory;
        if (required) {
            return Cast<typeof onChange>(requiredManager.makeRequired(onChange));
        }
        return onChange;
    };

    const statusChange = (statusId?: number) => {
        setStatusEdit(new InvoiceEditStatus(CompanyService.getUserid(), statusId, invoice));
        setFields(getFields(statusId ? statusId : undefined));
    };

    const CanSubmit = () => {
        if (statusEdit.statusId === undefined) {
            showToast(translate.SelectStatus, undefined, "warning");
            return false;
        }
        return requiredManager.validate();
    };

    const onSubmit = async () => {
        if (isSaving || !CanSubmit()) { return; }
        setIsSaving(true);

        const statusEditSave = { ...statusEdit };
        statusEditSave.additionals = statusEditSave.additionals.filter(x => fields.map(y => y.id).includes(x.Id));

        const result = await InvoiceService.statusChange({
            invoiceIds: [invoice.IOID],
            all: false,
            filters: undefined,
            personId: invoice.personId.toString(),
            statusEdit: statusEditSave
        });
        if (result instanceof Error) {
            showToast(translate.ErrorProcessingRequest, undefined, "danger");
        }
        else {
            showToast(translate.SavedChanges, undefined, "success");
        }
        setIsSaving(false);
        editingChanged(false);
        afterSubmit && afterSubmit();
    };

    const content = isEditing ?
        (<>
            <div className="d-flex input-row mb-3">
                <label className="form-label">{translate.IOStatus}</label>
                <div className="show-child-on-hover ml-auto">
                    <div className="row">
                        <div className="col-auto">
                            <i className="far fa-times text-danger" style={{ marginTop: 10 }} onClick={() => editingChanged(false)}></i>
                            {isSaving && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                        </div>
                        <div className="col-auto">
                            <Dropdown optionLabel={translate.Select} onChange={statusChange} items={statusList.map(x => ({ value: x.IOStatusID, text: x.Value }))} defaultValue={statusEdit.statusId} />
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-success" onClick={onSubmit}>{translate.Save}</button>
                        </div>
                    </div>
                </div>
            </div>
            {
                fields.map((field, index) =>
                    <div className="d-flex input-row mb-3" key={field.id}>
                        <label className="form-label">{field.title}</label>
                        <div className="ml-auto">
                            <FieldEdit key={field.id} field={field} onChange={statusFieldChange(field)} status={statusEdit} onlyEditor={true} invoice={invoice} personId={invoice.personId.toString()} />
                        </div>
                    </div>
                )
            }

        </>)
        :
        (<>
            <div className="d-flex input-row mb-3">
                <label className="form-label">{translate.IOStatus}</label>
                <div className="show-child-on-hover ml-auto">
                    <div className="row">
                        {canEdit && CompanyService.canDo("editiostatus") && !CompanyService.getSetting("hideeditio") && CompanyService.getConfigItems().find(x => x.field === -9)?.ManualEdit &&
                            <div className="col-auto">
                                <i className="pointer hideOnPrint far fa-pen show-when-hovering-parent" onClick={() => editingChanged(true)} ></i>
                            </div>
                        }
                        <div className="col">
                            <p className="form-label-detail">{currentStatus?.Value}</p>
                        </div>
                    </div>
                </div>
            </div>
            {fields.map((field, index) =>
                <div className="d-flex input-row mb-3" key={field.id}>
                    <label className="form-label">{field.title}</label>
                    <p className="form-label-detail">{getInvoiceFieldDisplayValue(field, invoice)}</p>
                </div>
            )
            }


        </>);

    return (<>
        {content}
    </>
    );

};

export default InvoiceDetailStatus;    