import { useEffect, useState } from 'react'
import { TranslationService } from '../../../services/TranslationService'
import AccountService from '../../../services/AccountService'
import Dropdown from '../../shared/components/Dropdown'
import CompanyService from '../../../services/CompanyService'
import Loading from '../../shared/components/Loading'

const timeZoneItems = [
    { value: -720, DisplayName: "(UTC-12:00) Línea internacional de cambio de fecha", Id: "(UTC-12:00) Línea internacional de cambio de fecha" },
    { value: -660, DisplayName: "(UTC-11:00) Hora universal coordinada-11", Id: "(UTC-11:00) Hora universal coordinada-11" },
    { value: -600, DisplayName: "(UTC-10:00) Hawai", Id: "(UTC-10:00) Hawai" },
    { value: -600, DisplayName: "(UTC-10:00) Islas Aleutianas", Id: "(UTC-10:00) Islas Aleutianas" },
    { value: -558, DisplayName: "(UTC-09:30) Islas Marquesas", Id: "(UTC-09:30) Islas Marquesas" },
    { value: -540, DisplayName: "(UTC-09:00) Alaska", Id: "(UTC-09:00) Alaska" },
    { value: -540, DisplayName: "(UTC-09:00) Hora universal coordinada-09", Id: "(UTC-09:00) Hora universal coordinada-09" },
    { value: -480, DisplayName: "(UTC-08:00) Baja California", Id: "(UTC-08:00) Baja California" },
    { value: -480, DisplayName: "(UTC-08:00) Hora del Pacífico (EE.UU. y Canadá)", Id: "(UTC-08:00) Hora del Pacífico (EE.UU. y Canadá)" },
    { value: -480, DisplayName: "(UTC-08:00) Hora universal coordinada-08", Id: "(UTC-08:00) Hora universal coordinada-08" },
    { value: -420, DisplayName: "(UTC-07:00) Arizona", Id: "(UTC-07:00) Arizona" },
    { value: -420, DisplayName: "(UTC-07:00) Hora de las Montañas Rocosas (EE.UU. y Canadá)", Id: "(UTC-07:00) Hora de las Montañas Rocosas (EE.UU. y Canadá)" },
    { value: -420, DisplayName: "(UTC-07:00) La Paz, Mazatlán", Id: "(UTC-07:00) La Paz, Mazatlán" },
    { value: -420, DisplayName: "(UTC-07:00) Yukon", Id: "(UTC-07:00) Yukon" },
    { value: -360, DisplayName: "(UTC-06:00) América Central", Id: "(UTC-06:00) América Central" },
    { value: -360, DisplayName: "(UTC-06:00) Guadalajara, Ciudad de México, Monterrey", Id: "(UTC-06:00) Guadalajara, Ciudad de México, Monterrey" },
    { value: -360, DisplayName: "(UTC-06:00) Hora central (EE.UU. y Canadá)", Id: "(UTC-06:00) Hora central (EE.UU. y Canadá)" },
    { value: -360, DisplayName: "(UTC-06:00) Isla de Pascua", Id: "(UTC-06:00) Isla de Pascua" },
    { value: -360, DisplayName: "(UTC-06:00) Saskatchewan", Id: "(UTC-06:00) Saskatchewan" },
    { value: -300, DisplayName: "(UTC-05:00) Bogotá, Lima, Quito, Rio Branco", Id: "(UTC-05:00) Bogotá, Lima, Quito, Rio Branco" },
    { value: -300, DisplayName: "(UTC-05:00) Chetumal", Id: "(UTC-05:00) Chetumal" },
    { value: -300, DisplayName: "(UTC-05:00) Haití", Id: "(UTC-05:00) Haití" },
    { value: -300, DisplayName: "(UTC-05:00) Hora del este (EE.UU. y Canadá)", Id: "(UTC-05:00) Hora del este (EE.UU. y Canadá)" },
    { value: -300, DisplayName: "(UTC-05:00) Indiana (este)", Id: "(UTC-05:00) Indiana (este)" },
    { value: -300, DisplayName: "(UTC-05:00) Islas Turcas y Caicos", Id: "(UTC-05:00) Islas Turcas y Caicos" },
    { value: -300, DisplayName: "(UTC-05:00) La Habana", Id: "(UTC-05:00) La Habana" },
    { value: -240, DisplayName: "(UTC-04:00) Asunción", Id: "(UTC-04:00) Asunción" },
    { value: -240, DisplayName: "(UTC-04:00) Caracas", Id: "(UTC-04:00) Caracas" },
    { value: -240, DisplayName: "(UTC-04:00) Cuiabá", Id: "(UTC-04:00) Cuiabá" },
    { value: -240, DisplayName: "(UTC-04:00) Georgetown, La Paz, Manaos, San Juan", Id: "(UTC-04:00) Georgetown, La Paz, Manaos, San Juan" },
    { value: -240, DisplayName: "(UTC-04:00) Hora del Atlántico (Canadá)", Id: "(UTC-04:00) Hora del Atlántico (Canadá)" },
    { value: -240, DisplayName: "(UTC-04:00) Santiago", Id: "(UTC-04:00) Santiago" },
    { value: -198, DisplayName: "(UTC-03:30) Terranova", Id: "(UTC-03:30) Terranova" },
    { value: -180, DisplayName: "(UTC-03:00) Araguaína", Id: "(UTC-03:00) Araguaína" },
    { value: -180, DisplayName: "(UTC-03:00) Brasilia", Id: "(UTC-03:00) Brasilia" },
    { value: -180, DisplayName: "(UTC-03:00) Cayena, Fortaleza", Id: "(UTC-03:00) Cayena, Fortaleza" },
    { value: -180, DisplayName: "(UTC-03:00) Ciudad de Buenos Aires", Id: "(UTC-03:00) Ciudad de Buenos Aires" },
    { value: -180, DisplayName: "(UTC-03:00) Montevideo", Id: "(UTC-03:00) Montevideo" },
    { value: -180, DisplayName: "(UTC-03:00) Punta Arenas", Id: "(UTC-03:00) Punta Arenas" },
    { value: -180, DisplayName: "(UTC-03:00) Salvador", Id: "(UTC-03:00) Salvador" },
    { value: -180, DisplayName: "(UTC-03:00) San Pedro y Miquelón", Id: "(UTC-03:00) San Pedro y Miquelón" },
    { value: -120, DisplayName: "(UTC-02:00) Atlántico central - Antiguo", Id: "(UTC-02:00) Atlántico central - Antiguo" },
    { value: -120, DisplayName: "(UTC-02:00) Groenlandia", Id: "(UTC-02:00) Groenlandia" },
    { value: -120, DisplayName: "(UTC-02:00) Hora universal coordinada-02", Id: "(UTC-02:00) Hora universal coordinada-02" },
    { value: -60, DisplayName: "(UTC-01:00) Azores", Id: "(UTC-01:00) Azores" },
    { value: -60, DisplayName: "(UTC-01:00) Cabo Verde", Id: "(UTC-01:00) Cabo Verde" },
    { value: 0, DisplayName: "(UTC) Hora universal coordinada", Id: "(UTC) Hora universal coordinada" },
    { value: 0, DisplayName: "(UTC+00:00) Dublín, Edimburgo, Lisboa, Londres", Id: "(UTC+00:00) Dublín, Edimburgo, Lisboa, Londres" },
    { value: 0, DisplayName: "(UTC+00:00) Monrovia, Reikiavik", Id: "(UTC+00:00) Monrovia, Reikiavik" },
    { value: 0, DisplayName: "(UTC+00:00) Santo Tomé", Id: "(UTC+00:00) Santo Tomé" },
    { value: 60, DisplayName: "(UTC+01:00) Casablanca", Id: "(UTC+01:00) Casablanca" },
    { value: 60, DisplayName: "(UTC+01:00) Amsterdam, Berlín, Berna, Roma, Estocolmo, Viena", Id: "(UTC+01:00) Amsterdam, Berlín, Berna, Roma, Estocolmo, Viena" },
    { value: 60, DisplayName: "(UTC+01:00) Belgrado, Bratislava, Budapest, Liubliana, Praga", Id: "(UTC+01:00) Belgrado, Bratislava, Budapest, Liubliana, Praga" },
    { value: 60, DisplayName: "(UTC+01:00) Bruselas, Copenhague, Madrid, París", Id: "(UTC+01:00) Bruselas, Copenhague, Madrid, París" },
    { value: 60, DisplayName: "(UTC+01:00) Sarajevo, Skopie, Varsovia, Zagreb", Id: "(UTC+01:00) Sarajevo, Skopie, Varsovia, Zagreb" },
    { value: 60, DisplayName: "(UTC+01:00) África Central Occidental", Id: "(UTC+01:00) África Central Occidental" },
    { value: 120, DisplayName: "(UTC+02:00) Atenas, Bucarest", Id: "(UTC+02:00) Atenas, Bucarest" },
    { value: 120, DisplayName: "(UTC+02:00) Beirut", Id: "(UTC+02:00) Beirut" },
    { value: 120, DisplayName: "(UTC+02:00) Chisinau", Id: "(UTC+02:00) Chisinau" },
    { value: 120, DisplayName: "(UTC+02:00) El Cairo", Id: "(UTC+02:00) El Cairo" },
    { value: 120, DisplayName: "(UTC+02:00) Gaza, Hebrón", Id: "(UTC+02:00) Gaza, Hebrón" },
    { value: 120, DisplayName: "(UTC+02:00) Harare, Pretoria", Id: "(UTC+02:00) Harare, Pretoria" },
    { value: 120, DisplayName: "(UTC+02:00) Helsinki, Kiev, Riga, Sofía, Tallin, Vilna", Id: "(UTC+02:00) Helsinki, Kiev, Riga, Sofía, Tallin, Vilna" },
    { value: 120, DisplayName: "(UTC+02:00) Jartum", Id: "(UTC+02:00) Jartum" },
    { value: 120, DisplayName: "(UTC+02:00) Jerusalén", Id: "(UTC+02:00) Jerusalén" },
    { value: 120, DisplayName: "(UTC+02:00) Kaliningrado", Id: "(UTC+02:00) Kaliningrado" },
    { value: 120, DisplayName: "(UTC+02:00) Trípoli", Id: "(UTC+02:00) Trípoli" },
    { value: 120, DisplayName: "(UTC+02:00) Windhoek", Id: "(UTC+02:00) Windhoek" },
    { value: 120, DisplayName: "(UTC+02:00) Yuba", Id: "(UTC+02:00) Yuba" },
    { value: 180, DisplayName: "(UTC+03:00) Ammán", Id: "(UTC+03:00) Ammán" },
    { value: 180, DisplayName: "(UTC+03:00) Bagdad", Id: "(UTC+03:00) Bagdad" },
    { value: 180, DisplayName: "(UTC+03:00) Damasco", Id: "(UTC+03:00) Damasco" },
    { value: 180, DisplayName: "(UTC+03:00) Estambul", Id: "(UTC+03:00) Estambul" },
    { value: 180, DisplayName: "(UTC+03:00) Kuwait, Riad", Id: "(UTC+03:00) Kuwait, Riad" },
    { value: 180, DisplayName: "(UTC+03:00) Minsk", Id: "(UTC+03:00) Minsk" },
    { value: 180, DisplayName: "(UTC+03:00) Moscú, San Petersburgo", Id: "(UTC+03:00) Moscú, San Petersburgo" },
    { value: 180, DisplayName: "(UTC+03:00) Nairobi", Id: "(UTC+03:00) Nairobi" },
    { value: 180, DisplayName: "(UTC+03:00) Volgogrado", Id: "(UTC+03:00) Volgogrado" },
    { value: 198, DisplayName: "(UTC+03:30) Teherán", Id: "(UTC+03:30) Teherán" },
    { value: 240, DisplayName: "(UTC+04:00) Abu Dabi, Mascate", Id: "(UTC+04:00) Abu Dabi, Mascate" },
    { value: 240, DisplayName: "(UTC+04:00) Astrakhan, Ulyanovsk", Id: "(UTC+04:00) Astrakhan, Ulyanovsk" },
    { value: 240, DisplayName: "(UTC+04:00) Bakú", Id: "(UTC+04:00) Bakú" },
    { value: 240, DisplayName: "(UTC+04:00) Ereván", Id: "(UTC+04:00) Ereván" },
    { value: 240, DisplayName: "(UTC+04:00) Izhevsk, Samara", Id: "(UTC+04:00) Izhevsk, Samara" },
    { value: 240, DisplayName: "(UTC+04:00) Port Louis", Id: "(UTC+04:00) Port Louis" },
    { value: 240, DisplayName: "(UTC+04:00) Sarátov", Id: "(UTC+04:00) Sarátov" },
    { value: 240, DisplayName: "(UTC+04:00) Tiflis", Id: "(UTC+04:00) Tiflis" },
    { value: 258, DisplayName: "(UTC+04:30) Kabul", Id: "(UTC+04:30) Kabul" },
    { value: 300, DisplayName: "(UTC+05:00) Asjabad, Tashkent", Id: "(UTC+05:00) Asjabad, Tashkent" },
    { value: 300, DisplayName: "(UTC+05:00) Ekaterimburgo", Id: "(UTC+05:00) Ekaterimburgo" },
    { value: 300, DisplayName: "(UTC+05:00) Islamabad, Karachi", Id: "(UTC+05:00) Islamabad, Karachi" },
    { value: 300, DisplayName: "(UTC+05:00) Qyzylorda", Id: "(UTC+05:00) Qyzylorda" },
    { value: 318, DisplayName: "(UTC+05:30) Chennai, Kolkata (Calcuta), Mumbai, Nueva Delhi", Id: "(UTC+05:30) Chennai, Kolkata (Calcuta), Mumbai, Nueva Delhi" },
    { value: 318, DisplayName: "(UTC+05:30) Sri Jayawardenepura", Id: "(UTC+05:30) Sri Jayawardenepura" },
    { value: 327, DisplayName: "(UTC+05:45) Katmandú", Id: "(UTC+05:45) Katmandú" },
    { value: 360, DisplayName: "(UTC+06:00) Astaná", Id: "(UTC+06:00) Astaná" },
    { value: 360, DisplayName: "(UTC+06:00) Dacca", Id: "(UTC+06:00) Dacca" },
    { value: 360, DisplayName: "(UTC+06:00) Omsk", Id: "(UTC+06:00) Omsk" },
    { value: 378, DisplayName: "(UTC+06:30) Yangón (Rangún)", Id: "(UTC+06:30) Yangón (Rangún)" },
    { value: 420, DisplayName: "(UTC+07:00) Bangkok, Hanói, Yakarta", Id: "(UTC+07:00) Bangkok, Hanói, Yakarta" },
    { value: 420, DisplayName: "(UTC+07:00) Barnaul, Gorno-Altaysk", Id: "(UTC+07:00) Barnaul, Gorno-Altaysk" },
    { value: 420, DisplayName: "(UTC+07:00) Hovd", Id: "(UTC+07:00) Hovd" },
    { value: 420, DisplayName: "(UTC+07:00) Krasnoyarsk", Id: "(UTC+07:00) Krasnoyarsk" },
    { value: 420, DisplayName: "(UTC+07:00) Novosibirsk", Id: "(UTC+07:00) Novosibirsk" },
    { value: 420, DisplayName: "(UTC+07:00) Tomsk", Id: "(UTC+07:00) Tomsk" },
    { value: 480, DisplayName: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi", Id: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi" },
    { value: 480, DisplayName: "(UTC+08:00) Irkutsk", Id: "(UTC+08:00) Irkutsk" },
    { value: 480, DisplayName: "(UTC+08:00) Kuala Lumpur, Singapur", Id: "(UTC+08:00) Kuala Lumpur, Singapur" },
    { value: 480, DisplayName: "(UTC+08:00) Perth", Id: "(UTC+08:00) Perth" },
    { value: 480, DisplayName: "(UTC+08:00) Taipéi", Id: "(UTC+08:00) Taipéi" },
    { value: 480, DisplayName: "(UTC+08:00) Ulán Bator", Id: "(UTC+08:00) Ulán Bator" },
    { value: 507, DisplayName: "(UTC+08:45) Eucla", Id: "(UTC+08:45) Eucla" },
    { value: 540, DisplayName: "(UTC+09:00) Chita", Id: "(UTC+09:00) Chita" },
    { value: 540, DisplayName: "(UTC+09:00) Osaka, Sapporo, Tokio", Id: "(UTC+09:00) Osaka, Sapporo, Tokio" },
    { value: 540, DisplayName: "(UTC+09:00) Pyongyang", Id: "(UTC+09:00) Pyongyang" },
    { value: 540, DisplayName: "(UTC+09:00) Seúl", Id: "(UTC+09:00) Seúl" },
    { value: 540, DisplayName: "(UTC+09:00) Yakutsk", Id: "(UTC+09:00) Yakutsk" },
    { value: 558, DisplayName: "(UTC+09:30) Adelaida", Id: "(UTC+09:30) Adelaida" },
    { value: 558, DisplayName: "(UTC+09:30) Darwin", Id: "(UTC+09:30) Darwin" },
    { value: 600, DisplayName: "(UTC+10:00) Brisbane", Id: "(UTC+10:00) Brisbane" },
    { value: 600, DisplayName: "(UTC+10:00) Canberra, Melbourne, Sídney", Id: "(UTC+10:00) Canberra, Melbourne, Sídney" },
    { value: 600, DisplayName: "(UTC+10:00) Guam, Port Moresby", Id: "(UTC+10:00) Guam, Port Moresby" },
    { value: 600, DisplayName: "(UTC+10:00) Hobart", Id: "(UTC+10:00) Hobart" },
    { value: 600, DisplayName: "(UTC+10:00) Vladivostok", Id: "(UTC+10:00) Vladivostok" },
    { value: 618, DisplayName: "(UTC+10:30) Isla Lord Howe", Id: "(UTC+10:30) Isla Lord Howe" },
    { value: 660, DisplayName: "(UTC+11:00) Chokurdakh", Id: "(UTC+11:00) Chokurdakh" },
    { value: 660, DisplayName: "(UTC+11:00) Isla Bougainville", Id: "(UTC+11:00) Isla Bougainville" },
    { value: 660, DisplayName: "(UTC+11:00) Isla Norfolk", Id: "(UTC+11:00) Isla Norfolk" },
    { value: 660, DisplayName: "(UTC+11:00) Islas Salomón, Nueva Caledonia", Id: "(UTC+11:00) Islas Salomón, Nueva Caledonia" },
    { value: 660, DisplayName: "(UTC+11:00) Magadán", Id: "(UTC+11:00) Magadán" },
    { value: 660, DisplayName: "(UTC+11:00) Sajalín", Id: "(UTC+11:00) Sajalín" },
    { value: 720, DisplayName: "(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky", Id: "(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky" },
    { value: 720, DisplayName: "(UTC+12:00) Auckland, Wellington", Id: "(UTC+12:00) Auckland, Wellington" },
    { value: 720, DisplayName: "(UTC+12:00) Fiyi", Id: "(UTC+12:00) Fiyi" },
    { value: 720, DisplayName: "(UTC+12:00) Hora universal coordinada+12", Id: "(UTC+12:00) Hora universal coordinada+12" },
    { value: 720, DisplayName: "(UTC+12:00) Petropavlovsk-Kamchatsky - antiguo", Id: "(UTC+12:00) Petropavlovsk-Kamchatsky - antiguo" },
    { value: 747, DisplayName: "(UTC+12:45) Islas Chatham", Id: "(UTC+12:45) Islas Chatham" },
    { value: 780, DisplayName: "(UTC+13:00) Hora universal coordinada+13", Id: "(UTC+13:00) Hora universal coordinada+13" },
    { value: 780, DisplayName: "(UTC+13:00) Nuku'alofa", Id: "(UTC+13:00) Nuku'alofa" },
    { value: 780, DisplayName: "(UTC+13:00) Samoa", Id: "(UTC+13:00) Samoa" },
    { value: 840, DisplayName: "(UTC+14:00) Isla Kiritimati", Id: "(UTC+14:00) Isla Kiritimati" },
];

export const CultureData = () => {
    const { currentLanguage, setCurrentLanguage } = TranslationService
    const timeZoneDefault = CompanyService.getCompanyToken()?.timezone ? timeZoneItems.find(e => e.value.toString() === CompanyService.getCompanyToken()!.timezone) : undefined;


    const [changingData, setChangingData] = useState(false)
    const [dataText, setDataText] = useState<{ culture?: string, timezone?: string, timeName?: string }>();
    const [loading, setLoading] = useState(false)
    const [cultures, setCultures] = useState<{ Id: string, Name: string }[]>([]);
    const [timezones, setTimezones] = useState<{ Id: string, DisplayName: string }[]>([]);
    const timeZonesMapped = [{ text: TranslationService.translate.Select, value: '' }, ...timezones.map((x) => ({ text: x.DisplayName, value: x.Id }))]
    const languagesMapped = [{ text: TranslationService.translate.Select, value: '' }, ...cultures.map((x) => ({ text: x.Name, value: x.Id }))]

    const initTimeZone = timeZoneDefault && timeZoneItems.find(el => el.Id === timeZoneDefault.Id)?.DisplayName;
    const initCulture = currentLanguage && cultures.find(el => el.Id === currentLanguage)?.Name;

    const handleChangeData = () => {
        setChangingData(p => !p)
        setLoading(false)
    }

    const handleTimeZoneChange = async (timeZoneId: string) => {
        setLoading(true);
        const valueSelected = timezones.find(el => el.Id === timeZoneId)
        if (timeZoneId && valueSelected) {
            setDataText(d => ({ ...d, timezone: timeZoneId, timeName: valueSelected.DisplayName }))
        }
        await AccountService.setAccountTimeZone({ timeZoneId })
            .catch(() => setLoading(false))
            .finally(handleChangeData)
    }

    const handleCultureChange = async (culture: any) => {
        const cultureFormated = culture.includes('-') ? culture.split('-')[0] : culture;
        setLoading(true)
        setDataText(d => ({ ...d, culture }))
        setCurrentLanguage(cultureFormated as "es" | "en" | "pt")
        await AccountService.setAccountCulture({ culture })
            .catch(() => setLoading(false))
            .finally(() => window.location.replace(window.location.pathname.replace(currentLanguage, culture)))
    }


    const loadData = async () => {
        setLoading(true);
        const result = await AccountService.getCultures();
        if (result instanceof Error) {
            setLoading(false);
            return;
        }
        setTimezones(result.timezones)
        setCultures(result.cultures)
        setLoading(false);
    };

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="card-body">
            <div className='mb-2'>
                <h5>{TranslationService.translate.CultureData}</h5>
            </div>
            {
                loading ?
                    <Loading /> :
                    <>
                        {changingData ?
                            <div className="col">
                                <div className="col mb-3">
                                    <label className="font-weight-normal col-form-label">{TranslationService.translate.TimeZone}</label>
                                    <Dropdown onChange={handleTimeZoneChange} items={timeZonesMapped} defaultValue={timeZoneDefault?.DisplayName} />
                                    <label className="font-weight-normal col-form-label">{TranslationService.translate.Language}</label>
                                    <Dropdown onChange={handleCultureChange} items={languagesMapped} defaultValue={currentLanguage} />
                                </div>
                            </div>
                            :
                            <div className='row mb-2'>
                                <label className="font-weight-normal col-form-label">{TranslationService.translate.TimeZone}</label>
                                <p>{initTimeZone || dataText?.timeName}</p>

                                <label className="font-weight-normal col-form-label">{TranslationService.translate.Language}</label>
                                <p>{dataText?.culture || initCulture?.toUpperCase()}</p>
                            </div>
                        }
                    </>
            }




            <div className='mb-2 d-flex justify-content-end'>
                {!changingData ?
                    <button className="btn btn-primary mt-2" onClick={handleChangeData}>{TranslationService.translate.Change}</button>
                    : <div className='d-flex gap-2'>
                        <button className=" col btn btn-primary mt-2" onClick={handleChangeData}>{TranslationService.translate.Cancel}</button>
                    </div>}
            </div>
        </div>
    )
}
