import { useContext, useMemo, useState } from "react";
import { CompanyAuth } from "../../entities/login/CompanyAuth";
import { ReportExportListResponse } from "../../entities/reports/ReportExport/ReportExportListResponse";
import { ReportExportSetRequest } from "../../entities/reports/ReportExport/ReportExportSetRequest";
import CompanyService, { Entities } from "../../services/CompanyService";
import InvoiceService from "../../services/InvoiceService";
import ReportExportService from "../../services/ReportExportService";
import { TranslationService } from "../../services/TranslationService";
import { sheetColumns } from "../company/Configuration/ImportTemplate/ImportTemplateEditBase";
import ToastContext from "../shared/bootstrap/Toast";
import Dropdown, { DropdownProps } from "../shared/components/Dropdown";
import { CheckBoxEditor, TextEditor } from "../shared/components/Editors";
import FloatingPanelService from "../shared/FloatingPanel";
import { FloatingPanelFooter } from "../shared/components/FloatingPanelFooter";
import { RequiredManager, ValidationMessage } from "../shared/RequieredManager";
import { toEvent } from "../../utils/FuncUtils";
import { isNullOrWhitespace } from "../../utils/Utils";
import TooltipComponent from "../shared/TooltipComponent";

const ReportExportEdit = ({ data, reload }: { data: ReportExportListResponse.Item, reload: () => void }) => {
    const { translate } = TranslationService;
    const { showToast } = useContext(ToastContext);
    const [model, setModel] = useState<Partial<ReportExportSetRequest.Item>>(createModel(data));
    const [, setAvailableSheetColumns] = useState<DropdownProps<string>["items"]>(getAvailableSheetColumns(model));
    const [submiting, setSubmiting] = useState<"deleting" | "saving">();
    const [search, setSearch] = useState("");
    const requiredManager = new RequiredManager();
    const readOnlyForm = !CompanyService.getIsAdmin();

    const reportTypes = [
        translate.Activity,
        translate.Invoice,
        translate.Customer,
        translate.Activity + "-" + translate.Invoice,
        "",
        translate.Payment,
    ];

    const reportTypesFields = [
        {
            /*Activity*/
            builder: activityFields(clientFields(new FieldBuilder(model))),
            showInvoiceCheckbox: false,
            includeLastActivityCheckbox: false,
        },
        {
            /* Invoice */
            builder: invoiceFields(clientFields(new FieldBuilder(model))),
            showInvoiceCheckbox: true,
            includeLastActivityCheckbox: true,
        },
        {
            /* Customer */
            builder: clientFields(new FieldBuilder(model)),
            showInvoiceCheckbox: false,
            includeLastActivityCheckbox: true,
        },
        {
            /* Activity-Invoice */
            builder: invoiceFields(clientFields(new FieldBuilder(model))),
            showInvoiceCheckbox: false,
            includeLastActivityCheckbox: true,
        },
        { builder: new FieldBuilder(undefined), showInvoiceCheckbox: false, includeLastActivityCheckbox: false, },
        {
            /* Payment */
            builder: invoiceFields(paymentFields(clientFields(new FieldBuilder(model)))),
            showInvoiceCheckbox: false,
            includeLastActivityCheckbox: false,
        },
    ];

    const onSubmit = async () => {
        if (!requiredManager.validate()) {
            return;
        }
        if (submiting) {
            return;
        }

        if (model.columns === undefined || model.columns.length === 0) {
            showToast(translate.MissingRequiredFields);
            return;
        }

        const modelFormated: any = {
            ...model,
            columns: (model as any).columns
                .map((el: any) => (el.field === "" && el.AdditionalDefinitionID)
                    ? { ...el, field: "additional-" + el.AdditionalDefinitionID }
                    : el)
        };
        setSubmiting("saving");
        const result = await ReportExportService.set(modelFormated as ReportExportSetRequest.Item);
        setSubmiting(undefined);
        if (result instanceof Error) {
            showToast(translate.ErrorProcessingRequest, undefined, "danger");
            return;
        }
        reload();
        FloatingPanelService.hidePanel();
    };

    const onDelete = async () => {
        if (submiting) {
            return;
        }
        setSubmiting("deleting");
        const result = await ReportExportService.delete(model.ReportExportID!);
        setSubmiting(undefined);
        if (result instanceof Error) {
            showToast(translate.ErrorProcessingRequest, undefined, "danger");
            return;
        }
        reload();
        FloatingPanelService.hidePanel();
    };

    const updateColumn = (field: FieldData, value?: string) => {
        setModel(model => {
            const columns = model.columns?.filter(x => x.field !== field.fieldId) ?? [];
            if (value !== undefined) {
                columns.push({ field: field.fieldId, Position: parseInt(value) });
            }
            const isAdditional = field.fieldId.includes("additional");
            let columnsFormated = [...columns];
            if (isAdditional) {
                const keyToRemove = field.key;
                columnsFormated = columnsFormated.filter((el: any) => el.AdditionalDefinitionID !== parseInt(keyToRemove));
            }
            return ({ ...model, columns: columnsFormated });
        });
        setAvailableSheetColumns(getAvailableSheetColumns(model));
    };

    const reportBody = reportTypesFields[data.report];

    if (model.IncludeLastActivity || activityFields(new FieldBuilder(model)).fields[translate.Activity].some(x => x.value?.length > 0)) {
        reportBody.builder = activityFields(reportBody.builder);
    }

    return (
        <>
            <div className="floatingBody">
                <div className='d-flex justify-content-between align-items-center'>
                    <label className="col-4 font-weight-bold">{translate.Type}</label>
                    <div className="col">
                        {reportTypes[data.report]}
                    </div>
                </div>

                <div className='d-flex justify-content-between align-items-center my-2'>
                    <label className="col-4 font-weight-bold">{translate.Name}</label>
                    {!readOnlyForm ?
                        <div className="col">
                            <TextEditor
                                onChange={requiredManager.makeRequiredWithId(x => setModel(model => ({ ...model, Name: x })), "NameId")}
                                defaultValue={data.Name} />
                            {<ValidationMessage onChange={requiredManager.getValidation("NameId")!} defaultValue={model.Name} />}
                        </div> :
                        <div className="col">
                            {data.Name}
                        </div>
                    }
                </div>

                <div className='d-flex justify-content-between align-items-center'>
                    <TooltipComponent title={translate.ProtectedTip}>
                        <label className="col-4 font-weight-bold">{translate.Protected}
                            <i className="fal fa-question-square px-2" />
                        </label>
                    </TooltipComponent>
                    <div className="col">
                        <CheckBoxEditor disabled={readOnlyForm} message={<></>} onChange={x => setModel(model => ({ ...model, Protected: x }))} defaultValue={data.Protected} />
                    </div>
                </div>
                {
                    reportBody.showInvoiceCheckbox && <>
                        <div className='d-flex justify-content-between align-items-center'>
                            <label className="col-4 font-weight-bold">{translate.Public}
                                <i className="fal fa-question-square px-2" title={translate.PublicTip} />
                            </label>
                            <div className="col">
                                <CheckBoxEditor disabled={readOnlyForm} message={<></>} onChange={x => setModel(model => ({ ...model, Public: x }))} defaultValue={data.Public} />
                            </div>
                        </div>

                        <div className='d-flex justify-content-between align-items-center'>
                            <label className="col-4 font-weight-bold">{translate.CustomerSite}
                                <i className="fal fa-question-square px-2" title={translate.CustomerSiteTip} />
                            </label>
                            <div className="col">
                                <CheckBoxEditor disabled={readOnlyForm} message={<></>} onChange={x => setModel(model => ({ ...model, Portal: x }))} defaultValue={data.Portal} />
                            </div>
                        </div>

                        <div className='d-flex justify-content-between align-items-center'>
                            <label className="col-4 font-weight-bold">{translate.Default}
                                <i className="fal fa-question-square px-2" title={translate.DefaultTip} />
                            </label>
                            <div className="col">
                                <CheckBoxEditor disabled={readOnlyForm} message={<></>} onChange={x => setModel(model => ({ ...model, Default: x }))} defaultValue={data.Default} />
                            </div>
                        </div>
                    </>
                }

                <div className='d-flex justify-content-between align-items-center mt-3'>
                    <label className="col-4 font-weight-bold">{TranslationService.translate.Search + " " + TranslationService.translate.Field.toLocaleLowerCase()}</label>
                    <div className="col">
                        <input className="form-control" onChange={toEvent(x => setSearch(x))} />
                    </div>
                </div>
                {Object.keys(reportBody.builder.fields)
                    .filter(x => x !== TranslationService.translate.Activity)
                    .map(title => <ExportFields fieldData={reportBody.builder.fields[title]}
                        title={title} model={model} readOnlyForm={readOnlyForm}
                        updateColumn={updateColumn} key={title} search={search} />)
                }
                {reportBody.includeLastActivityCheckbox && <>
                    <div className="row align-items-center mt-3">
                        <label className="col-auto w-xl col-form-label font-weight-normal">{translate.IncludeLastActivity}   </label>
                        <div className="col mt-1">
                            <CheckBoxEditor disabled={readOnlyForm} message={<></>} onChange={x => setModel(model => ({ ...model, IncludeLastActivity: x }))} defaultValue={data.IncludeLastActivity} />
                        </div>
                    </div>
                </>}
                {Object.keys(reportBody.builder.fields)
                    .filter(x => x === TranslationService.translate.Activity)
                    .map(title => <ExportFields fieldData={reportBody.builder.fields[title]}
                        title={title} model={model} readOnlyForm={readOnlyForm}
                        updateColumn={updateColumn} key={title} search={search} />)
                }
            </div>
            <FloatingPanelFooter>
                {!readOnlyForm &&
                    <>
                        {data.ReportExportID !== undefined && data.ReportExportID > 0 &&
                            <button className='btn btn-danger me-auto' onClick={onDelete}>
                                {translate.Delete}
                                {submiting === "deleting" && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                            </button>}

                        <button className='btn btn-primary me-3' onClick={onSubmit}>
                            {translate.Save}
                            {submiting === "saving" && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                        </button>
                    </>
                }
            </FloatingPanelFooter>
        </>
    );
};

function getAvailableSheetColumnsForField(data: Partial<ReportExportSetRequest.Item> | undefined, field: FieldData): readonly { value: string; text: string; }[] {
    if (data === undefined) {
        return sheetColumns.map(x => ({ text: x.text, value: x.value.toString() }));
    }
    const columns = data.columns?.map(x => x.Position) ?? [];
    return sheetColumns.filter(x => !columns.includes(x.value) || field.value === x.value.toString()).map(x => ({ text: x.text, value: x.value.toString() }));
}

function getAvailableSheetColumns(data: Partial<ReportExportSetRequest.Item> | undefined): readonly { value: string; text: string; }[] {
    if (data === undefined) {
        return sheetColumns.map(x => ({ text: x.text, value: x.value.toString() }));
    }
    const columns = data.columns?.map(x => x.Position) ?? [];
    return sheetColumns.filter(x => !columns.includes(x.value)).map(x => ({ text: x.text, value: x.value.toString() }));
}

export type FieldData = { fieldId: string; key: string; label: string; value: string; };
function createFieldData(key: string, label: string, data?: Partial<ReportExportSetRequest.Item>): FieldData {
    return {
        fieldId: key,
        key: key,
        label: label,
        value: data?.columns?.find(x => x.field === key)?.Position.toString() ?? "",
    };
}

function additionalDefsToFieldData(additionalDefs: CompanyAuth.AdditionalDefinition[], data: Partial<ReportExportSetRequest.Item> | undefined) {
    return additionalDefs.map(x => (
        {
            fieldId: "additional-" + x.AdditionalDefinitionID.toString(),
            key: x.AdditionalDefinitionID.toString(),
            label: x.Name,
            value: data?.columns?.find((y: any) => y.AdditionalDefinitionID?.toString() === x.AdditionalDefinitionID.toString())?.Position.toString() ?? "",
        }));
}

class FieldBuilder {
    fields: { [key: string]: FieldData[] } = {};
    constructor(public data: Partial<ReportExportSetRequest.Item> | undefined) { }
}

function clientFields(fieldBuilder: FieldBuilder) {
    const name = TranslationService.translate.Customer;
    fieldBuilder.fields[name] = [
        createFieldData("name", TranslationService.translate.Name, fieldBuilder.data),
        createFieldData("email", TranslationService.translate.Email, fieldBuilder.data),
        createFieldData("address", TranslationService.translate.Address, fieldBuilder.data),
        createFieldData("comments", TranslationService.translate.Comments, fieldBuilder.data),
        createFieldData("personcontact", TranslationService.translate.Contact, fieldBuilder.data),
        createFieldData("phone", TranslationService.translate.Phone, fieldBuilder.data),
        createFieldData("followupmail", TranslationService.translate.FollowUpMail, fieldBuilder.data),
        createFieldData("customersite", TranslationService.translate.CustomerSite, fieldBuilder.data),
        createFieldData("creditlimit", TranslationService.translate.CreditLimit, fieldBuilder.data),
        createFieldData("segments", TranslationService.translate.Segments, fieldBuilder.data),
        createFieldData("person-count", TranslationService.translate.TypeIOList, fieldBuilder.data),
        createFieldData("person-amount", TranslationService.translate.Amount, fieldBuilder.data),
        createFieldData("person-overdueamount", TranslationService.translate.DueAmount, fieldBuilder.data),
        createFieldData("person-nodueamount", TranslationService.translate.NotDueAmount, fieldBuilder.data),
    ];
    fieldBuilder.fields[name].push(...additionalDefsToFieldData(CompanyService.getAdditionalDefinitions().filter(x => x.Entity === Entities.Client), fieldBuilder.data));
    return fieldBuilder;
}

function activityFields(fieldBuilder: FieldBuilder) {
    const name = TranslationService.translate.Activity;
    fieldBuilder.fields[name] = [
        createFieldData("activity-id", "Id", fieldBuilder.data),
        createFieldData("activity-date", TranslationService.translate.Date, fieldBuilder.data),
        createFieldData("activity-user", TranslationService.translate.User, fieldBuilder.data),
        createFieldData("activity-type", TranslationService.translate.Type, fieldBuilder.data),
        createFieldData("activity-activitytype", TranslationService.translate.ActivityType, fieldBuilder.data),
        createFieldData("activity-content", TranslationService.translate.Content, fieldBuilder.data),
        createFieldData("activity-tags", TranslationService.translate.Tags, fieldBuilder.data),
        createFieldData("activity-created", TranslationService.translate.Created, fieldBuilder.data),
        createFieldData("activity-aa", TranslationService.translate.AutomatedActions, fieldBuilder.data),
    ];
    return fieldBuilder;
}

function invoiceFields(fieldBuilder: FieldBuilder) {
    const name = TranslationService.translate.Invoice;
    fieldBuilder.fields[name] = [
        createFieldData("group", CompanyService.getGroupName(), fieldBuilder.data),
        createFieldData("createdDate", TranslationService.translate.IssueDate, fieldBuilder.data),
        createFieldData("referenceNumber", InvoiceService.getReferenceNumberName(), fieldBuilder.data),
        createFieldData("currency", TranslationService.translate.Currency, fieldBuilder.data),
        createFieldData("amount", TranslationService.translate.Amount, fieldBuilder.data),
        createFieldData("pending", TranslationService.translate.Pending, fieldBuilder.data),
        createFieldData("currencyrate", TranslationService.translate.ExchangeRate, fieldBuilder.data),
        createFieldData("dueDate", TranslationService.translate.DueDate, fieldBuilder.data),
        createFieldData("dueDays", TranslationService.translate.OverdueDays, fieldBuilder.data),
        createFieldData("completeddate", TranslationService.translate.PaidDate, fieldBuilder.data),
        createFieldData("iostatus", TranslationService.translate.IOStatus, fieldBuilder.data),
        createFieldData("description", TranslationService.translate.Comments, fieldBuilder.data),
    ];
    fieldBuilder.fields[name].push(...additionalDefsToFieldData(CompanyService.getAdditionalDefinitions().filter(x => x.Entity === Entities.Invoice), fieldBuilder.data));
    return fieldBuilder;
}

function paymentFields(fieldBuilder: FieldBuilder) {
    const name = TranslationService.translate.Payment;
    fieldBuilder.fields[name] = [
        createFieldData("transaction-Amount", TranslationService.translate.Amount, fieldBuilder.data),
        createFieldData("transaction-Date", TranslationService.translate.Date, fieldBuilder.data),
        createFieldData("transaction-Comments", TranslationService.translate.Comments, fieldBuilder.data),
        createFieldData("transaction-TransactionType", TranslationService.translate.PaymentType, fieldBuilder.data),
        createFieldData("transaction-User", TranslationService.translate.User, fieldBuilder.data),
        createFieldData("transaction-Receipt", TranslationService.translate.Receipt, fieldBuilder.data),
    ];
    fieldBuilder.fields[name].push(...additionalDefsToFieldData(CompanyService.getAdditionalDefinitions().filter(x => x.Entity === Entities.Payment), fieldBuilder.data));
    return fieldBuilder;
}

function createModel(data: ReportExportListResponse.Item): Partial<ReportExportSetRequest.Item> | (() => Partial<ReportExportSetRequest.Item>) {
    if (data === undefined) {
        return {};
    }
    return { ...data, columns: data.ReportExportFields };
}

const ExportFields = ({ fieldData, title, readOnlyForm, updateColumn, model, search }: { fieldData: FieldData[], title: string, readOnlyForm: boolean, updateColumn: (field: FieldData, value?: string) => void, model: Partial<ReportExportSetRequest.Item>, search: string }) => {
    const fields = useMemo(() => {
        const searchUpper = (search ?? "").toUpperCase();
        return fieldData.filter(x => isNullOrWhitespace(search) || x.label.toUpperCase().includes(searchUpper));
    }, [fieldData, search]);
    return (<div>
        <h6 className="mt-3 font-weight-bold">{TranslationService.translate.FilterData.replace("{EntityName}", title)}</h6>
        {fields
            .map(x => {
                return (
                    <div key={x.key} className="col-12">
                        <div className="row w-150 mt-1">
                            <label className="px-3 col-form-label font-weight-normal col-4">{x.label}</label>
                            <div className="col-6">
                                <Dropdown
                                    readonly={readOnlyForm}
                                    onChange={value => updateColumn(x, value)}
                                    items={getAvailableSheetColumnsForField(model, x)}
                                    optionLabel={TranslationService.translate.Select}
                                    defaultValue={x.value}
                                />
                            </div>
                        </div>
                    </div>
                );
            })}
    </div>);
};

export default ReportExportEdit;
