import { useContext } from "react";
import { ImportIds, ImportService } from "../../../services/ImportService";
import RequestService from "../../../services/RequestService";
import { formatDateShort, formatDateTimeDigits, formatTimeLong } from "../../../utils/FormatUtils";
import ModalService from "../../shared/bootstrap/Modal";
import ToastContext from "../../shared/bootstrap/Toast";
import { ImportLogListResponse } from "../../shared/entities/ImportLogListResponse";
import FloatingPanelService from "../../shared/FloatingPanel";
import { TranslationService } from "../../../services/TranslationService";
import TableContext from "../../task/TableContext";
import { ImportTableContextValues } from "./ImportLog";
import ImportLogDetail from "./ImportLogDetail";
import CompanyService from "../../../services/CompanyService";
import TooltipComponent from "../../shared/TooltipComponent";

const ImportLogItem = ({ data }: { data: ImportLogListResponse.Item }) => {
    const { translate } = TranslationService;
    const { showToast } = useContext(ToastContext);
    const { lastcompleteimportid, reload } = useContext(TableContext) as ImportTableContextValues;
    const status = (!data.Status && data.Status?.length === 0) ? undefined : TranslationService.getTranslation(data.Status!);
    const importType = ImportService.findImportType(data.Type, data.UpdateStrategy);
    const timezone = CompanyService.getTimezone();
    const lastStatusDate = data.LastStatus && new Date(new Date(data.LastStatus).getTime() - timezone * 60 * 1000);
    const statusTitle = status && status + (lastStatusDate ? (" " + formatDateShort(lastStatusDate) + " " + formatTimeLong(lastStatusDate)) : "");
    const getResult = (data: ImportLogListResponse.Item) => {
        let resultData = data.IOCount > 0 ? `<a href="/${TranslationService.currentLanguage}/report/iolist?impid=${data.ImportID}">` + (data.IOCount.toString() + " " + translate.TypeIOList + (data.IOCount !== 1 ? "s" : "")) + "</a>" : "";
        resultData += (data.PersonCount > 0 ? `<a href="/${TranslationService.currentLanguage}/client?impid=${data.ImportID}">` + (((resultData) ? ", " : "") + data.PersonCount.toString() + " " + translate.Client + (data.PersonCount !== 1 ? "s" : "")) + "</a>" : "");
        resultData += (data.TransactionCount > 0 ? `<a href="/${TranslationService.currentLanguage}/report/payment?impid=${data.ImportID}">` + (((resultData) ? ", " : "") + data.TransactionCount.toString() + " " + translate.Transaction + (data.TransactionCount !== 1 ? "s" : "")) + "</a>" : "");
        // eslint-disable-next-line 
        resultData += (data.MessageCount > 0 ? `<a href="/${TranslationService.currentLanguage}/report/activity?impid=${data.ImportID}">` + (((resultData) ? ", " : "") + data.MessageCount.toString() + " " + translate.TypeMail + (data.MessageCount !== 1 ? "s" + "</a>" : "")) : "");
        resultData += (data.TagCount > 0 ? ((!(resultData) ? ", " : "") + data.TagCount.toString() + " " + translate.Tag + (data.TagCount !== 1 ? "s" : "")) : "");
        resultData += (data.GroupCount > 0 ? ((!(resultData) ? ", " : "") + data.GroupCount.toString() + " " + translate.group + (data.GroupCount !== 1 ? "s" : "")) : "");
        return resultData;
    };

    const showImportDetail = () => {
        FloatingPanelService.showPanel({
            title: translate.Import,
            children: <ImportLogDetail data={data} />,
            width: 900,
            height: 700,
        });
    };

    const deleteImport = (id: number) => {
        const onDelete = async () => {
            const result = ImportService.delete(id);
            if (result instanceof Error) {
                showToast(translate.ErrorProcessingRequest, undefined, "danger");
            } else {
                reload();
            }
        };
        ModalService.showDefaultModal({
            title: translate.DeleteImport,
            message: translate.AreYouSureYouWishToDeleteThisImportRecord,
            acceptButtonLabel: translate.Delete,
            acceptButtonClassName: "btn btn-danger",
            onAcceptClick: onDelete,
        });
    };

    const downloadImport = () => {
        RequestService.downloadFile(`/import/download?id=${data.ImportID}`, data.Filename);
    };

    const autoFtp = (
        data.ImportTemplate !== null
        &&
        (
            data.ImportTemplate?.entity === ImportIds.AutomaticInvoiceUpdate ||
            data.ImportTemplate?.entity === ImportIds.AutomaticCustomerUpdate
        )
    )

    return (
        <tr className="show-child-on-hover">
            <td width={100} >{importType && TranslationService.getTranslation(importType.text)}</td>
            <td width={100} >{(data.Started ?? data.Created) ? formatDateTimeDigits(new Date(new Date(data.Started ?? data.Created).getTime() - timezone * 60 * 1000)) : ""}</td>
            <td width={100} >{lastStatusDate ? formatDateTimeDigits(lastStatusDate) : ""}</td>

            <td title={statusTitle} onClick={showImportDetail} className="pointer">
                <TooltipComponent title={status || ''}>
                    {status}
                </TooltipComponent>
            </td>
            <td>
                <div dangerouslySetInnerHTML={{ __html: getResult(data) }} />
            </td>
            <td>
                <TooltipComponent title={autoFtp ? "AUTO" : data.FTP ? 'FTP' : data.UserName || ''}>
                    {autoFtp ? "AUTO" : data.FTP ? 'FTP' : data.UserName}
                </TooltipComponent>
            </td>
            <td>
                <TooltipComponent title={data.ImportTemplate?.Name || ''}>
                    {data.ImportTemplate?.Name}
                </TooltipComponent>
            </td>
            <td width={100}>
                <TooltipComponent title={data.Filename || ''}>
                    {data.Filename}
                </TooltipComponent>
            </td>
            <td>
                <i className="show-when-hovering-parent fa-regular fa-arrow-down-to-line pointer" onClick={downloadImport}></i>
                <i className={"pointer fa-regular fa-search show-when-hovering-parent"} onClick={showImportDetail} />
                {((lastcompleteimportid && data.ImportID >= lastcompleteimportid) || data.New === 0) &&
                    <>
                        {<i className={"pointer fa-regular fa-trash-can text-danger show-when-hovering-parent"} onClick={() => deleteImport(data.ImportID)} />}
                    </>
                }
            </td>
        </tr>);
};
export default ImportLogItem;