import { useState } from "react";
import { TranslationService } from "../../services/TranslationService";
import AccountService, { AccountPasswordExpiredRequest } from "../../services/AccountService";
import { toEvent } from "../../utils/FuncUtils";
import { RequiredManager, ValidationMessage } from "../shared/RequieredManager";
import { isNullOrWhitespace } from "../../utils/Utils";
import { handleErrorWithToast } from "../../utils/RequestUtils";
import { useHistory } from "react-router-dom";

export const PasswordExpired = () => {
    const [model, setModel] = useState<Partial<AccountPasswordExpiredRequest>>();
    const [repeatedPassword, _setRepeatedPassword] = useState("");
    const [visiblePassword, setVisiblePassword] = useState(false);
    const [visiblePasswordRepeat, setVisiblePasswordRepeat] = useState(false);
    const [visiblePasswordCurrent, setVisiblePasswordCurrent] = useState(false);
    const [loading, setLoading] = useState(false);
    const requiredManager = new RequiredManager();
    const history = useHistory();
    const submit = async () => {
        if (!requiredManager.validate()) {
            return;
        }
        setLoading(true);
        await handleErrorWithToast(AccountService.changePasswordExpired(model as AccountPasswordExpiredRequest), () => setLoading(false));
        history.push("/");

    };
    const changeModel = <T extends keyof AccountPasswordExpiredRequest,>(field: T) => requiredManager.makeRequiredWithId((value: string) => setModel(x => ({ ...x, [field]: value })), field);
    const setRepeatedPassword = requiredManager.makeRequired((value: string) => _setRepeatedPassword(value));
    return (
        <>
            <h2>Tu contraseña ha expirado. Por favor, selecciona una nueva para acceder a tu cuenta.</h2>
            <p className="my-3 text-center">La contraseña debe tener al menos 8 caracteres, debe incluir mayúsculas, minúsculas y números o símbolos ($, !, %, etc.)</p>
            <div className="box">
                <div className="input-group mb-3">
                    <input type={visiblePasswordCurrent ? "text" : "password"} placeholder={TranslationService.translate.CurrentPassword} onChange={() => { /*toEvent(changeModel("currentpassword"))*/ }} />
                    <i className={`fas fa-${visiblePasswordCurrent ? "un" : ""}lock-alt icono-input`} onClick={() => setVisiblePasswordCurrent(x => !x)} />
                </div>
                {/* <ValidationMessage defaultValue={model?.currentpassword} onChange={requiredManager.getValidation("currentpassword")} /> */}
                <div className="input-group">
                    <input type={visiblePassword ? "text" : "password"} placeholder={TranslationService.translate.Password} onChange={toEvent(changeModel("password"))} />
                    <i className={`fas fa-${visiblePassword ? "un" : ""}lock-alt icono-input`} onClick={() => setVisiblePassword(x => !x)} />
                </div>
                <ValidationMessage defaultValue={model?.password} onChange={requiredManager.getValidation("password")} />
                <div className="input-group">
                    <input type={visiblePasswordRepeat ? "text" : "password"} placeholder={TranslationService.translate.RepeatPassword} onChange={toEvent(x => { setRepeatedPassword(x); changeModel("confirmpassword")(x); })} />
                    <i className={`fas fa-${visiblePasswordRepeat ? "un" : ""}lock-alt icono-input`} onClick={() => setVisiblePasswordRepeat(x => !x)} />
                </div>
                <ValidationMessage defaultValue={repeatedPassword} onChange={setRepeatedPassword} validationMethod={(x) => !isNullOrWhitespace(x) && x === model?.password}
                    message="Las contraseñas deben coincidir" />
                <div className="login-btn" id="loginbut">
                    <button type="button" onClick={() => submit()} className="botondemo" disabled={loading}>
                        {TranslationService.translate.Save.toUpperCase()}
                        {loading && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                    </button>
                </div>
            </div>
        </>
    );
};