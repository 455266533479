export class GroupListRequest {
    page: number = 0;
    pagesize: number = 10;
    pageSize: number = 10;
    filter: string = "";
    sortcolumn: string = "dueDate"
    descending: number = 0;
    personid?: number;

    constructor(personid: number) {
        this.personid = personid;
    }
}