import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { TranslationService } from "../../services/TranslationService";
import PortalClientService, { ClientInfoType, PortalClientResponse, PortalIoStatusesDataResponse } from "../../services/PortalClientService";
import PortalClientTableProvider, { PortalClientTableContextValues } from "./providers/PortalClientTableProvider";
import TableContext from "../task/TableContext";
import { PortalHeader } from "./components/PortalHeader";
import AdvancedFilters, { AdvancedFiltersProvider } from "../shared/components/AdvancedFilters";
import FilterService, { FilterCollection, FilterDefinition, FilterOption } from "../../services/FilterService";
import ExportPortalService from "../../services/ExportPortalService";
import CompanyService, { DataType, Entities } from "../../services/CompanyService";
import { CompanyAuth } from "../../entities/login/CompanyAuth";
import { ToastService } from "../shared/bootstrap/Toast";
import FloatingPanelService from "../shared/FloatingPanel";
import { SendMessageCompose } from "./components/SendMessageCompose";
import { PortalInvoicesTable } from "./components/PortalInvoicesTable";
import { SortDirection } from "../shared/entities/Sort";
import { InvoiceIconActive, InvoiceIconInactive } from "./components/InvoiceIcon";
import { HomeIconActive, HomeIconInactive } from "./components/HomeIcon";
import { OptionTab } from "../shared/ScrollNavChips";
import { PaymentsIconActive, PaymentsIconInactive } from "./components/PaymentsIcon";
import ButtonDropdown from "../shared/components/ButtonDropdown";
import plusPublic from '../../assets/img/plusPublic.svg';
import './styles.css';
import Dropdown from "../shared/components/Dropdown";
import { ButtonTooltipIcon } from "../shared/components/ButtonTooltipIcon";

export const PortalClientContainer = () => {
  const { currentLanguage } = TranslationService;
  const [dataClient, setDataClient] = useState<PortalClientResponse>();
  const [dataClientAdditionals, setDataClientAdditionals] = useState<PortalClientResponse>();
  const [dataIoStatuses, setDataIoStatuses] = useState<PortalIoStatusesDataResponse>();
  const history = useHistory()
  const tokenId = new URLSearchParams(history.location.search).get("t") ?? "";

  const filterOptionsDataClient: FilterOption = useMemo(() => {
    if (dataClient) {

      const filtersDefinitions: FilterDefinition[] = [
        {
          AdditionalDefinitionItems: [],
          Entity: Entities.Portal,
          Name: TranslationService.translate.IssueDate,
          Field: "-1023",
          Type: DataType.Date,
        },
        {
          AdditionalDefinitionItems: [],
          Entity: Entities.Portal,
          Name: TranslationService.translate.DueDate,
          Field: "-1024",
          Type: DataType.Date,
        },
        {
          AdditionalDefinitionItems: [],
          Entity: Entities.Portal,
          Name: TranslationService.translate.Amount,
          Field: "-1011",
          Type: DataType.Number,
        },
        {
          AdditionalDefinitionItems: [],
          Entity: Entities.Portal,
          Name: TranslationService.translate.PendingAmount,
          Field: "-1012",
          Type: DataType.Number,
        },
      ];

      if (dataClient?.referenceNumberName) {
        filtersDefinitions.push({
          AdditionalDefinitionItems: [],
          Entity: Entities.Portal,
          Name: dataClient?.referenceNumberName || '',
          Field: dataClient?.referenceNumberName,
          Type: DataType.Text,
        })
      }

      if (dataClient?.showGroup && dataClient?.groupName) {
        filtersDefinitions.push({
          AdditionalDefinitionItems: [],
          Entity: Entities.Portal,
          Name: dataClient?.groupName,
          Field: 'group',
          Type: DataType.Text,
        })
      }

      const ioAdditionals = (CompanyService.getAdditionalDefinitionsPortal() as any)?.ioAdditionals;

      const filterDefinitions: FilterDefinition[] = [...FilterService.AdditionalDefinitionToFilterDefinition(ioAdditionals)];
      return {
        title: '',
        entity: Entities.Portal,
        definitions: [...filtersDefinitions, ...filterDefinitions]
      };
    } else {
      return {
        title: '',
        entity: Entities.Portal,
        definitions: []
      };
    }
  }, [dataClient])
  const loadData = async (token: string) => {
    const result = await PortalClientService.getClientData(token);
    if (result instanceof Error) {
      window.location.href = `/${TranslationService.currentLanguage}/logon`;
      return;
    } else if (result?.data) {
      setDataClient(result.data);
    }
  };
  const loadDataAdditionals = async (token: string) => {

    const result = await PortalClientService.getAdditionals(token);
    if (result instanceof Error) {
      window.location.href = `/${TranslationService.currentLanguage}/logon`;
      return;
    } else if (result?.data) {
      setDataClientAdditionals(result.data);
      CompanyService.setCompanyAuthPortal({
        additionalDefinitions: result.data as unknown as CompanyAuth.AdditionalDefinition[],
        companies: [],
        currencies: [],
        users: [],
        configItems: [],
        iostatus: [],
        activityTypes: [],
        reportExports: [],
        tags: [],
        segments: [],
        transactionTypes: [],
        emailTemplates: [],
        emailFrames: [],
        storedFilters: [],
        companyGuid: "",
        companyName: "",
        defaultCurrencySymbol: "",
        HidePriority: false,
        IOGroup: [],
        defaultCurrencyId: 0,
        companyToken: "",
        refreshToken: "",
        block: null,
        expire: "",
        Production: false,
        AdminId: null,
        personSortedFields: "",
        ioSortedFields: "",
        showSMS: false,
        groupName: "",
        PersonKey: 0,
        dashBoardPanelId: null,
        EnabledSegmentPriorities: false,
        TotalPriorities: 0,
        NormalIOStatusName: "",
        userCount: 0,
        accountUserCount: 0,
        accountPersonCount: 0,
        accountReadonlyUserCount: 0,
        OrderMailBy: "",
        GroupMailBy: "",
        OrderMailByOrderDesc: "",
        GraphScales: [],
        HasReceipt: false,
        logoUrl: "",
        ActionLanguage: "",
        Languages: [],
        ShowCents: false,
        excludedFilterFields: [],
        Whatsapp: false,
        customerFieldName: "",
        customersFieldName: "",
        versionAllowed: null,
        currentVersion: null
      })
      CompanyService.getAdditionalDefinitionsPortal()
    }
  };
  const loadIoStatuses = async (token: string) => {
    const result = await PortalClientService.getIoStatuses(token);
    if (result instanceof Error) {
      window.location.href = `/${TranslationService.currentLanguage}/logon`;
      return;
    } else if (result) {
      setDataIoStatuses(result.data);
    }
  };
  const loadAllData = useCallback(async () => {
    await loadDataAdditionals(tokenId);
    await loadData(tokenId);
    await loadIoStatuses(tokenId);
  }, [tokenId])


  useEffect(() => {
    if (tokenId) {
      loadAllData()
    } else {
      history.push(`/${currentLanguage}/`)
    }
  }, [currentLanguage, history, loadAllData, tokenId])

  return (
    <PortalClientTableProvider token={tokenId}>
      {(dataClientAdditionals && dataClient && dataIoStatuses) &&
        <PortalClient
          dataClient={dataClient}
          tokenId={tokenId}
          dataIoStatuses={dataIoStatuses}
          filterOptionsDataClient={filterOptionsDataClient}
        />}
    </PortalClientTableProvider>
  )
}

const PortalClient = ({
  dataClient,
  tokenId,
  dataIoStatuses,
  filterOptionsDataClient
}: {
  dataClient?: PortalClientResponse,
  tokenId: string,
  dataIoStatuses: PortalIoStatusesDataResponse,
  filterOptionsDataClient: FilterOption
}) => {
  const [otherFilters, setOtherFilters] = useState(false)
  const [isPanelOpen, setIsPanelOpen] = useState(false)
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectedInvoices, setSelectedInvoices] = useState<{ id: number, nro: string }[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const { response, setRequest, request, loading } = useContext(TableContext) as PortalClientTableContextValues;
  const hasPaymentMethod = Boolean(dataClient?.paymentMethod && !(dataClient?.paymentMethod === "<p></p>\n"));
  const togglePanel = () => setIsPanelOpen(!isPanelOpen);

  const [requestUpdated, setRequestUpdated] = useState(request)
  const [quickFilter, setQuickFilter] = useState<number | null>(null)
  const [activeTab, setActiveTab] = useState<number>(0)
  const tabs = useMemo(() => {
    let tabsFormated = [
      { title: 'Facturas', iconActive: InvoiceIconActive, iconInactive: InvoiceIconInactive },
      { title: 'Mis datos', iconActive: HomeIconActive, iconInactive: HomeIconInactive },
    ];
    if (hasPaymentMethod) {
      tabsFormated.push({ title: 'Formas de pago', iconActive: PaymentsIconActive, iconInactive: PaymentsIconInactive })
    }
    return tabsFormated;
  }, [hasPaymentMethod])

  const tabsFormatted: OptionTab[] = tabs.map((x, index) => ({
    title: x.title,
    iconActive: x.iconActive,
    iconInactive: x.iconInactive,
    index,
    activeTab,
    setActiveTab
  }));

  const filterItems = useMemo(() => {
    let filterItems = [
      { text: TranslationService.translate.Select, key: -1, value: -1 },
      { text: 'Facturas Vencidas', key: 0, value: 0 },
      { text: 'Todas las facturas pendientes', key: 1, value: 1 },
      { text: 'Otros Filtros', key: 3, value: 3 },
    ];

    if (response?.customerportalshowhistory) {
      filterItems.push({ text: 'Todas las facturas', key: 2, value: 2 });
    }
    return filterItems;
  }, [response?.customerportalshowhistory]);

  const exportInvoiceList = async () => {
    await ExportPortalService.requestExport("/customer/exportiolist", {
      descending: request.descending || SortDirection.Descending,
      filter: request.filter || '',
      quickFilter: request.quickFilter,
    }, 1, 'customer_list.xlsx', tokenId);
  };

  const statuses = useMemo(() => {
    const data = dataIoStatuses.statuses.map(st => ({ id: st.IOStatusID?.toString(), text: st.Name }));
    data.push({ id: '0', text: 'Otros' })
    return data || [];
  }, [dataIoStatuses.statuses])

  const actionSendMessage = useCallback(async (field) => {
    await FloatingPanelService.hidePanel()
    const ignoreFields = !Boolean(parseInt(field));

    if (!selectedRows.length && !ignoreFields) {
      ToastService.showToast(TranslationService.translate.SendMessageAlertWithoutMsg, undefined, "danger");
      return;
    }
    const dataField = dataIoStatuses.statuses.find(st => st.IOStatusID.toString() === field) || null;
    await FloatingPanelService.showPanel({
      title: TranslationService.translate.SendMessage,
      children: <SendMessageCompose
        dataFields={dataField}
        ignoreFields={ignoreFields}
        tokenId={tokenId}
        selectedRows={selectedRows}
        selectedInvoices={selectedInvoices}
      />,
      height: 600,
      width: 600,
      position: 'center'
    });
  }, [dataIoStatuses.statuses, selectedRows, tokenId, selectedInvoices])

  const selectGroupInvoice = useCallback((filters: string[]) => {
    setRequest({ ...requestUpdated, quickFilter, filter: FilterService.GetExtraFiltersRequestString(filters) || '' });
  }, [quickFilter, requestUpdated, setRequest])

  useEffect(() => {
    setRequestUpdated(prev => ({ ...prev, quickFilter }))
    setOtherFilters(quickFilter === 3);

    if (quickFilter !== null && quickFilter >= 0 && quickFilter !== 3) {
      setRequest({ ...requestUpdated, quickFilter });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quickFilter])

  return (
    <>
      <PortalHeader
        logo={dataClient?.urlLogo}
        personName={dataClient?.companyName || "Cliente"}
        tabs={tabsFormatted}
        {...{ activeTab, setActiveTab }}
      />

      <div
        className={"d-flex flex-column flex-grow-1"}
        style={{ backgroundColor: "#F7F7F9", minHeight: "calc(100vh - 179px)" }}
      >
        {activeTab === 0 &&

          <div className="container-fluid padding-portal">
            <AdvancedFiltersProvider>
              <div className="d-flex flex-wrap align-items-center gap-2 mb-md-3 m-1">

                {dataClient?.customerMessages &&
                  <div className="col-6 col-md-auto">
                    <ButtonDropdown
                      items={statuses.map(el => ({ text: el.text, value: el.id }))}
                      onChange={(selected) => actionSendMessage(selected!)}
                      buttonClass='p-0'
                    >
                      <div className="d-flex align-items-center menuBtnText">
                        <img src={plusPublic} alt='Enviar Mensaje' />
                        <p>{TranslationService.translate.SendMessage}</p>
                      </div>
                    </ButtonDropdown>
                  </div>}

                <ButtonTooltipIcon
                  title={TranslationService.translate.Export}
                  icon="fa-light fa-arrow-down-to-line"
                  onClick={exportInvoiceList}
                />

                <div className="col-12 col-md-2">
                  <Dropdown items={filterItems} onChange={setQuickFilter} defaultValue={request?.quickFilter || undefined} />
                </div>
              </div>


              {(otherFilters) &&
                <AdvancedFilters filterOptions={[filterOptionsDataClient]} page={FilterCollection.PortalFixed} onFilterApply={selectGroupInvoice} />}

              {response &&
                <PortalInvoicesTable
                  {...{
                    response,
                    setRequest,
                    request,
                    togglePanel,
                    otherFilters,
                    setOtherFilters,
                    tokenId,
                    selectedRows,
                    setSelectedRows,
                    selectAll,
                    setSelectAll,
                    selectedInvoices,
                    setSelectedInvoices
                  }}
                  isLoading={(!dataClient || loading)} />
              }
            </AdvancedFiltersProvider>
          </div>
        }


        {activeTab === 1 &&
          <div className="d-flex flex-wrap">
            <div className="container-fluid m-md-3 p-md-4 p-2 m-2 bg-white">
              <div className="col-md-8">
                {dataClient?.personName &&
                  <div className="row mb-1">
                    <div className="col-3"><strong>{TranslationService.translate.Name}:</strong></div>
                    <div className="col-8">{dataClient.personName}</div>
                  </div>}

                {dataClient?.clientInfo && Object.keys(dataClient.clientInfo).map((el, i) => <ItemClientConfig clientInfo={dataClient.clientInfo} itemClientInfo={el} />)}

              </div>
            </div>
          </div>}

        {(activeTab === 2 && hasPaymentMethod) &&
          <div className="d-flex flex-wrap">
            <div className="container-fluid m-md-3 p-md-4 p-2 m-2 bg-white">
              <div className="col-md-8">
                <div className="row mb-1">
                  {dataClient?.paymentMethod ?
                    <div className="col-12" dangerouslySetInnerHTML={{ __html: dataClient.paymentMethod }} /> :
                    <div className="col-12">{TranslationService.translate.NoDataToShow}</div>}
                </div>
              </div>
            </div>
          </div>}

      </div >
    </>
  )
}

const ItemClientConfig = ({ clientInfo, itemClientInfo }: { clientInfo: ClientInfoType, itemClientInfo: string }) => {
  let component = <></>;
  const item = clientInfo[itemClientInfo as keyof ClientInfoType];

  switch (item.type) {
    case DataType.PhoneWhatsapp:
      component = (
        <div className="row mb-1">
          <div className="col-3"><strong>{itemClientInfo}:</strong></div>
          <div className="col-8">
            <a target="_blank" className="d-flex align-items-center" rel="noopener noreferrer" href={CompanyService.getWhatsappUrl(item.value)}>
              {item.value} <i className="fa-brands fa-whatsapp me-2" style={{ color: "#1bd741", borderRadius: 100, marginLeft: 10 }} />
            </a>
          </div>
        </div>
      );
      break;
    case DataType.Link:
      component = (
        <div className="row mb-1">
          <div className="col-3"><strong>{itemClientInfo}:</strong></div>
          <div className="col-8">
            <a href={item.value}
              target="_blank"
              rel="noopener noreferrer">Ir al sitio: {item.value}</a>
          </div>
        </div>
      );
      break;
    default:
      component = (
        <div className="row mb-1">
          <div className="col-3"><strong>{itemClientInfo}:</strong></div>
          <div className="col-8">{item.value}</div>
        </div>
      );
      break;
  }


  return (component)
}


export default PortalClient;
